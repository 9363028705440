import React from 'react';
import Video from '../../../../src/img/intro.mp4';
function FirstSection() {
  return (
    <div className="px-4 mt-0 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="relative w-full transition-shadow duration-300 hover:shadow-xl">
        <div className="relative w-full transition-shadow duration-300 hover:shadow-xl">
          <div style={{ position: 'relative', width: '100%' }}>
            <video className="w-full shadow-lg" autoPlay controls>
              <source src={Video} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
