import React from 'react';
import { Link } from 'react-router-dom';
import RegButton from './button';
import RegCta from './regCTA';

function Register(props) {
  const { data } = props;

  const info1 = [
    '   تعداد جلسات:<b> ۱۰ جلسه</b>',
    'هر ماه، یک جلسه مشاوره رایگان در Google Meet',
    '     برگزار کننده و پشتیبانی: <b>تیم فیت لاینز</b>',
  ];

  const info2 = [
    'عضویت در گروه تلگرامی و همراه بودن با بچه های دوره های قبلی',
    'امکان ارسال پرسش ها و مشکلات به مدرس دوره',
    'پشتیبانی کامل از طریق  تلگرام',
    'دسترسی <b><u>نامحدود</u></b> به فیلم جلسات در پنل شخصی',
  ];

  return (
    <div className=" ">
      <section className="text-center bg-main">
        <div className="px-6 py-12 md:px-12">
          <div className="container mx-auto xl:px-32">
            <div className="flex grid items-center lg:grid-cols-1">
              <div className="mb-12 md:mt-12 lg:mt-0 lg:mb-0 text-right">
                <div className="flex grid lg:grid-cols-2 rounded-lg bg-white px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                  <div className="container flex mx-auto w-full font-bold items-center justify-center">
                    <ul className="flex flex-col  p-4">
                      <span className="font-bold mb-5">
                        اطلاعات دوره آکادمی Shape Up{' '}
                      </span>
                      {info1.map((item) => (
                        <li className="border-gray-400 flex flex-row mb-2">
                          <div className="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                            <div className="flex-1 pl-1">
                              <div className="font-medium">
                                <p
                                  className="text-main font-custom1"
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}></p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="container flex mx-auto w-full font-bold items-center justify-center">
                    <ul className="flex flex-col  p-4">
                      <span className="font-bold mb-5">
                        با ثبت نام در آکادمی Shape Up چه امکاناتی دریافت می
                        کنید؟
                      </span>
                      {info2.map((item) => (
                        <li className="border-gray-400 flex flex-row mb-2">
                          <div className="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                            <div className="flex-1 pl-1">
                              <div className="font-medium">
                                <p
                                  className="text-whimainte font-custom1"
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}></p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Register;
