import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import callouts from '../../data/services';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const PaymentIndex = () => {
  const navigate = useNavigate();
  useEffect(() => {
    //redirect to home page with id section

    navigate('/#product');
  }, []);
  return (
    <div className="bg-main py-24 sm:py-32">
      <Helmet title="فیتلاینز - لیست دوره ها  " />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold font-custom1 tracking-tight text-white sm:text-4xl">
            دوره ها، محصولات و خدمات فیتلاینز{' '}
          </h2>
          {/* <p className="mt-6 text-lg leading-8 text-gray-100">
            این دوره برای کسانی است که میخواهند با تغذیه سالم و متناسب با
            نیازهای خود زندگی کنند و از این روش برای کاهش وزن و افزایش متابولیسم
            استفاده کنند.
          </p> */}
        </div>
        {callouts.map((data) => (
          <div
            key={data.id}
            className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none bg-white">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold font-custom1 tracking-tight text-main">
                {data.name}
              </h3>
              <h5>
                <span className=" font-bold font-custom1 tracking-tight text-main">
                  {data.description}
                </span>
              </h5>
              <p className="mt-6 text-base leading-7 text-gray-800"></p>
              {data.features && (
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 font-custom1 text-gray-800">
                    این پکیج شامل موارد زیر است:
                  </h4>
                  <div className="h-px flex-auto bg-gray-100"></div>
                </div>
              )}
              <ul
                // role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {data.features?.map((feature) => (
                  <li className="flex gap-x-3 font-custom1">
                    <svg
                      className="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true">
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    {feature.details}
                  </li>
                ))}
              </ul>
              {data.forWho && (
                <span className="flex-none text-sm font-semibold leading-6 font-custom1 text-gray-800">
                  این دوره برای چه کسانی مناسب است؟
                </span>
              )}
              <ul
                // role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {data.forWho?.map((data) => (
                  <li className="flex gap-x-3 font-custom1">
                    <svg
                      className="h-6 w-5 flex-none text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true">
                      <path
                        fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    {data.title}
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex items-center justify-center min-h-screen mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="relative overflow-hidden rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                {data.active !== true && (
                  <div className="absolute right-0 top-0 h-16 w-16">
                    <div className="bg-nonary absolute transform rotate-45 bg-green-600 text-center text-gold font-semibold py-1 right-[-35px] top-[32px] w-[170px]">
                      ظرفیت تکمیل شد
                    </div>
                  </div>
                )}
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    {data.priceHeadLine}
                  </p>

                  {data.price && (
                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-main">
                        {data.sign} {data.price}
                      </span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                        {data.currency}
                      </span>
                    </p>
                  )}
                  {data.priceRial && (
                    <p>
                      <p className="mt-6 flex items-baseline justify-center gap-x-2">
                        <span className="text-5xl  text-main font-custom1">
                          {data.priceRialSign}
                        </span>
                        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                          تومان
                        </span>
                      </p>
                      <span className="text-main">
                        قیمت ریالی، فقط برای کاربران ساکن ایران
                      </span>
                    </p>
                  )}
                  {data.priceRialLevel1 && (
                    <p>
                      <p className="mt-6 flex items-baseline justify-center gap-x-2">
                        <span className="text-5xl  text-main font-custom1">
                          {data.priceRialLevel1}
                        </span>
                        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                          ریال
                        </span>
                      </p>
                      <span className="text-main">
                        نسخه طلایی، فقط برای کاربران ساکن ایران
                      </span>
                    </p>
                  )}

                  {/* <a
                    href="/"
                    className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-main shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    ثبت نام
                  </a> */}

                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    {data.active ? (
                      <Link
                        to={`/service/${data?.id}`}
                        state={{
                          data: data,
                        }}
                        className="mt-10 bg-main text-white w-full font-bold uppercase text-sm outline-none focus:outline-none px-6 py-3 rounded shadow hover:shadow-lg  mr-1 mb-1 ease-linear border border-persian-indigo-700 transition-all duration-150">
                        پرداخت
                      </Link>
                    ) : (
                      <span className="text-red-500">ظرفیت تکمیل شد</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentIndex;
