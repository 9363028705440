import React from 'react';
import img1 from '../../img/image1.png';
import img2 from '../../img/image2.png';
import img3 from '../../img/image3.png';
import img4 from '../../img/image4.png';

const images = [img1, img2, img3, img4];

function Carosusel() {
  return (
    <div
      className="carousel bg-main   mx-auto
    ">
      {images.map((image) => (
        <div className="carousel-item w-1/3">
          <img src={image} className="w-full" />
        </div>
      ))}
    </div>
  );
}

export default Carosusel;
