import { Card } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PrizeWheel from '../../../landing/games/PrizeWheel';

const ClassSessions = ({ data }) => {
  const [isAcademy, setIsAcademy] = useState(false);
  const [userProducts, setUserProducts] = useState();

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    const parsedCredentials = JSON.parse(credentials);
    setUserProducts(parsedCredentials?.userProduct);
  }, []);

  const checkUserHasAcademyProduct = () => {
    const hasAcademy = userProducts?.some(
      (item) => item.product === 'Shape up Academy'
    );
    if (hasAcademy) {
      setIsAcademy(true);
    }
  };

  useEffect(() => {
    checkUserHasAcademyProduct();
  }, []);
  const options = [
    { name: 'Prize 1', color: 'red' },
    { name: 'Prize 2', color: 'blue' },
    { name: 'Prize 3', color: 'green' },
    { name: 'Prize 4', color: 'yellow' },
    { name: 'Prize 5', color: 'purple' },
    { name: 'Prize 6', color: 'orange' },
  ];

  return (
    <>
      <div className="grid md:grid-cols-4 gap-4 my-4 mx-5">
        <Card className="mb-5">
          <h5 className="text-2xl text-right font-bold tracking-tight text-gray-900 dark:text-white">
            آموزش اپلیکیشن MyFitnessPal (کالری شماری)
          </h5>
          <p className="font-normal text-right text-gray-700 dark:text-gray-400"></p>
          <p className="font-normal text-right text-gray-700 dark:text-gray-400"></p>

          <Link
            className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
            to={`/dashboard/workshop/myfitnesspal`}
            state={{
              data: {
                name: 'آموزش اپلیکیشن MyFitnessPal (کالری شماری)',
                attachments: [],
                description:
                  'در این جلسه با نحوه استفاده از اپلیکیشن MyFitnessPal برای کالری شماری آشنا می شوید',
                product: 'Shape up Academy',
                video: 'https://backend.fitlinez.com/videos/myfitnesspal.MP4',
                active: true,
                session: 'myfitnesspal',
              },
            }}>
            <p className="text-center">مشاهده</p>
          </Link>
        </Card>
        {isAcademy && (
          <Card className="mb-5">
            <h5 className="text-2xl text-right font-bold tracking-tight text-gray-900 dark:text-white">
              نکات مهم برای ماه رمضان و عید نوروز
            </h5>
            <p className="font-normal text-right text-gray-700 dark:text-gray-400"></p>
            <p className="font-normal text-right text-gray-700 dark:text-gray-400"></p>

            <Link
              className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
              to={`/dashboard/workshop/ramadan`}
              state={{
                data: {
                  name: 'نکات مهم برای ماه رمضان و عید نوروز',
                  attachments: [],
                  description:
                    'در این جلسه نکات مهم برای حفظ لایف استایل سالم (تغذیه، ورزش و پیاده روی) بیان شده است',
                  product: 'Shape up Academy',
                  link: 'https://drive.google.com/file/d/1YpZ0WJIuDL2uv2U2jW9B-keF1U_aP1m0/preview',
                  active: true,
                  session: 'specialdays',
                  additionalLinks: [
                    {
                      name: ' پیاده روی در منزل',
                      link: 'https://youtu.be/nXSrQKhxe1U?si=_Gb165qLaO9-H_7f',
                    },
                    {
                      name: ' دانلود اپلیکیشن فیتلاینز',
                      link: 'https://www.fitlinez.com/downloadapp',
                    },
                  ],
                },
              }}>
              <p className="text-center">مشاهده</p>
            </Link>
          </Card>
        )}
        {data?.data.map((item, index) => {
          return (
            <Card className="mb-5">
              <h5 className="text-2xl text-right font-bold tracking-tight text-gray-900 dark:text-white">
                {item.name}
              </h5>
              <p className="font-normal text-right text-gray-700 dark:text-gray-400">
                {item.description}
              </p>
              <p className="font-normal text-right text-gray-700 dark:text-gray-400">
                {item.product}
              </p>
              {item?.active ? (
                <Link
                  className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                  to={`/dashboard/workshop/${item.name}`}
                  state={{ data: item }}>
                  <p className="text-center">مشاهده</p>
                </Link>
              ) : (
                <p className="text-center text-red-500">
                  برای مشاهده این جلسه بعدا مراجعه کنید
                </p>
              )}
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default ClassSessions;
