import axios from 'axios';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import api from '../../api/api';

const PaymentSuccess = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // Get the email and name parameters
  const email = params.get('email');
  const name = params.get('name');
  const product = params.get('product');
  const [confirmayment, setConfirmPayment] = React.useState(false);
  const handlePayment = () => {
    // Send a POST request to the server to save the user's data
    api
      .get(`/stripeworkshop/successful-payment`, {
        params: {
          email: email,
          name: name,
          product: product,
        },
      })
      .then((res) => {
        console.log(res.data);
        setConfirmPayment(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      {name && email ? (
        <div className="bg-main min-h-screen flex flex-col justify-center items-center">
          <h1 className="text-3xl font-bold mb-6">پرداخت با موفقیت انجام شد</h1>
          {!confirmayment ? (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                handlePayment();
              }}>
              تایید پرداخت
            </button>
          ) : (
            <p className="text-lg text-gray-200 mb-12">
              {name} عزیز، ایمیل تایید پرداخت به آدرس {email} ارسال شد. لطفا
              ایمیل خود را چک کنید.
            </p>
          )}

          <p className="text-lg text-gray-200 mb-12">
            <Link to="/login" className="text-blue-500 hover:text-blue-700">
              برای ورود به حساب کاربری خود، اینجا کلیک کیند{' '}
            </Link>
          </p>
          <a
            href="/"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Back to Homepage
          </a>
        </div>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default PaymentSuccess;
