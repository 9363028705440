import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../api/api'; // Your axios instance or fetch wrapper
import UserContext from '../../context/usercontext';
import { useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { Alert, Button, Checkbox, Label, TextInput } from 'flowbite-react';

const LogIn = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Get the token from URL
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);

  const [status, setStatus] = useState('idle');
  console.log(status, error);
  useEffect(() => {
    if (token) {
      // Call backend to validate token and log in
      api
        .get(`/login?token=${token}`)
        .then((res) => {
          const { authorized } = res.data;

          if (authorized) {
            const user = jwtDecode(token);

            setUserDetails(JSON.stringify(user));
            localStorage.setItem('credentials', JSON.stringify(user));
            // If authorized, redirect to dashboard
            navigate('/dashboard');
          } else {
            setStatus('error');
            setError('لینک معتبر نیست');
          }
        })
        .catch(() => {
          setStatus('error');
          setError('خطایی پیش امده است');
        });
    }
  }, [token, navigate]);

  const handleGettoken = async () => {
    console.log(email);
    setStatus('loading');
    try {
      const result = await api.post('/login', { email });
      console.log(result);

      if (result.data.status == 'error') {
        setStatus('error');
        setError('کاربری با این ایمیل یافت نشد');
      } else setStatus('success');
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  };

  return (
    <div className="items-center flex flex-col items-center py-8 md:h-screen my-5">
      {status === 'error' && (
        <Alert color="failure">
          <span className="font-medium"> خطا: </span>
          {error}
        </Alert>
      )}

      {status === 'idle' && (
        <form className="flex w-4/5  flex-col gap-4 items-center ">
          <div>
            <span></span>
            <div className="mb-2 block">
              <Label
                htmlFor="email1"
                value="  ایمیل خود را وارد کنید تا لینک دایمی ورود به پنل برای شما ارسال شود"
              />
            </div>
            <TextInput
              dir="ltr"
              id="email1"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@gmail.com"
              required
            />
          </div>

          <Button onClick={() => handleGettoken()} className="bg-main">
            دریافت لینک ورود
          </Button>
        </form>
      )}
      <div className="text-center text-main mb-5 mt-5 font-custom1 text-xs">
        <a href="https://t.me/fitlinezsupport">ارتباط با پشتیبان فنی</a>
      </div>
      {status === 'success' && (
        <Alert color="success" onDismiss={() => alert('Alert dismissed!')}>
          <span className="font-medium"></span>لطفا ایمیل خود را چک کنید. ایمیلی
          حاوی لینک ورود به پنل برای شما ارسال شده است
        </Alert>
      )}
    </div>
  );
};

export default LogIn;
