import React from 'react';
import { Link } from 'react-router-dom';

const RegCta = (props) => {
  const { data } = props;
  return (
    <>
      <section className="py-1 lg:py-[4px] text-white font-custom1">
        <div
          style={{
            background:
              'linear-gradient(to right, rgb(64,59,108), rgb(30, 64, 175), rgb(64,59,108))',
          }}
          // className={`relative z-10 overflow-hidden rounded bg-primary py-12 px-8 md:p-[70px] bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900`}
        >
          <div className="text-center items-center bg-gradient-to-r from-indigo-500 via-green-500 to-red-500">
            <div className="w-full px-4 lg:w-full">
              <br></br>
              <span className="font-bold text-sm mt-4">
                هزینه ثبت نام :
                <spna className=" decoration-red-500 decoration-1">
                  <span className="text-xl">۴،۵۰۰،۰۰۰ تومان</span>
                </spna>
              </span>
              <br></br>
              {/* <span className="mb-5">تا ۲۳ مهرماه میتوانید با </span>
              <br></br>
              <span className=" font-bold text-4xl text-center dark:text-primary-400">
                ۳،۵۰۰،۰۰۰ تومان
              </span> */}
              <br></br>
              {/* <span className="mb-5">ثبت نام کنید</span> */}
            </div>

            <div className="w-full px-4 lg:w-full content-center">
              <div>
                <Link
                  style={{
                    textDecoration: 'none',
                    margin: '0 10px',
                  }}
                  to={`/userlogin`}
                  state={{ data: data }}>
                  <button
                    className=" 
                    content-center
                  bg-gold hover:bg-silver text-main font-bold hover:text-main py-2 px-2 border border-blue-500 hover:border-transparent rounded my-2 ">
                    ثبت نام
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegCta;
