import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from 'victory';
import { grayscale } from '../../../api/victoryGrayScaleTheme';
import { material } from '../../../api/victoryMaterialTheme';
import api from '../../../api/api';
import persianNumbers from '../../../api/persianNumber';

const SizeHistory = () => {
  const [reports, setReports] = useState([]);

  const [userId, setUserId] = useState('');

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);
      setUserId(parsedCredentials.userId);
    }
  }, []);

  useEffect(() => {
    const getReports = async () => {
      if (userId) {
        try {
          const res = await api.get(`/report/sizereport/${userId}`);
          setReports(res.data);
        } catch (err) {
          console.error(err);
        }
      }
    };

    getReports();
  }, [userId]);

  const createDataset = (label) => {
    const dataset = reports
      .map((report) => {
        const sizeObj = report.sizes.find((size) => size.name === label);
        return {
          x: new Date(report.date).toLocaleDateString('fa-IR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          y: sizeObj ? sizeObj.value : null,
          name: sizeObj ? sizeObj.name : null,
        };
      })
      .filter((dataPoint) => dataPoint.y !== null);

    return dataset;
  };

  const sizeNames = [
    'دور سینه',
    'بازوی چپ',
    'بازوی راست',
    'دور شکم',
    'دور کمر',
    'دور باسن',
    'ران چپ',
    'ران راست',
    'وزن',
  ];

  const [selectedSizes, setSelectedSizes] = useState(sizeNames);

  return (
    <div className="container flex flex-col mb-5 w-full h-full text-main">
      <div className="mx-4">
        {sizeNames.map((name) => (
          <label key={name} className=" my-2 mb-3">
            <input
              type="checkbox"
              checked={selectedSizes.includes(name)}
              onChange={() => {
                if (selectedSizes.includes(name)) {
                  setSelectedSizes((prev) =>
                    prev.filter((size) => size !== name)
                  );
                } else {
                  setSelectedSizes((prev) => [...prev, name]);
                }
              }}
            />
            <span className="ml-2 align-middle">{name}</span>
          </label>
        ))}
      </div>

      <VictoryChart
        theme={material}
        // domain={{ y: [0, ] }}
        containerComponent={<VictoryVoronoiContainer />}>
        <VictoryAxis
          dependentAxis
          label=""
          // tickValues={[0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500]}
        />
        <VictoryAxis label="تاریخ" />
        {selectedSizes.map((name, index) => (
          <VictoryLine
            interpolation="natural"
            data={createDataset(name)}
            labels={({ datum }) => `${datum.name}: ${datum.y}`}
            labelComponent={<VictoryTooltip />}
            style={{
              data: {
                stroke: '#' + Math.floor(Math.random() * 16777215).toString(16),
              },
              labels: { fontSize: 12, fill: '#333' },
            }}
          />
        ))}
      </VictoryChart>

      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">تاریخ</th>
              {sizeNames.map((name) => (
                <th key={name} className="border border-gray-300 p-2 text-xs">
                  {name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report._id}>
                <td className="border border-gray-300 p-2">
                  {new Date(report.date).toLocaleDateString('fa-IR', {
                    month: '2-digit',
                    day: '2-digit',
                  })}
                </td>
                {sizeNames.map((name) => {
                  const sizeObj = report.sizes.find(
                    (size) => size.name === name
                  );
                  return (
                    <td key={name} className="border border-gray-300 p-2">
                      {sizeObj ? persianNumbers(sizeObj.value) : '-'}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SizeHistory;
