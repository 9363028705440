import React, { useState } from 'react';
import axios from 'axios';
import api from './api';

const ImageUpload = () => {
  const [singleFile, setSingleFile] = useState(null);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [singleImageUrl, setSingleImageUrl] = useState('');
  const [multipleImageUrls, setMultipleImageUrls] = useState([]);

  const handleSingleFileChange = (event) => {
    setSingleFile(event.target.files[0]);
  };

  const handleMultipleFilesChange = (event) => {
    setMultipleFiles(event.target.files);
  };

  const handleSingleUpload = async () => {
    if (!singleFile) {
      alert('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('image', singleFile);

    try {
      const response = await api.post('/imageUploader/single', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setSingleImageUrl(response.data.url);
      alert('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading image', error);
      alert('Error uploading image');
    }
  };

  //   const handleMultipleUpload = async () => {
  //     if (multipleFiles.length === 0) {
  //       alert('Please select files first');
  //       return;
  //     }

  //     const formData = new FormData();
  //     for (let i = 0; i < multipleFiles.length; i++) {
  //       formData.append('images', multipleFiles[i]);
  //     }

  //     try {
  //       const response = await api.post(
  //         'http://localhost:3001/upload/multiple',
  //         formData,
  //         {
  //           headers: {
  //             'Content-Type': 'multipart/form-data',
  //           },
  //         }
  //       );

  //       setMultipleImageUrls(response.data.urls);
  //       alert('Images uploaded successfully');
  //     } catch (error) {
  //       console.error('Error uploading images', error);
  //       alert('Error uploading images');
  //     }
  //   };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
      }}>
      <h2>Upload Single Image to Google Cloud Storage</h2>
      <input type="file" onChange={handleSingleFileChange} />
      <button onClick={handleSingleUpload}>Upload Single</button>
      {singleImageUrl && (
        <div>
          <h3>Uploaded Image:</h3>
          <img src={singleImageUrl} alt="Uploaded" />
        </div>
      )}

      {/* <h2>Upload Multiple Images to Google Cloud Storage</h2>
      <input type="file" multiple onChange={handleMultipleFilesChange} />
      <button onClick={handleMultipleUpload}>Upload Multiple</button>
      {multipleImageUrls.length > 0 && (
        <div>
          <h3>Uploaded Images:</h3>
          {multipleImageUrls.map((url, index) => (
            <img key={index} src={url} alt={`Uploaded ${index}`} />
          ))}
        </div>
      )} */}
    </div>
  );
};

export default ImageUpload;
