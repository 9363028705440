import React, { useState } from 'react';
import calculateMacronutrients from '../../api/calorieCalc';
import CustomTabs from './tabs';
import MacrosChart from './macrosChart';
import toPersianNumber from '../../api/persianNumber';

function Calories({
  mildWeightLossCalories,
  weightLossCalories,
  extremeWeightLossCalories,
  maintainWeightCalories,
  bulkWeightCalories,
}) {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div className="stats stats-vertical  border-2 border-gray-400 w-full">
      <div
        onClick={() => setActiveTab(1)}
        className={`${
          activeTab === 1 && 'font-bold bg-gray-200'
        } stat text-fitlinez border-1`}>
        <div className="stat-figure text-fitlinez">
          <div className="stat-value">
            {toPersianNumber(parseInt(maintainWeightCalories))}{' '}
            <div className="stat-desc">کالری در روز </div>
          </div>
        </div>

        <div
          className={`${
            activeTab === 1 && 'font-bold'
          } stat-title text-fitlinez`}>
          {' '}
          کالری مناسب برای حفظ وزن کنونی
        </div>
      </div>
      {activeTab === 1 && <MacrosChart calories={maintainWeightCalories} />}
      <div
        onClick={() => setActiveTab(2)}
        className={`${
          activeTab === 2 && 'font-bold bg-gray-200'
        } stat text-fitlinez border-1`}>
        <div className="stat-figure text-fitlinez">
          <div className="stat-value">
            {toPersianNumber(parseInt(mildWeightLossCalories))}
            <div className="stat-desc">کالری در روز </div>
          </div>
        </div>

        <div
          className={`${
            activeTab === 2 && 'font-bold'
          } stat-title text-fitlinez`}>
          {' '}
          کالری مناسب برای کاهش وزن آهسته
        </div>

        <div className="stat-desc ">↘︎ ۲۵۰ گرم/هفته</div>
      </div>
      {activeTab === 2 && <MacrosChart calories={mildWeightLossCalories} />}

      <div
        onClick={() => setActiveTab(3)}
        className={`${
          activeTab === 3 && 'font-bold bg-gray-200'
        } stat text-fitlinez border-1`}>
        <div className="stat-figure text-fitlinez">
          <div className="stat-value">
            {toPersianNumber(parseInt(weightLossCalories))}
            <div className="stat-desc">کالری در روز </div>
          </div>
        </div>
        <div
          className={`${
            activeTab === 3 && 'font-bold'
          } stat-title text-fitlinez`}>
          {' '}
          کالری مناسب برای کاهش وزن
        </div>

        <div className="stat-desc">↘︎ نیم کیلوگرم/هفته</div>
      </div>
      {activeTab === 3 && <MacrosChart calories={weightLossCalories} />}

      <div
        onClick={() => setActiveTab(4)}
        className={`${
          activeTab === 4 && 'font-bold bg-gray-200'
        } stat text-fitlinez border-1`}>
        <div className="stat-figure text-fitlinez">
          <div className="stat-value ">
            {toPersianNumber(parseInt(extremeWeightLossCalories))}
            <div className="stat-desc ">کالری در روز </div>
          </div>
        </div>
        <div
          className={`${
            activeTab === 4 && 'font-bold'
          } stat-title text-fitlinez`}>
          {' '}
          کالری مناسب برای کاهش وزن سریع
        </div>

        <div className="stat-desc">↘︎ ۱ کیلوگرم/هفته</div>
      </div>
      {activeTab === 4 && <MacrosChart calories={extremeWeightLossCalories} />}

      <div
        onClick={() => setActiveTab(5)}
        className={`${
          activeTab === 5 && 'font-bold bg-gray-200'
        } stat text-fitlinez border-1`}>
        <div className="stat-figure text-fitlinez">
          <div className="stat-value ">
            {toPersianNumber(parseInt(bulkWeightCalories))}
            <div className="stat-desc ">کالری در روز </div>
          </div>
        </div>
        <div
          className={`${
            activeTab === 5 && 'font-bold'
          } stat-title text-fitlinez`}>
          {' '}
          کالری مناسب برای افزایش وزن
        </div>

        <div className="stat-desc">↗︎</div>
      </div>
      {activeTab === 5 && <MacrosChart calories={bulkWeightCalories} />}
    </div>
  );
}

export default Calories;
