import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import axios from 'axios';
import api from '../../api/api';

const CallbackRial = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get('Status');
  const authority = params.get('Authority');

  console.log('callback values', status, authority);

  useEffect(() => {
    api
      .post(`/zarinpal/callback`, {
        status: status,
        authority: authority,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [status]);

  return (
    <div>
      {status === 'OK' ? (
        <>
          <h1 className="text-center text-main text-xl font-custom1">
            پرداخت با موفقیت انجام شد
          </h1>
          <div className="flex flex-col items-center justify-center grid-cols-2 ">
            <h2 className="text-center text-main text-xl mt-11 font-custom1">
              لطفا وارد اکانت خود شوید
            </h2>
            <button
              className="btn btn-main mt-11 bg-main hover:bg-main-dark w-1/4 rounded rounded-xl h-20 font-bold btn-sx text-center text-white text-xl"
              onClick={() => {
                window.location.href = '/login';
              }}>
              ورود
            </button>
          </div>
        </>
      ) : (
        <h1 className="text-center text-main text-2xl font-custom1">
          مشکلی در پرداخت پیش امده، لطفا بعدا تلاش کنید
        </h1>
      )}
    </div>
  );
};

export default CallbackRial;
