import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import AreaCode from '../../data/areacode';

import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '../../component/Modal';
import api from '../../api/api';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const Bookingform = () => {
  const [agreed, setAgreed] = useState(false);
  const [userForm, setUserForm] = useState({});
  const [country, setCountry] = useState(null);
  const [product, setProduct] = useState(null);
  const [name, setName] = useState(null);
  const [whatsapp, setWhatsapp] = useState(null);
  const [email, setEmail] = useState(null);
  const [userWant, setUserWant] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  console.log('isError', isError);
  console.log('showSuccess', showSuccess);

  const [message, setMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(0);

  console.log('isLoading', isLoading);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserForm((prev) => ({ ...prev, [name]: value }));
  };
  //update country, product, name, whatsapp, email, after userForm is set
  useEffect(() => {
    if (userForm.country) {
      setCountry(userForm.country);
    }
    if (userForm.product) {
      setProduct(userForm.product);
    }
    if (userForm.name) {
      setName(userForm.name);
    }
    if (userForm.whatsapp) {
      setWhatsapp(userForm.whatsapp);
    }
    if (userForm.email) {
      setEmail(userForm.email);
    }
    if (userForm.userWant) {
      setUserWant(userForm.userWant);
    }
  }, [userForm]);

  //useEffect to set county value after userForm.country is set
  useEffect(() => {
    if (userForm.country) {
      setCountry(userForm.country);

      if (userForm.country === 'ایران' && userForm.product === 'shape-up') {
        setPrice('۳،۵۰۰،۰۰۰ تومان');
      } else if (
        userForm.country === 'ایران' &&
        userForm.product === 'private'
      ) {
        setPrice('۵،۸۰۰،۰۰۰ تومان');
      } else if (
        userForm.country !== 'ایران' &&
        userForm.product === 'shape-up'
      ) {
        setPrice('۱۱۵ یورو');
      } else if (
        userForm.country !== 'ایران' &&
        userForm.product === 'private'
      ) {
        setPrice('۱۸۹ یورو');
      }
    }
  }, [userForm]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  };
  const handleSend = () => {
    setIsLoading(true);
    setTimeout(async () => {
      api
        .post('/form', userForm)
        .then((res) => {
          console.log('res: ', res);
          setIsLoading(false);
          setMessage('اطلاعات شما با موفقیت ثبت شد');
          setShowSuccess(true);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 409) {
            setIsError(true);
            setMessage('شما قبلا رزرو کرده اید');
          } else {
            setIsError(true);
            setMessage('مشکلی پیش آمد. لطفا با پشتیبانی تماس بگیرید');
          }
        });
    }, 2000);
  };

  return (
    <div className=" bg-white px-6 py-24 sm:py-32 lg:px-8 font-custom1">
      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true">
        <div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-main sm:text-4xl">
          فرم رزرو{' '}
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          لطفا اطلاعات خود را در فرم زیر وارد کنید تا در اسرع وقت با شما تماس
          بگیریم
        </p>
      </div>
      <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1 text-main">
          <div className="sm:col-span-2">
            <label
              htmlFor="name"
              className="block text-sm font-semibold leading-6 text-gray-900">
              نام
            </label>
            <div className="mt-2.5">
              <input
                required
                type="text"
                name="name"
                id="name"
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            {/* <label
              htmlFor="phone-number"
              className="block text-sm font-semibold leading-6 text-gray-900">
              انتخاب دوره{' '}
            </label> */}
            <div className="relative mt-2.5">
              <div>
                <select
                  required
                  id="product"
                  name="product"
                  onChange={handleChange}
                  className="text-center block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <option value="">
                    لطفا دوره ی مورد نظر خود را انتخاب کنید{' '}
                  </option>
                  <option value="shape-up">دوره shape up</option>
                  <option value="private"> کلاس خصوصی</option>
                </select>
              </div>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="company"
              className="block text-sm font-semibold leading-6 text-gray-900">
              ایدی اینستاگرام
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="instagram"
                id="instagram"
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900">
              ایمیل
            </label>
            <div className="mt-2.5">
              <input
                required
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="phone-number"
              className="block text-sm font-semibold leading-6 text-gray-900">
              شماره تلفن{' '}
            </label>
            <div className="relative mt-2.5 " dir="ltr">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">
                  Country
                </label>
                <select
                  disabled={showAlert}
                  required
                  id="country"
                  name="country"
                  onChange={handleChange}
                  className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
                  <option value="">انتخاب کنید</option>
                  {AreaCode.map((country, i) => (
                    <option key={i} value={country.name}>
                      {country.flag}
                      {country.dial_code}
                    </option>
                  ))}
                </select>
                <ChevronDownIcon
                  className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                dir="ltr"
                required
                type="tel"
                name="whatsapp"
                id="whatsapp"
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          {/* <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-gray-900">
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                name="message"
                id="message"
                rows={4}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={''}
              />
            </div>
          </div> */}
          {/* <Switch.Group
            as="div"
            className="flex gap-x-4 sm:col-span-2"
            dir="ltr">
            <div className="flex h-6 items-center">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? 'bg-indigo-600' : 'bg-gray-200',
                  'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}>
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? 'translate-x-3.5' : 'translate-x-0',
                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
            <Switch.Label
              className="text-sm leading-6 text-gray-600 "
              dir="ltr">
              By selecting this, you agree to our{' '}
              <a href="#" className="font-semibold text-indigo-600">
                privacy&nbsp;policy
              </a>
              .
            </Switch.Label>
          </Switch.Group> */}
        </div>

        <div className="mt-10">
          {showAlert === false && (
            <button
              //disable button when userForm object has no name,product,whatsapp,country,email
              disabled={
                name === null ||
                email === null ||
                country === null ||
                whatsapp === null ||
                product === null
                  ? true
                  : false
              }
              onClick={() => setShowAlert(true)}
              className={`block w-full rounded-md
            //  ${
              //   name === null ||
              //   email === null ||
              //   country === null ||
              //   whatsapp === null ||
              //   product === null
              //     ? 'bg-green-600 hover:bg-green-400 '
              //     :
              'bg-green-500 hover: bg-green-400 '
            } px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
              ثبت درخواست{' '}
            </button>
          )}
        </div>
        {showAlert && country !== null && userForm.whatsapp !== undefined && (
          <div
            id="alert-additional-content-1"
            className="p-4 mb-4 mt-5 text-main border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
            role="alert">
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"></path>
              </svg>
              <span className="sr-only">Info</span>
              <h3 className="text-lg m-2">لطفا توجه کنید</h3>
            </div>
            <div className="mt-2 mb-4 text-lg italic">
              هزینه شرکت در{' '}
              {product === 'private' ? 'کلاس خصوصی' : 'دوره Shape up with Azar'}{' '}
              برای شما{' '}
              <span className="font-bold not-italic text-green-600">
                {price}
              </span>{' '}
              می باشد
            </div>
            <div className="flex justify-between relative">
              {isLoading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-main"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <>
                  <button
                    onClick={handleSend}
                    type="button"
                    className="text-main hover:text-white bg-blue-800 hover:bg-green-500  border-b-main focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    ادامه و رزرو
                  </button>

                  <button
                    onClick={() => (window.location.href = '/')}
                    type="button"
                    className="text-blue-800 bg-transparent border hover:bg-orange-300 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-blue-600 dark:border-blue-600 dark:text-blue-400 dark:hover:text-white dark:focus:ring-blue-800">
                    انصراف
                  </button>
                </>
              )}
            </div>

            {/*  display Modal wheater showSuccess or isError set */}

            {isError && (
              <Modal
                message={message}
                buttonTitle="باشه"
                title="رزرو دوره"
                onClick={() => (window.location.href = '/')}
              />
            )}

            {showSuccess && (
              <Modal
                message={message}
                buttonTitle="باشه"
                title="رزرو دوره"
                onClick={() => (window.location.href = '/')}
              />
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default Bookingform;
