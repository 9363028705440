import React from 'react';
import sStar from '../../../img/star.svg';
function Stars(props) {
  const { star } = props;
  return (
    <div>
      <ul className="flex justify-center lg:justify-start">
        {Array.from({ length: star }, (_, i) => (
          <li>
            <img
              src={sStar}
              alt="star"
              style={{
                width: '20px',
                height: '20px',
                margin: '0 2px',
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Stars;
