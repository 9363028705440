import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProductData from '../Dashboard/userData/sessions';
import SizeHistory from '../../component/weightChart';
import CoachReport from './coachReport';

function UserIndex(props) {
  const [userId, setUserId] = useState(null);
  const [userBasicData, setUserBasicData] = useState(null);
  const [userNutritionData, setUserNutritionData] = useState(null);
  const [userExerciseData, setUserExerciseData] = useState(null);
  const { hasReport, coachReport } = props;
  const userData = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState();
  const [proteinAmount, setProteinAmount] = useState(0);
  const [fatAmount, setFatAmount] = useState(0);
  const [carbsAmount, setCarbsAmount] = useState(0);

  const calculateGrams = () => {
    // Calculate calories for each macronutrient
    const proteinCalories =
      (coachReport?.protein / 100) * coachReport?.calories;
    const fatCalories = (coachReport?.fat / 100) * coachReport?.calories;
    const carbsCalories = (coachReport?.carbs / 100) * coachReport?.calories;

    // Calculate grams for each macronutrient
    setProteinAmount(proteinCalories / 4); // 4 calories per gram of protein
    setFatAmount(fatCalories / 9); // 9 calories per gram of fat
    setCarbsAmount(carbsCalories / 4); // 4 calories per gram of carbs
  };

  useEffect(() => {
    if (coachReport) {
      calculateGrams();
    }
  }, [coachReport]);

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    const parsedCredentials = JSON.parse(credentials);
    setUserId(parsedCredentials.id);
    setUserDetails(parsedCredentials);
    // setUserProducts(parsedCredentials?.userProduct);
    setUserNutritionData(parsedCredentials?.userNutritionData);
    setUserBasicData(parsedCredentials?.userBasicData);
    setUserExerciseData(parsedCredentials?.userWorkOutData);
  }, []);

  return (
    <div className="p-6">
      <div className="p-8 bg-white shadow mt-1">
        <div className="grid grid-cols-2 md:grid-cols-3"></div>{' '}
        <div className="mt-20  border-b pb-12">
          {' '}
          <div className="flex flex-col lg:flex-row md:max-lg:flex md:justify-between mt-5">
            {hasReport && (
              <CoachReport
                coachReport={coachReport}
                proteinAmount={proteinAmount}
                fatAmount={fatAmount}
                carbsAmount={carbsAmount}
                maintainCalorie={coachReport.maintainCalorie}
                className="lg:w-1/2 md:max-lg:flex lg:mr-5 mb-5 md:mb-0"
              />
            )}
            {/* <SizeHistory userId={userId} className="lg:w-1/2 md:max-lg:flex" /> */}
          </div>
          {props?.userProducts && (
            <ProductData userProducts={props.userProducts} />
          )}
        </div>
        <div className="mt-12 flex flex-col justify-center"></div>
      </div>
    </div>
  );
}

export default UserIndex;
