import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div
      dir="ltr"
      className="bg-white p-6 rounded-lg shadow-md max-w-2xl mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-4">Fitlinez Privacy Policy</h1>
      <p className="mb-4">Last Updated: Sep 9, 2023</p>
      <p>
        Welcome to Fitlinez! We are committed to protecting your personal
        information and your right to privacy. If you have any questions or
        concerns about our policy, or our practices with regards to your
        personal information, please contact us at info@fitlinez.com.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        1. Information We Collect
      </h2>
      <p>
        As a part of our Mobile Application, we collect the following personal
        information:
      </p>
      <ul className="list-disc pl-5 mt-2">
        <li className="mb-1">Name: To personalize your user experience.</li>
        <li className="mb-1">
          Email: For account setup, password recovery, and communication
          purposes.
        </li>
        <li className="mb-1">
          Gender: To tailor workout and fitness recommendations.
        </li>
        <li className="mb-1">
          Weight, Height, and Body Part Sizes: To create a personalized workout
          plan tailored to your physique and fitness goals.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        2. How We Use Your Information
      </h2>
      <p>We use the information we collect in various ways, including to:</p>
      <ul className="list-disc pl-5 mt-2">
        <li className="mb-1">
          Provide, operate, and maintain our Mobile Application.
        </li>
        <li className="mb-1">
          Improve, personalize, and expand our Mobile Application.
        </li>
        <li className="mb-1">
          Understand and analyze how you use our Mobile Application.
        </li>
        <li className="mb-1">
          Develop new products, services, features, and functionality.
        </li>
        <li className="mb-1">
          Communicate with you, either directly or through one of our partners,
          for customer service, to provide you with updates and other
          information relating to the Mobile Application, and for marketing and
          promotional purposes.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        3. Sharing and Disclosure
      </h2>
      <p>
        We do not share or disclose any of your personal information without
        your consent, except for the purposes specified in this policy or if
        required by law.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">4. Security</h2>
      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, please also remember that we cannot guarantee that the
        internet itself is 100% secure. Although we will do our best to protect
        your personal information, the transmission of personal information to
        and from our Mobile Application is at your own risk.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">5. Retention</h2>
      <p>
        We will retain your personal information only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use your information to the extent necessary to comply with
        our legal obligations, resolve disputes, and enforce our policies.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">6. Your Rights</h2>
      <p>
        You have the right to request access to the personal information we
        collect about you, change or correct your personal information, delete
        your personal information, or object to our processing of your personal
        information.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">
        7. Changes to This Privacy Policy
      </h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-2">8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at info@fitlinez.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
