import React, { useEffect, useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import callouts from '../../data/services';
import { Link, useLocation } from 'react-router-dom';
import countries from '../../data/countries';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import api from '../../api/api';
import ProductCard from './productCard';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('ایمیل الزامی است')
    .email('ایمیل وارد شده نامعتبر است')
    .max(50, 'ایمیل نمیتواند بیشتر از 50 کاراکتر باشد')
    .min(5, 'ایمیل نمیتواند کمتر از 5 کاراکتر باشد')
    .label('Email'),
  location: Yup.string().required().label('Location'),
  discount: Yup.string()
    .label('Discount')
    .matches('academy', ' کد تخفیف نامعتبر است'),
  platform: Yup.string(),
});

const PreSignUp = () => {
  const locations = useLocation();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [product, setProduct] = useState(null);
  const [isDiscountValid, setIsDiscountValid] = useState(false);
  const [finalPrice, setFinalPrice] = useState(null);
  const [finalPriceRial, setFinalPriceRial] = useState(null);
  const [inputDiscount, setInputDiscount] = useState('');
  let platform = 'web';
  console.log('product in signup', product);
  useEffect(() => {
    if (locations?.state?.data) {
      setProduct(locations?.state?.data);
    }
  }, [locations?.state?.data]);

  const discountCode = 'academy';

  const inputStyle =
    'input input-bordered w-full max-w-xs font-custom1 mb-5 mt-3';
  const errorStyle = 'text-main text-lg italic bg-red-100 p-2 rounded-lg';

  const handleDiscountChange = () => {
    console.log('inputDiscount', inputDiscount, discountCode);
    if (inputDiscount === discountCode) {
      setIsDiscountValid(true);
      setFinalPrice(product.price * 0.5);
      setFinalPriceRial(product.priceRial * 0.5);
    } else {
      setIsDiscountValid(false);
    }
  };

  useEffect(() => {
    handleDiscountChange(inputDiscount);
  }, [inputDiscount]);

  return (
    <div>
      <div
        className="flex flex-col justify-center items-center h-screen text-main font-custom1

      ">
        {product ? (
          <>
            <ProductCard
              name={product.name}
              price2={
                isDiscountValid ? product.priceRial * 0.5 : product.priceRial
              }
              price1={isDiscountValid ? product.price * 0.5 : product.price}
            />
            {product.name === 'آکادمی شیپ آپ' && (
              <div
                className="form-control
            mt-5 mb-5 w-full max-w-xs font-custom1 mb-5 mt-3
            ">
                <label className="label cursor-pointer">
                  <span className="label-text">کد تخفیف دارم</span>
                  <input
                    type="checkbox"
                    onClick={() => setShowDiscount(!showDiscount)}
                    className="checkbox"
                  />
                </label>
              </div>
            )}

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                email: '',
                location: '',
                discount: '',
                platform: 'web',
              }}
              onSubmit={(values) => {
                setEmail(values.email);
                setIsLoading(true);

                api
                  .post(`/api/prelogin`, values)
                  .then(async (res) => {
                    if (res.data.status === 200) {
                      setIsLoading(false);
                      const queryParams = new URLSearchParams({
                        email: values.email,
                        name: name,
                        location: values.location,
                        amount: isDiscountValid
                          ? product.price * 0.5
                          : product.price,
                        amountRial: isDiscountValid
                          ? product.priceRial * 0.5
                          : product.priceRial,
                        product: product.nameEn,
                        platform: 'web',
                      });
                      setName(res.data.data);
                      window.location.href = `/loadingpayment/?${queryParams.toString()}`;
                    } else {
                      api.post(`/register`, { payload: values });
                      const queryParams = new URLSearchParams({
                        email: values.email,
                        name: name,
                        location: values.location,
                        amount: isDiscountValid
                          ? product.price * 0.5
                          : product.price,
                        amountRial: isDiscountValid
                          ? product.priceRial * 0.5
                          : product.priceRial,
                        product: product.nameEn,
                        platform: 'web',
                      });
                      window.location.href = `/loadingpayment/?${queryParams.toString()}`;
                    }
                  })
                  .catch((e) => {
                    setIsLoading(false);
                    alert(e);
                    BugsnagPluginReact.notify(e);
                  });
              }}>
              {({ handleChange, handleSubmit }) => (
                <>
                  <Field
                    className={inputStyle}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="ایمیل"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className={errorStyle}
                  />
                  {showDiscount && (
                    <>
                      <Field
                        className={inputStyle}
                        id="discount"
                        name="discount"
                        type="text"
                        placeholder="کد تخفیف"
                        onChange={(e) => {
                          handleChange(e);
                          setInputDiscount(e.target.value);
                        }}
                      />

                      <ErrorMessage
                        name="discount"
                        component="div"
                        className={errorStyle}
                      />
                    </>
                  )}

                  <select
                    dir=""
                    className={inputStyle}
                    name="location"
                    onChange={handleChange('location')}>
                    <option className="align-right" disabled selected>
                      <span className="align-left"> کشور محل سکونت </span>
                    </option>

                    <option className="align-left" value="Iran">
                      ساکن ایران هستم
                    </option>
                    <option className="align-left" value="NotIran">
                      ساکن ایران نیستم
                    </option>
                  </select>
                  <ErrorMessage
                    name="location"
                    component="div"
                    className={errorStyle}
                  />
                  <button
                    className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-800 dark:bg-white dark:border-gray-700 dark:text-gray-900 dark:hover:bg-gray-200 me-2 mb-2"
                    onClick={handleSubmit}
                    type="submit">
                    ادامه
                  </button>
                  <div className="text-center text-main mb-5 mt-5 font-custom1">
                    در صورتی که در مرحله ثبت نام به مشکلی برخوردید لطفا از طریق
                    لینک زیر با پشتیبان در تماس باشید
                  </div>
                  <button>
                    <Link
                      to="https://t.me/fitlinezsupport"
                      className="bg-main hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mb-10">
                      تماس با پشتیبان
                    </Link>
                  </button>
                  {isLoading && <div className={errorStyle}>Loading...</div>}
                </>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="dropdown">
              <label
                tabIndex={0}
                className="btn m-1 font-custom1 text-center text-2xl font-bold text-main"
                onClick={() => setDropdownOpen(!dropdownOpen)}>
                انتخاب پکیج
              </label>
              {dropdownOpen && (
                <div
                  tabIndex={0}
                  className="dropdown-content font-custom1 z-[1] card card-compact w-64 p-2 shadow bg-primary text-main border-e-8">
                  {callouts.map((callout, i) => (
                    <div
                      key={i}
                      className="card-body border-b-4"
                      onClick={() => {
                        setProduct(callout);
                        setDropdownOpen(false);
                      }}>
                      <h4 className="font-bold text-center bg-gold p-4 rounded cursor-pointer">
                        {callout.name}
                        <p dir="ltr" className="card-text">
                          {callout.nameEn}
                        </p>
                      </h4>
                      <p>
                        <div>قیمت برای کاربران خارج از کشور</div>
                        <div>
                          {callout.price} {callout.currency}
                        </div>
                      </p>
                      <p>
                        قیمت برای کاربران داخل کشور {callout.priceRialSign}{' '}
                        تومان
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PreSignUp;
