import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    user: null,
    error: null,
    isLoggedIn: false,
  },
  reducers: {
    login: (state, action) => {
      state.name = action.payload.name;
      state.phone = action.payload.phone;
      // Set isLoggedIn to true when the user logs in
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.user = '';
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
