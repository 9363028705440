import React, { useState } from 'react';
import api from '../../api/api';
import { useParams, useSearchParams } from 'react-router-dom';

function SubscriptionIndex() {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get('email');
  console.log(email);
  const reason = 'join-meeting-academy';
  const [status, setStatus] = useState('idle');
  const handleSubmit = async (e) => {
    setStatus('loading');
    e.preventDefault();
    try {
      // Call the API to subscribe the user
      const response = await api.post('/subscription/add', {
        email: email,
        reason: reason,
      });
      setStatus('success');
      console.log(response);
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  };

  return (
    <div
      className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 text-center
      ">
      {status === 'idle' && email && (
        <div className="  my-5 text-center ">
          <h1
            className="
          text-3xl font-bold
          mb-4">
            شرکت در جلسه آنلاین ماهیانه آکادمی
          </h1>
          <div className="flex justify-center items-center">
            <div className="items-center text-center text-gray-600 text-sm mb-4 bg-gray-100 p-2 rounded-lg border border-gray-200">
              <span>
                چهارشنبه شنبه ۸ تیرماه ماه (10 July)ساعت ۷ شب بوقت ایران
              </span>
            </div>
          </div>
          <p className="mb-4">
            برای شرکت در جلسه آنلاین ماهیانه آکادمی، با فشردن دکمه زیر، حضور خود
            را تایید کنید.
          </p>
          <button onClick={handleSubmit} className="btn btn-primary">
            در جلسه آنلاین شرکت میکنم
          </button>
        </div>
      )}
      {status === 'loading' && (
        <span className="loading loading-spinner text-neutral"></span>
      )}
      {status === 'error' && (
        <div role="alert" className="alert alert-warning">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>متاسفانه خطایی رخ داده است. لطفا دوباره تلاش کنید.</span>
        </div>
      )}
      {status === 'success' && (
        <div role="alert" className="alert alert-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>
            ایمیل شما با موفقیت ثبت شد. برای شرکت در جلسه آنلاین، ایمیلی حاوی
            لینک جلسه برای شما ارسال خواهد شد.
          </span>
        </div>
      )}
    </div>
  );
}

export default SubscriptionIndex;
