export const Statistic = () => {
  const stats = [
    {
      id: 1,
      name: ' از این دوره <b style="color:#FFD700">رضایت کامل</b> داشتند.',
      value: '۹۷٪',
    },
    {
      id: 2,
      name: 'ورزش و تغذیه ی درست را تبدیل به <b style="color:#FFD700">روتین</b> کردند.',
      value: '۹۴٪',
    },
    {
      id: 3,
      name: 'شرکت کنندگان به بدن خود<b style="color:#FFD700"> حس بهتری</b> داشتند.',
      value: '۹۵٪',
    },
  ];

  return (
    <section className="text-gray-600 body-font">
      <div className="text-main  lg:px-8 lg:py-20 bg-main text-white">
        <div className="grid gap-10 row-gap-8 lg:grid-cols-3 px-3 py-4">
          {stats.map((stat) => (
            <div className="flex flex-col items-center p-4 bg-blue-600 rounded-lg shadow-lg">
              <div className="flex">
                <h6 className="mr-2 text-4xl font-bold md:text-5xl text-deep-purple-accent-400 text-center">
                  {stat.value}
                </h6>
                <div className="flex items-center justify-center rounded-full bg-teal-accent-400 w-7 h-7">
                  <svg
                    className="text-teal-900 w-7 h-7"
                    stroke="currentColor"
                    viewBox="0 0 52 52">
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>

              <p
                className="text-white font-custom1"
                dangerouslySetInnerHTML={{ __html: stat.name }}></p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
