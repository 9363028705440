import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MCountDown from './CountDown';
import PriceComment from './priceComment';
function RegButton(props) {
  const { data } = props;
  const targetDate = new Date('2023-08-10T23:59:59'); // Replace this with your target date

  // useEffect(() => {
  //   let style1 = document.createElement('style');
  //   let style2 = document.createElement('style');
  //   let after = document.getElementById('after-te1');
  //   let before = document.getElementById('before-te1');
  //   const setKeyframesRules = (n, start = 0) => {
  //     let steps = '';
  //     for (let i = start; i <= n; i++) {
  //       let percent = (i / n) * 100;
  //       let random1 = `${Math.random() * 150}px`;
  //       let random2 = `${Math.random() * 150}px`;
  //       steps = steps.concat(
  //         `${percent}% { clip: rect(${random1}, 9999px, ${random2}, 0) } `
  //       );
  //     }
  //     return steps;
  //   };
  //   let keyframes1 = `@keyframes glitch-anim-1 { ${setKeyframesRules(24)} }`;
  //   let keyframes2 = `@keyframes glitch-anim-2 { ${setKeyframesRules(32, 2)} }`;
  //   style1.innerHTML = keyframes1;
  //   style2.innerHTML = keyframes2;
  //   after.appendChild(style1);
  //   before.appendChild(style2);
  //   after.style.animation =
  //     'glitch-anim-1 2.5s infinite linear alternate-reverse';
  //   before.style.animation =
  //     'glitch-anim-2 3s infinite linear alternate-reverse';
  // }, []);
  console.log(data);
  return (
    <section>
      <div className="flex grid grid-cols-1 justify-between">
        <button
          disabled
          className="bg-main hover:bg-main-dark text-white font-bold py-2 px-4 rounded my-5">
          <span>
            <span
              className="text-3xl font-bold text-white hover:bg-main-light mx-3 my-3 py-2 px-4 cursor-pointer
            "></span>
            <h1 className=" text-4xl font-bold uppercase relative inline-block">
              {' '}
              <span
                id="after-te1"
                className="absolute top-0 -left-0.5 w-full h-full bg-transparent"
                style={{
                  textShadow: '-2px 0 spin(#49FC00, 180)',
                  clipPath: 'rect(85px, 550px, 140px, 0)',
                }}
                aria-hidden="true">
                ثبت نام
              </span>{' '}
            </h1>
            {/* <span className="font-custom1 text-4xl mt-10 mx-10">
              ظرفیت تکمیل شد
            </span> */}
          </span>
        </button>
      </div>
      <PriceComment />
    </section>
  );
}

export default RegButton;
