import { Button, Card } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CommentForm from '../../../../component/comment';
import Attachments from '../../../../component/attachments';
import ViewCounter from '../../../../component/counter';
import AdditionalLinks from '../../../../component/additionalLinks';
import './style.css';
const ClassSession = () => {
  const { state } = useLocation();

  const {
    video,
    link,
    attachments,
    _id,
    product,
    session,
    description,
    additionalLinks,
  } = state.data;

  console.log('state', state.data);
  return (
    <>
      <div
        dir="ltr"
        className="max-w-full bg-white
      ">
        <div className="justify-center items-stretch bg-white flex flex-col">
          <div className="bg-white flex flex-row min-h-[56px] w-full max-md:max-w-full" />
          <div className="items-stretch flex w-full flex-col pr-5 py-6 max-md:max-w-full">
            <div className="max-md:max-w-full">
              <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <div className="nb flex grow flex-col justify-center w-full px-16 py-12 items-end max-md:max-w-full max-md:mt-3 max-md:px-5">
                  {video ? (
                    <video
                      src={video}
                      controls
                      className=" sm:w-full lg:w-1/4 items-center justify-center flex mx-auto inset-0   max-h-fit object-cover"
                    />
                  ) : (
                    <iframe
                      //className=" video"
                      className=" items-center justify-center flex mx-auto inset-0 lg:w-full lg:max-h-fit object-contain"
                      //responsive width

                      width={480}
                      height={640}
                      src={link}
                      allow="autoplay"></iframe>
                  )}
                </div>
                {/* <ViewCounter id={_id} /> */}
              </div>
              {description && (
                <div
                  dir="rtl"
                  className=" rounded-lg p-4 mx-5 border border-gray-300
          ">
                  <h4 className="text-2xl font-bold">توضیحات جلسه</h4>
                  <p className="text-gray-700 dark:text-gray-400">
                    {description}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          dir="rtl"
          className=" rounded-lg p-4 mx-5 border border-gray-300 mt-3
       ">
          <div className="flex flex-col w-full max-md:max-w-full mx-5 max-md:mt-3 max-md:px-1 max-md:py-1 max-md:items-stretch max-md:flex-col max-md:justify-center max-md:gap-2 max-md:flex">
            <span className="flex justify-start bg-main rounded-md p-3 w-full">
              <h3 className="text-xl font-bold text-right tracking-tight text-white dark:text-white ">
                پیوست ها
              </h3>
            </span>
            <Attachments attachments={attachments} />
            {additionalLinks?.length > 0 && (
              <>
                <span className=" flex justify-start bg-main rounded-md p-3 md:w-full mb-5">
                  <h3 className="text-xl font-bold text-right tracking-tight text-white dark:text-white ">
                    لینک ها
                  </h3>
                </span>
                <AdditionalLinks additionalLinks={additionalLinks} />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row">
          <CommentForm id={_id} product={product} session={session} />{' '}
        </div>
      </div>
    </>
  );
};

export default ClassSession;
