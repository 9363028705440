//import { Helmet } from "react-helmet-async";
import { Helmet } from 'react-helmet';
import Message from '../../component/Message';
//import Media from '../components/SocialMedia';

const Contact = () => {
  return (
    <div className="mt-40 mb-20 flex flex-col justify-center items-center max-w-screen-xl mx-auto">
      <Helmet>
        <title> Contact us</title>
        <meta name="description" content="contact ISO organization" />
      </Helmet>
      <div className="flex flex-col items-center ">
        <h1 className="text-2xl font-custom1 text-main mt-8">
          Feel free to contact us
        </h1>

        {/* <Media /> */}
      </div>
      <div className="flex flex-col items-center mt-10">
        <p className="text-xs mt-1 mb-3 text-center font-custom1 text-main">
          We will get back to you as soon as possible
          <br />
        </p>
        <p className="mt-6 mb-3 text-main">Info@fitlinez.com</p>
        {/* <Message /> */}
      </div>
    </div>
  );
};

export default Contact;
