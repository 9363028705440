import React from 'react';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Tutorials = () => {
  const location = useLocation();
  const session = location?.state?.data;
  const [showSecondVideo, setShowSecondVideo] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  return (
    <>
      <div>
        <div className="bg-white text-main text-center font-custom1">
          <h1 className="text-3xl font-bold text-center">{session.product}</h1>

          <h2> {session.name}</h2>

          <video
            src={session}
            controls
            className=" items-center justify-center flex mx-auto inset-0 w-3/6  max-h-fit object-cover"
          />
          <span></span>
        </div>
      </div>
    </>
  );
};

export default Tutorials;
