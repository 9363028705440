import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import api from '../../api/api';

function CardInput({ email, product }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handlePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    console.log(paymentMethod);
    if (error) {
      setMessage(error.message);
      console.error(error);
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.post(`/subscription/createSubscription`, {
        email: email,
        paymentMethodId: paymentMethod.id,
        product: product,
        name: email,
      });

      console.log(response.data);
      if (response.data.error) {
        console.log(response.data.error);
        return;
      }

      // window.location.href = response.data.url;
    } catch (error) {
      console.error(error);
    }
  };
  const options = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <div className=" mx-auto h-screen mt-5" dir="ltr">
      <CardElement
        options={options}
        className="w-full border border-gray-300 px-3 py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
      />
      {/* <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handlePayment}>
        Pay
      </button> */}
      <button
        className=" w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
        disabled={isLoading || !stripe || !elements}
        id="submit"
        onClick={handlePayment}>
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
        </span>
      </button>

      {message && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert">
          <strong className="font-bold">Error</strong>
          <span className="block sm:inline">{message}</span>
          <span
            onClick={() => setMessage(null)}
            className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
    </div>
  );
}

export default CardInput;
