import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import CardInput from './CardInput';
import { Elements } from '@stripe/react-stripe-js';
import api from '../../api/api';

const stripePromise = loadStripe(
  'pk_live_51JSLBhAB6MVrXxqz3Aoqhc3IbLydGJGzAAdAOZu7gHDp902JfOIYlDuTafiCN22u4hZcr3Wx6SDJtq3yOlIf3WBp00zOauga5p'
  //'pk_test_51JSLBhAB6MVrXxqzX3ZVALadRmlc7jrW9yiykDmjKJfnPMjVQg0xtIiBqvOPcwz2r1t8VEiC7dCwQromfRjRak8W00raQ8oyLK'
);

function PayFromApp() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const locations = queryParams.get('location');
  const name = queryParams.get('name');
  // const product = queryParams.get('product');
  //const product = 'prod_NbmwiYSnJnE7TK';
  const product = 'prod_OkZ2T0p4OirdLz';
  const [isLoading, setIsLoading] = useState(false);
  const amount = queryParams.get('amount');
  const amountrial = queryParams.get('amountRial');
  console.log(email, locations, product, amount, amountrial);

  useEffect(() => {
    const initiatePayment = async () => {
      setIsLoading(true);

      try {
        if (locations === 'Iran') {
          console.log('iran');
          const response = await api.post(`/zarinpal/rial`, {
            email: email,
            product: product,
            name: email,
            amount: amountrial,
          });

          window.location.href = response.data.url;
          //console.log(response.data);
        } else {
          console.log('not iran');
          const stripe = await stripePromise;

          // Create a payment method
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: {
              // You need to pass the card element here
            },
          });

          if (paymentMethod.error) {
            console.error(paymentMethod.error);
            return;
          }

          const response = await api.post(`/subscription/createSubscription`, {
            email: email,
            paymentMethodId: paymentMethod.id,
            product: product,
            name: email,
          });

          if (response.data.error) {
            console.error(response.data.error);
            return;
          }

          window.location.href = response.data.url;
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    initiatePayment();
  }, [locations]);

  return (
    <div dir="ltr" className="mx-auto h-screen mt-5 px-3">
      {email && locations ? (
        <>
          <span
            className="text-center"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {' '}
            <h1>Payment for Fitlinez App - {product}</h1>
          </span>
          {email ? (
            <>
              {' '}
              <span
                className="text-center"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {' '}
                <h3>Email: {email}</h3>
              </span>
              <span
                className="text-center"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {' '}
                <h3>Price: {locations === 'Iran' ? amountrial : amount} EUR</h3>
              </span>{' '}
            </>
          ) : null}
          <span>
            {isLoading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
          </span>
          <Elements stripe={stripePromise}>
            <CardInput email={email} name={email} product={product} />
          </Elements>
        </>
      ) : (
        <div className="text-center">
          <h1>Payment for Fitlinez App</h1>
          <h3>Something went wrong, please try again</h3>
        </div>
      )}
    </div>
  );
}

export default PayFromApp;
