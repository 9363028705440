import { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useLocation } from 'react-router-dom';
import BodySize from '../../../img/bodysize.png';
import Bugsnag from '@bugsnag/js';
import Modal from '../../../component/Modal';
import api from '../../../api/api';
import { Alert, Button } from 'flowbite-react';

const SizeReport = () => {
  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      try {
        const parsedCredentials = JSON.parse(credentials);
        console.log(parsedCredentials.userId);
        setUserId(parsedCredentials.userId);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const sizeInputs = [
    {
      id: 1,
      name: 'دور سینه',
      value: 'chest',
      min: 30,
      max: 180,
      step: 0.5,
      unit: 'cm',
    },
    {
      id: 2,
      name: 'بازوی چپ',
      value: 'leftArm',
      min: 10,
      max: 100,
      step: 0.5,
      unit: 'cm',
    },
    {
      id: 3,
      name: 'بازوی راست',
      value: 'rightArm',
      min: 10,
      max: 100,
      step: 0.5,
      unit: 'cm',
    },
    {
      id: 4,
      name: 'دور شکم',
      value: 'abdomen',
      min: 50,
      max: 300,
      step: 0.5,
      unit: 'cm',
    },
    {
      id: 5,
      name: 'دور کمر',
      value: 'waist',
      min: 50,
      max: 300,
      step: 0.5,
      unit: 'cm',
    },
    {
      id: 6,
      name: 'دور باسن',
      value: 'hip',
      min: 50,
      max: 300,
      step: 0.5,
      unit: 'cm',
    },
    {
      id: 7,
      name: 'ران چپ',
      value: 'leftThigh',
      min: 10,
      max: 150,
      step: 0.5,
      unit: 'cm',
    },
    {
      id: 8,
      name: 'ران راست',
      value: 'rightThigh',
      min: 10,
      max: 150,
      step: 0.5,
      unit: 'cm',
    },
    {
      id: 9,
      name: 'وزن',
      value: 'weight',

      min: 10,
      max: 250,
      step: 0.5,
      unit: 'kg',
    },
  ];

  const [isError, setIsError] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState(null);
  const [userSize, setUserSize] = useState([]);
  console.log(userSize);
  const [showUpload, setShowUpload] = useState(false);

  const [status, setStatus] = useState('idle');

  const handleInputChange = (event, min, max) => {
    const { name, value } = event.target;
    // add name and value as an object into userSize array while previos data is not lost and prevent duplicate
    setUserSize((prev) => [
      ...prev.filter((item) => item.name !== name),
      //convert value to number
      { name, value: Number(value) },
    ]);
  };

  useEffect(() => {
    if (userSize.length === 9) {
      setShowUpload(true);
    }
  }, [userSize]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!imageUrls.length) return;

    setStatus('loading');

    //base64 image

    try {
      // Send request data to backend endpoint
      const response = await api.post('/report/sizereport', {
        userId: userId,
        sizes: userSize,
        // images: imageUrls,
      });
      setStatus('success');
      setShowModal(true);
      console.log(response);
      setMessage('گزارش ماهیانه شما با موفقیت ثبت شد');
    } catch (error) {
      console.error(error);
      setStatus('error');
      setShowModal(true);
      setMessage('Upload failed: ' + error.message);
      setIsError(true);
      Bugsnag.notify('error in uploading to database');
    }
  };

  return (
    <>
      {userId !== null ? (
        <div className="bg-white mt-10 mx-auto sm:w-4/5 md:w-2/3 rounded-lg shadow-lg">
          {status === 'error' && (
            <Alert color="failure">
              <span className="font-medium">خطا</span> مشکلی پیش امده. لطفا
              مجددا تلاش کنید
            </Alert>
          )}
          {status === 'success' && (
            <>
              <Alert color="success">
                <span className="font-medium">اطلاعات ذخیره شدند </span>
              </Alert>
              <Button.Group>
                <Button
                  onClick={() => (window.location.href = '/dashboard')}
                  color="blue">
                  برگشت به خانه
                </Button>
                <Button
                  onClick={() => (window.location.href = '/size-history')}
                  color="light">
                  مشاهده سابقه{' '}
                </Button>
              </Button.Group>
            </>
          )}
          {status === 'idle' && (
            <>
              <div className="justify-between rounded-t-lg bg-blue-500 flex items-center">
                <label className="text-base text-right text-white mx-4 my-2">
                  گزارش سایز بدن{' '}
                </label>
              </div>
              <div>
                <img src={BodySize} alt="body size" className="w-full" />
              </div>
              <div></div>
              {sizeInputs.map((item, index) => (
                <div
                  className="flex-row items-center justify-center w-full my-2 mx-2
            "
                  key={index}>
                  <label className="flex flex-col items-center justify-center">
                    <span className="text-base text-right text-main mx-4 my-2">
                      {item.name}{' '}
                      {userSize[index] &&
                        `${userSize[index].value} ${item.unit}`}
                    </span>
                    <input
                      required
                      className="border text-main border-main rounded-lg w-4/5 p-2"
                      type="number"
                      name={item.name}
                      onChange={(e) =>
                        handleInputChange(e, index, item.min, item.max)
                      }
                    />
                  </label>
                </div>
              ))}

              <div
                className=" text-main mt-5
              grid md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                {userSize.length !== 10 && (
                  <button
                    // type="submit"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    disabled={status === 'loading'}
                    className={`w-full border-transparent rounded-b-lg py-4  text-sm font-custom1 text-white ${
                      status === 'loading' ? 'bg-gray-500' : 'bg-main'
                    } text-center`}>
                    ثبت گزارش{' '}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <span>شما اجازه دسترسی به این صفحه را ندارید</span>
        </div>
      )}
    </>
  );
};

export default SizeReport;
