import React from 'react';

const DownloadLink = ({ fileName }) => {
  // Direct URL to the video file
  const videoUrl = 'https://backend.fitlinez.com/videos/IMG_8112.mp4';

  return (
    <a href={videoUrl} download={fileName}>
      Download Video
    </a>
  );
};

export default DownloadLink;
