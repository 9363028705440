import React, { useEffect, useState } from 'react';
import api from '../../api/api';

export default function Verif({ email }) {
  const [verificationCode, setVerificationCode] = useState('');
  const [status, setStatus] = useState('initial');
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (verificationCode.length === 4) {
      setStatus('filled');
    } else {
      setStatus('initial');
    }
  }, [verificationCode]);

  const handleVerif = async (e) => {
    e.preventDefault();
    try {
      await api
        .post(`/userauth/reset-password`, {
          email,
          verificationCode,
        })
        .then((res) => {
          console.log(res.data.status);
          if (res.data.status == 'ok') {
            setStatus('verified');
            console.log(res.data.data);
            window.location.href = '/reset-password?email=' + email;
          } else {
            setStatus('error');
          }
          setResult(res.data);
        });
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  };

  return (
    <>
      <div className=" flex flex-col justify-center items-center">
        <input
          dir="ltr"
          className="text-main border-1 border-main rounded-md p-2 w-80 "
          //value={otp}
          onChange={(e) => setVerificationCode(e.target.value)}
          numInputs={4}
          isInputNum
        />
        {status === 'filled' && (
          <button
            onClick={handleVerif}
            className="btn btn-primary  text-white bg-main my-10 w-full py-4 px-3 border-2 border-main rounded-md"
            type="submit">
            تایید
          </button>
        )}
      </div>

      {status === 'error' && (
        <div className="alert alert-error">
          <div className="text-main">خطا در ارسال</div>
        </div>
      )}
    </>
  );
}
