import Agree from '../../../../src/img/agree.svg';
import Disagree from '../../../../src/img/disagree.svg';

export const ForWho = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 rounded rounded-lg">
      <div className="flex flex-col bg-white border rounded shadow-sm md:justify-center lg:flex-row rounded-lg">
        <div className="flex flex-col text-main justify-between p-5 border-b sm:p-10 lg:border-b-0 lg:border-r lg:w-1/2 ">
          <div>
            <div
              className="flex items-center justify-normal mb-6 text-xl leading-none text-main
            ">
              <img src={Agree} alt="agree" className="w-5 h-5" />
              <h5 className="max-w-md mx-2 text-xl  leading-none font-custom1">
                آکادمی shape Up مناسب{' '}
                <u>
                  <b>شما است</b>
                </u>{' '}
                اگر:
              </h5>
            </div>
            <ul className=" text-main md:text-sm font-custom1">
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-green-500 mb-2 ml-2 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="font-custom1">
                  {' '}
                  می خواهید بدون <b>ترس از غذا خوردن</b>، تناسب اندام داشته
                  باشید.
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-green-500 mb-2 ml-2 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="">
                  {' '}
                  رژیم های مختلف را امتحان کردید و عذاب زیادی کشیده اید{' '}
                  <b>ولی نتیجه ای نگرفتید.</b>
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-green-500 mb-2 ml-2 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="">
                  از همه جا نا امید شده اید و تصمیم به <b>عمل جراحی لاغری</b>{' '}
                  دارید.
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-green-500 mb-2 ml-2 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="">
                  <b>پرخوری عصبی</b> و یا <b>بی اشتهایی عصبی</b> شما را آزار می
                  دهد.
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-green-500 mb-2 ml-2 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="">
                  قصد دارید بصورت <b>علمی</b> ترکیب بدن خود را تغییر دهید. 
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-green-500 mb-2 ml-2 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="">
                  {' '}
                  به دنبال<b> بالا رفتن اطلاعات</b> خود در زمینه ی تغذیه هستید.
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-green-500 mb-2 ml-2 dark:text-green-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="">
                  {' '}
                  به دنبال <b>سبک زندگی سالم</b> و با تحرک هستید و دوست دارید
                  ورزش و تحرک <b>روتین</b> زندگی شما شود.
                </span>
              </li>
            </ul>
          </div>
          {/* <div className="flex items-center">
            <button
              type="submit"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
              Get started
            </button>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
              Learn more
            </a>
          </div> */}
        </div>
        <div className="flex flex-col justify-between p-5 sm:p-10 lg:w-1/2 ">
          <div className="mb-2 text-xl font-bold tracking-wide uppercase text-main mb-5">
            <div
              className="flex items-center justify-normal mb-6 text-xl leading-none  text-main
            ">
              <img src={Disagree} alt="disagree" className="w-5 h-5" />

              <h5
                className="max-w-md mx-2 text-xl  leading-none  font-custom1
            text-main">
                آکادمی shape Up مناسب{' '}
                <u>
                  <b>شما نیست</b>
                </u>{' '}
                اگر:
              </h5>
            </div>
            <ul className="text-sm text-main md:text-sm font-custom1">
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-red-500 mb-2 ml-2 dark:text-red-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M3.293 3.293a1 1 0 0 1 1.414 0L10 8.586l5.293-5.293a1 1 0 0 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 0 1 0-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span className="font-custom1">
                  به دنبال کاهش وزن<b> خیلی سریع</b> هستید.
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-red-500 mb-2 ml-2 dark:text-red-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M3.293 3.293a1 1 0 0 1 1.414 0L10 8.586l5.293-5.293a1 1 0 0 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 0 1 0-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="font-custom1">
                  <b>نمی توانید</b> در کاری تکرار، تمرین، نظم و استمرار داشته
                  باشید.{' '}
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-red-500 mb-2 ml-2 dark:text-red-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M3.293 3.293a1 1 0 0 1 1.414 0L10 8.586l5.293-5.293a1 1 0 0 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 0 1 0-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="font-custom1">
                  در طی کردن مسیر و رسیدن به هدف، <b>صبور نیستید</b> .
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-red-500 mb-2 ml-2 dark:text-red-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M3.293 3.293a1 1 0 0 1 1.414 0L10 8.586l5.293-5.293a1 1 0 0 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 0 1 0-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="font-custom1">
                  به دنبال رخ دادن <b>معجزه</b> هستید.
                </span>
              </li>
              <li className="mb-4 text-main flex items-center">
                <svg
                  className="w-3.5 h-3.5 text-red-500 mb-2 ml-2 dark:text-red-400 flex-shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M3.293 3.293a1 1 0 0 1 1.414 0L10 8.586l5.293-5.293a1 1 0 0 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 0 1 0-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="font-custom1">
                  <b>به کوچ و راهنمای</b> خود اعتماد نمی کنید.
                </span>
              </li>
            </ul>
          </div>
          {/* <div className="flex items-center">
            <button
              type="submit"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
              Get started
            </button>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
              Learn more
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};
