import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getProductData } from '../../api/getData';

function FeatureCompare() {
  const [priceToggled, setPriceToggled] = React.useState(0);
  console.log(priceToggled);
  const getProducts = async () => {
    const data = await getProductData();
    return data;
  };
  const { data: products, status } = useQuery('products', getProducts);
  console.log(products);
  if (status === 'loading') {
    return <div>در حال بارگزاری</div>;
  }
  if (status === 'error') {
    return <div> خطا در بارگزاری اطلاعات</div>;
  }

  const togglePrice = (index) => {
    setPriceToggled(index);
  };

  return (
    <main className="relative min-h-screen flex flex-col justify-center bg-white overflow-hidden">
      <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
        <label
          dir="rtl"
          className="inline-flex items-center me-5 cursor-pointer mb-4">
          <span className="ms-3 m-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            کاربران خارج از ایران
          </span>
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            onChange={(e) => {
              if (e.target.checked) {
                togglePrice(1);
              } else {
                togglePrice(0);
              }
            }}
          />
          <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
          <span className="ms-3 m-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            کاربران داخل ایران
          </span>
        </label>
        <div
          id="products"
          className=" max-w-sm mx-auto md:max-w-none grid md:grid-cols-4 md:-mx-6 text-sm">
          {products.map((callout, i) => (
            <Link
              to="/userlogin"
              state={{
                data: {
                  name: callout.name.displayName,
                  price: callout.price.price,
                  priceSign: callout.price.priceSign,
                  discountedPrice: callout.price.discountedPrice,
                  priceRial: callout.priceRial.price,
                },
              }}>
              <section className="md:contents [&>div:first-child]:pt-0 [&>div:first-child]:rounded-t-xl [&>div:last-child]:pb-5 [&>div:last-child]:rounded-b-2xl">
                <div className="relative bg-white dark:bg-slate-900 px-4 lg:px-6 flex flex-col justify-end">
                  <div
                    className={`${
                      callout.status === 'noSeat' ? 'bg-quaternary' : 'bg-main'
                    } rounded text-white py-1 px-2 mb-2 flex justify-between`}>
                    <div className=" font-semibold text-center flex flex-row  text-slate-900 dark:text-slate-200 ">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {callout.name.displayName}
                    </div>

                    {priceToggled === 0 && (
                      <span
                        className=" flex flex-row justify-between text-slate-900 dark:text-slate-200
                  ">
                        <span
                          className={`text-slate-900 dark:text-slate-200  ${
                            callout.isDiscounted === true
                              ? 'line-through text-sx'
                              : 'text-sx font-bold'
                          }`}>
                          {callout.price.price + callout.price.priceSign}
                        </span>
                        <span className="text-md font-bold text-slate-900 dark:text-slate-200 ">
                          {callout.isDiscounted &&
                            callout.price.discountedPrice +
                              callout.price.priceSign}
                        </span>
                      </span>
                    )}
                    {priceToggled === 1 && (
                      <span
                        className=" flex flex-row justify-between text-slate-900 dark:text-slate-200
                ">
                        <span
                          dir="rtl"
                          className={`text-slate-900 dark:text-slate-200 ${
                            callout.isDiscounted === true
                              ? 'line-through'
                              : 'text-md font-bold '
                          }`}>
                          {callout.priceRial.displayPrice + ' تومان'}
                        </span>
                        <span
                          dir="rtl"
                          className="text-md font-bold text-right text-slate-900 dark:text-slate-200">
                          {callout.isDiscounted &&
                            callout.priceRial.dispayDiscound + ' تومان'}
                        </span>
                      </span>
                    )}
                  </div>
                  {callout.status === 'noSeat' && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '10%',
                        right: '50%',
                        textAlign: 'center',
                        transform: 'translate(50%,-50%)',
                        backgroundColor: 'darkgray',
                        opacity: 0.6,
                        color: 'black',
                        borderRadius: 5,
                        padding: '0.5rem',
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        width: 200,
                        zIndex: 100,
                      }}>
                      ظرفیت تکمیل شد
                    </div>
                  )}

                  <div className="  border-slate-200 dark:border-slate-700"></div>
                  {callout.features.map((feature, index) => (
                    <div className="bg-white dark:bg-slate-900 px-4 lg:px-6 flex flex-col justify-end">
                      <div className="flex items-center border-b border-slate-200 dark:border-slate-700 md:py-2 text-slate-600 ">
                        <span
                          dir="rtl"
                          className="flex justify-between w-full text-xs text-slate-900 dark:text-slate-200">
                          <span>{feature.value}</span>
                          {feature.status ? (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="green"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.75 12L10.58 14.83L16.25 9.17004"
                                stroke="green"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="red"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.16998 14.83L14.83 9.17004"
                                stroke="red"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.83 14.83L9.16998 9.17004"
                                stroke="red"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </Link>
          ))}
        </div>
      </div>
    </main>
  );
}

export default FeatureCompare;
