import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../api/api';

const WorkoutComponent = () => {
  const [workouts, setWorkouts] = useState([]);
  const [goal, setGoal] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.post('/generateWorkoutPlan', {
        goal,
      });
      setWorkouts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4" dir="ltr">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Enter your goal"
          className="input input-bordered w-full"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
        />
        <button
          className={`btn btn-primary mt-2 ${loading ? 'loading' : ''}`}
          onClick={fetchData}
          disabled={loading}>
          Generate Workout
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {workouts.map((workout) => (
          <div key={workout._id} className="card bordered">
            <figure>
              <img
                src={workout.gifUrl}
                alt={workout.name}
                className="w-48 h-48 object-cover"
              />
            </figure>
            <div className="card-body">
              <h2 className="card-title">{workout.name}</h2>
              <p>Body Part: {workout.bodyPart}</p>
              <p>Location: {workout.loc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkoutComponent;
