import axios from 'axios';
import { useEffect, useState } from 'react';
import Modal from '../../../component/Modal';
import { Navigate, useLocation } from 'react-router-dom';
import SubmitButton from '../../../component/submitButton';
import api from '../../../api/api';

export default function NutritionForm(props) {
  const location = useLocation();
  const { userNutritionData: userData } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [calories, setCalories] = useState(userData?.calories);
  const [calorie, setCalorie] = useState(false);
  const [macro, setMacro] = useState(false);
  const [status, setStatus] = useState('idle');
  const [lastdiet, setLastdiet] = useState(userData?.lastdiet);
  const [diet, setDiet] = useState(userData?.diet);
  const [dietName, setDietName] = useState(userData?.dietName);
  const [isDiet, setIsDiet] = useState(false);
  const [done, setDone] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState();
  const [userNutritionData, setUserNutritionData] = useState({
    calories,
    calorie,
    macro,
    lastdiet,
    dietName,
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setUserNutritionData({ ...userNutritionData, [name]: value });
  };
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);

      setUserId(parsedCredentials.id);
    }
  }, []);

  const handleSubmit = async (e) => {
    setStatus('loading');
    e.preventDefault();
    setIsLoading(true);

    setTimeout(async () => {
      try {
        const res = await api.put(
          `api/updateNutrition/${userId}`,
          userNutritionData
        );
        setStatus('success');
        setMessage(res.status);
        alert('اطلاعات با موفقیت ثبت شد');
        window.location.href = '/dashboard';
      } catch (error) {
        setStatus('error');
      }
    }, 2000); // 5 seconds delay
  };

  useEffect(() => {
    if (
      calorie === undefined &&
      calories === undefined &&
      macro === undefined &&
      isDiet === undefined
    ) {
      setDone(false);
    } else {
      setDone(true);
    }
  }, [calorie, calories, macro, isDiet]);

  return (
    <>
      <div className="font-custom1 flex flex-row mt-20">
        <div className="basis-1/4"></div>
        <div className="basis-2/4">
          <div className="flex flex-wrap -mx-3 mb-6 ">
            <div className="w-full md:w-full px-3 mb-6 md:mb-0">
              <label
                htmlFor="title"
                className=" text-sm font-custom1 leading-6 text-main text-right">
                میزان کالری مصرفی
              </label>
              <select
                id="calories"
                name="calories"
                defaultValue={userData?.calories}
                onChange={handlechange}
                className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option>انتخاب کنید</option>
                <option value="نمیدانم">نمیدانم</option>
                <option value="زیر ۱۰۰۰ کالری در روز">
                  زیر ۱۰۰۰ کالری در روز
                </option>
                <option value="بین ۱۰۰۰ تا ۱۵۰۰ کالری در روز">
                  بین ۱۰۰۰ تا ۱۵۰۰ کالری در روز
                </option>
                <option value="بین ۱۵۰۰ تا ۲۰۰۰ کالری در روز">
                  بین ۱۵۰۰ تا ۲۰۰۰ کالری در روز
                </option>
                <option value="بالای ۲۰۰۰ کالری در روز">
                  بالای ۲۰۰۰ کالری در روز
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6 ">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="flex items-center gap-x-2">
                <legend className="text-sm font-semibold leading-4 text-main text-right">
                  میدونی کالری چیه؟
                </legend>
                <input
                  onChange={handlechange}
                  id="calorie"
                  name="calorie"
                  type="checkbox"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="flex items-center gap-x-2">
                <legend className="text-sm font-semibold leading-4 text-main text-right">
                  میدونی ماکرو چیه؟
                </legend>
                <input
                  onChange={handlechange}
                  id="macro"
                  name="macro"
                  type="checkbox"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6 ">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                htmlFor="country"
                className=" text-sm font-custom1 leading-6 text-main text-right">
                آخرین باری که رژیم گرفتی؟{' '}
              </label>
              <select
                id="lastdiet"
                name="lastdiet"
                onChange={handlechange}
                defaultValue={userData?.lastdiet} // Set the defaultValue based on userData.lastDiet
                className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option>انتخاب کنید</option>
                <option value="یک ماه پیش">یک ماه پیش</option>
                <option value="بین یک تا سه ماه پیش">
                  بین یک تا سه ماه پیش
                </option>
                <option value="بین سه تا شش ماه پیش">
                  بین سه تا شش ماه پیش
                </option>
                <option value="بیشتر از شش ماه پیش">بیشتر از شش ماه پیش</option>
                <option value="همیشه در حال رژیم گرفتنم">
                  همیشه در حال رژیم گرفتنم!
                </option>
              </select>
            </div>

            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block text-sm font-medium leading-6 text-main text-right ">
                رژیم فعلی شما{' '}
              </label>

              <input
                defaultValue={userData?.dietName}
                type="text"
                name="dietName"
                onChange={handlechange}
                id="dietName"
                placeholder='مثلا رژیم مدیترانه ای یا کانادایی"'
                className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <SubmitButton handleSubmit={handleSubmit} status={status} />
        </div>
        <div className="basis-1/4"></div>

        {isSubmitted && (
          <Modal
            title="پیام"
            message={message === 200 ? 'اطلاعات با موفقیت ثبت شد' : 'خطا'}
            buttonTitle="باشه"
            onClick={() => {
              window.location.href = '/dashboard';
            }}
          />
        )}
      </div>
    </>
  );
}
