import React from 'react';

const TermsOfService = () => {
  return (
    <div
      dir="ltr"
      className="bg-white p-6 rounded-lg shadow-md max-w-xl mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-4">Fitlinez Workout Disclaimer</h1>
      <p>
        By using the Fitlinez mobile application, you acknowledge and agree to
        the following terms:
      </p>
      <ol className="list-decimal pl-5 mt-4">
        <li className="mb-2">
          Personal Responsibility: Fitlinez offers workout suggestions tailored
          to your goals and conditions. However, the choice of gym equipment,
          including but not limited to weight plates, resistance bands,
          dumbbells, and machines, remains your responsibility. Always select
          equipment and resistance levels appropriate for your current fitness
          level and physical condition.
        </li>
        <li className="mb-2">
          Safety First: Always ensure you are using gym equipment correctly and
          safely. Follow manufacturer guidelines, and if unfamiliar with a
          particular exercise or equipment, seek instructional resources or
          professional guidance.
        </li>
        <li className="mb-2">
          No Liability: Fitlinez shall not be held liable for any injuries,
          damages, or adverse effects resulting from your use of any gym
          equipment or from following workout suggestions provided by the
          application. You assume all risks associated with the exercises and
          workouts.
        </li>
        <li className="mb-2">
          Professional Advice: Before starting any new exercise regimen, it's
          recommended to consult with a fitness professional, personal trainer,
          or healthcare provider. They can offer guidance tailored to your
          individual needs and ensure your safety.
        </li>
        <li className="mb-2">
          Physical Condition: Be aware of any physical limitations or medical
          conditions you may have. If you experience pain, discomfort, or any
          other symptoms while exercising, stop immediately and seek medical
          attention if necessary.
        </li>
        <li className="mb-2">
          Nutrition and Hydration: Avoid working out on an empty stomach. Ensure
          you're properly hydrated and have consumed a balanced meal or snack
          before exercising to maintain energy and prevent dizziness or fatigue.
        </li>
        <li className="mb-2">
          Updates: Fitlinez reserves the right to update or modify this
          disclaimer at any time. Regularly review this disclaimer to stay
          informed about any changes.
        </li>
      </ol>
      <p className="mt-4">
        By continuing to use the Fitlinez application, you signify your
        understanding and acceptance of these terms.
      </p>
    </div>
  );
};

export default TermsOfService;
