import { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Transition } from '@headlessui/react';
import api from '../../../api/api';

const BeforeAfterHistory = () => {
  const [reports, setReports] = useState([]);

  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('idle'); // 'idle' | 'loading' | 'success' | 'error'
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [itemId, setItemId] = useState('');

  // const filteredReports = reports.filter((report) => {
  //   return report.data.length > 1;
  // });

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);
      setUserId(parsedCredentials.userId);
    }
  }, []);

  useEffect(() => {
    // Define the async function inside the effect
    const getReports = async () => {
      if (!userId) return;
      setStatus('loading'); // Start loading
      setLoading(true); // Start loading
      setError(null); // Reset errors

      try {
        const response = await api.get(`/report/imagereport/${userId}`);
        setReports(response.data);
        setStatus('success'); // Set success
      } catch (error) {
        setStatus('error'); // Set error
        console.error(error);
        setError(error); // Set error state
      } finally {
        setStatus('idle'); // Set idle
        setLoading(false); // End loading
      }
    };

    // Wrap the async call in a promise to handle it explicitly
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(getReports());
      }, 1000);
    });

    // You can also handle the resolved value or errors of the promise if needed
    promise.then(
      () => console.log('Data fetch successful'),
      (error) => console.error('Data fetch failed:', error)
    );

    // You might want to handle cancellation here if the component unmounts
    return () => {
      // Cancel any ongoing requests or timeouts
    };
  }, [userId]);

  if (loading) {
    return (
      <span className="loading loading-infinity loading-lg items-center justify-center"></span>
    );
  }

  if (error) {
    return <div dir="ltr">Error: {error.message}</div>;
  }

  const deleteImage = async (id) => {
    try {
      const res = await api.put(`/report/imagereport/${id}`, {
        archive: true,
      });
      console.log(res.data);
      setOpenModal(false);
      //reload the page
      window.location.reload();
    } catch (err) {
      console.log(err);
      setOpenModal(false);
    }
  };

  const DeleteModal = ({ onClose, onConfirm, id }) => {
    console.log(id);
    return (
      <div className={`modal ${openModal ? 'modal-open' : ''}`}>
        <div className="modal-box">
          <h3 className="font-bold text-lg">تایید حذف</h3>
          <p className="py-4">آیا از حذف این گزارش قبل و بعد اطمینان دارید؟</p>
          <div className="modal-action">
            <button onClick={onClose} className="btn">
              انصراف
            </button>
            <button
              onClick={() => onConfirm(itemId)}
              className="btn btn-warning">
              حذف
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleDelete = (id) => {
    setItemId(id);
    setOpenModal((prevOpenModal) => !prevOpenModal);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full text-main">
      {reports.map((report) => {
        return (
          report.archive !== true && (
            <div className="card w-96 bg-base-100 shadow-xl mb-4">
              <div className="card-body" key={report._id}>
                <div className="card-actions justify-end">
                  <button
                    onClick={() => handleDelete(report._id)}
                    className="btn text-main btn-warning">
                    حذف
                  </button>
                </div>
                <h2 className="card-title">
                  تاریخ ارسال:
                  {new Intl.DateTimeFormat('fa-IR', {
                    dateStyle: 'short',
                  }).format(new Date(report?.date))}
                  {/* {new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'short',
                  }).format(new Date(report?.date))} */}
                </h2>

                {report?.data.map((item, index) => {
                  console.log('item', item);
                  return (
                    <figure key={index}>
                      <div className="w-64 carousel rounded-box">
                        <div className="carousel-item w-full">
                          <img
                            src={item}
                            className="w-ful object-scale-down"
                            alt="Tailwind CSS Carousel component"
                          />
                        </div>
                      </div>
                    </figure>
                  );
                })}
              </div>
            </div>
          )
        );
      })}
      {openModal ? (
        <DeleteModal
          onClose={() => setOpenModal(false)}
          onConfirm={deleteImage}
          id={reports._id}
        />
      ) : null}
    </div>
  );
};

export default BeforeAfterHistory;
