import moment from 'moment-jalaali';
import React from 'react';

function CoachReport({
  coachReport,
  proteinAmount,
  fatAmount,
  carbsAmount,
  maintainCalorie,
}) {
  console.log('maintainCalorie', coachReport.maintainCalorie);
  return (
    <div class="  w-full">
      <div class="border border-gray-200 rounded bg-white p-4 flex flex-col justify-between leading-normal">
        <div class="mb-8">
          <p class="text-sm text-gray-600 flex items-center"></p>
          <div class="text-gray-900 font-bold text-xl mb-2">
            اهداف هفته آینده
          </div>
          <div class="text-sm">
            <span
              class={`${
                coachReport?.rate === 1
                  ? 'bg-yellow-200	'
                  : coachReport?.rate === 2
                  ? 'bg-sky-500	'
                  : 'bg-orange-600	'
              } text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}>
              {coachReport?.rate === 1
                ? 'عالی'
                : coachReport?.rate === 2
                ? 'خوب'
                : 'متوسط'}
            </span>
          </div>
          <div className="flex flex-col lg:flex-row md:max-lg:flex md:justify-between mt-5">
            <span
              className="text-sm mb-5 p-2 rounded-lg mt-5  text-right justify-right p-6 bg-white border border-gray-200  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 overflow-auto lg:w-1/2
         ">
              {coachReport.next_Goal}
            </span>
            <div class="max-w-sm lg:w-1/2 p-6 bg-white  dark:bg-gray-800 dark:border-gray-700">
              <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                <ul className=" text-right">
                  <li className="text-gray-700">
                    <li className="text-gray-700">
                      <span>کالری تثبیت:</span>
                      <span>{maintainCalorie}</span> کیلوکالری
                    </li>
                    <hr />
                    <li className="text-gray-700">
                      <span>کالری:</span>
                      <span>{coachReport?.calories}</span> کیلوکالری
                    </li>
                    <hr />
                    <span>پروتیین:</span>
                    <span>{coachReport?.protein}</span> %
                    <span>
                      {proteinAmount !== 0 && (
                        <span> ({proteinAmount} گرم) </span>
                      )}
                    </span>
                  </li>
                  <hr />
                  <li className="text-gray-700">
                    <span>کربوهیدرات:</span>
                    <span>{coachReport?.carbs}</span> %
                    <span>
                      {carbsAmount !== 0 && <span> ({carbsAmount} گرم) </span>}
                    </span>
                  </li>
                  <hr />
                  <li className="text-gray-700">
                    <span>چربی:</span>
                    <span>{coachReport?.fat}</span> %
                    <span>
                      {fatAmount !== 0 && <span> ({fatAmount} گرم) </span>}
                    </span>
                  </li>
                  <hr />
                  <li className="text-gray-700">
                    <span>فیبر:</span>
                    <span>{coachReport?.fiber}</span> گرم
                  </li>
                  <hr />
                  <li className="text-gray-700">
                    <span>میانگین قدمهای روزانه:</span>
                    <span>{coachReport?.steps}</span> قدم در روز{' '}
                  </li>
                  <hr />
                  <li className="text-gray-700">
                    <span> ورزش قدرتی:</span>
                    <span>{coachReport?.exercise}</span> روز در هفته
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center"></div>
      </div>
    </div>
  );

  {
    /* <div
      className="w-1/2 cursor-grab flex flex-row bg-white  hover:bg-gray-100 dark:bg-gray-800
    ">
      <div
        className="card-body
    ">
        <div class=" grid-flow-row">
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
            <div
              className=" flex flex-row justify-between
      ">
              <span
                class={`${
                  coachReport?.rate === 1
                    ? 'bg-yellow-200	'
                    : coachReport?.rate === 2
                    ? 'bg-sky-500	'
                    : 'bg-orange-600	'
                } text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300`}>
                {coachReport?.rate === 1
                  ? 'عالی'
                  : coachReport?.rate === 2
                  ? 'خوب'
                  : 'متوسط'}
              </span>

              <span className="card-title mb-5">

                <span
                  className="text-xs text-gray-500 dark:text-gray-300 text-right flex flex-row
        ">
                  تاریخ :{' '}
                  {moment(coachReport.createdAt).format('jYYYY/jMM/jDD')}
                </span>
              </span>
            </div>{' '}
            <span
              className="text-sm mt-5 p-2 rounded-lg mt-2  text-right justify-right
         ">
              {coachReport.next_Goal}
            </span>
          </p>
        </div>
        <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
            <ul className=" text-right">
              <li className="text-gray-700">
                <li className="text-gray-700">
                  <span>کالری:</span>
                  <span>{coachReport?.calories}</span> کیلوکالری
                </li>
                <hr />
                <span>پروتیین:</span>
                <span>{coachReport?.protein}</span> %
                <span>
                  {proteinAmount !== 0 && <span> ({proteinAmount} گرم) </span>}
                </span>
              </li>
              <hr />
              <li className="text-gray-700">
                <span>کربوهیدرات:</span>
                <span>{coachReport?.carbs}</span> %
                <span>
                  {carbsAmount !== 0 && <span> ({carbsAmount} گرم) </span>}
                </span>
              </li>
              <hr />
              <li className="text-gray-700">
                <span>چربی:</span>
                <span>{coachReport?.fat}</span> %
                <span>
                  {fatAmount !== 0 && <span> ({fatAmount} گرم) </span>}
                </span>
              </li>
              <hr />
              <li className="text-gray-700">
                <span>فیبر:</span>
                <span>{coachReport?.fiber}</span> گرم
              </li>
              <hr />
              <li className="text-gray-700">
                <span>میانگین قدمهای روزانه:</span>
                <span>{coachReport?.steps}</span> قدم در روز{' '}
              </li>
              <hr />
              <li className="text-gray-700">
                <span> ورزش قدرتی:</span>
                <span>{coachReport?.exercise}</span> روز در هفته
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div> */
  }
}

export default CoachReport;
