import React, { useState, useEffect } from 'react';
import Stars from './stars';

function Testimonial() {
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      clientName: 'مهسا',
      testimonialContent:
        'حتی اگه فکر میکنید خدای تغذیه هستید، باز هم تو این دوره شرکت کنید چون قراره این بار به طور عملی یک سری کارها رو انجام بدید و اونجاست که متوجه میشید چقدر نکته مهم بود که نمیدونستید',
      image: 'https://i.pravatar.cc/300',
      star: 5,
    },
    {
      clientName: 'سمانه ',
      testimonialContent:
        'فقط همینو بگم که بعد سالها کم خوری و دوباره برگشتن وزنم برای اولین باره که حالم انقد خوبه که ایمان پیدا کردم مثل خودت هم به وزن ایده الم میرسم هم میتونم ثابتش کنم. چیزی که سالها نتوستم یه حسی بهم میگه این بار دیگه میتونم.دمت گرم',
      image: 'https://i.pravatar.cc/100',
      star: 5,
    },
    {
      clientName: 'سمیه ',
      testimonialContent:
        'بهترین و قشنگترین دوره و کلاسی که تو زندگیم شرکت کردم، بخوریم و چربی کم کنیم.',
      image: 'https://i.pravatar.cc/200',
      star: 5,
    },
    {
      clientName: ' پریسا',
      testimonialContent:
        'دوره آشتی با خودمونه آشتی با غذا اگاهی در مورد خودمون بدنمون، دوره نجاته به معنای واقعی',
      image: 'https://i.pravatar.cc/200',
      star: 5,
    },
    {
      clientName: ' الناز',
      testimonialContent:
        'دوره باعث تغییر مایندست من شده. طوری که دیگه به انتخاب خودم غذای سالم میخورم و ورزش دارم',
      image: 'https://i.pravatar.cc/200',
      star: 5,
    },
    {
      clientName: 'هما ',
      testimonialContent:
        'دوره عالی هستش این دوره. یاد میده در عین سالم خوردن و انتخاب های درست، از غذا خوردن لذت ببریم و مهمترش استمرار',
      image: 'https://i.pravatar.cc/200',
      star: 5,
    },
    {
      clientName: ' هانیه',
      testimonialContent:
        'دوره ای که محدودیت های غذا خوردنت از بین میره و یاد میگیری هم سالم باشی هم فیت',
      image: 'https://i.pravatar.cc/200',
      star: 5,
    },
    {
      clientName: 'ماندانا ',
      testimonialContent:
        'یک دوره آشتی با بدن و آموختن ذهن اگاهانه غذا خوردن و با اصول بسیار علمی و تبدیل به سبک زندگی شدن',
      image: 'https://i.pravatar.cc/200',
      star: 5,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (activeIndex + 1) % testimonials.length;
      setActiveIndex(nextIndex);
    }, 5000); // Change testimonial every 5 seconds (5000 milliseconds)

    return () => clearInterval(interval);
  }, [activeIndex, testimonials.length]);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  const handlePrevious = () => {
    const prevIndex =
      activeIndex === 0 ? testimonials.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
  };

  const handleNext = () => {
    const nextIndex = (activeIndex + 1) % testimonials.length;
    setActiveIndex(nextIndex);
  };

  return (
    <div className="relative rounded-lg text-main h-80 mb-10">
      <div className="container my-1 mx-auto md:px-2">
        <section className=" text-center lg:text-left">
          <div className="py-2 md:px-6 md:px-12">
            <div className="container mx-auto xl:px-32">
              <div className="flex grid items-center ">
                <div className="mb-2 md:mt-2 lg:mt-0 lg:mb-0">
                  <div className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                    <h2 className="mb-2 text-3xl font-bold text-main text-right dark:text-primary-400">
                      <p> {testimonials[activeIndex].clientName}</p>
                    </h2>
                    <p className="mb-4 font-bold text-right">
                      شرکت کننده shape up
                    </p>

                    <p className="mb-6 text-neutral-500 dark:text-neutral-300 text-right font-custom1">
                      {testimonials[activeIndex].testimonialContent}
                    </p>
                    <Stars star={testimonials[activeIndex].star} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Testimonial;
