import React from 'react';
import { Link } from 'react-router-dom';

const sessions = [
  {
    id: 1,
    name: 'Shape up with Azar',
    status: 'deactive',
  },
  {
    id: 2,
    name: 'Shape up pro',
    status: 'deactive',
  },
  {
    id: 3,
    name: 'Shape up 2',
    telegram: 'https://t.me/+DHG3M8CsQOFlMjg0',
    zoom: 'https://us06web.zoom.us/j/81506905687?pwd=VEdxSlJIVEZnaVVmanhHeE1kNkdEZz09',
    status: 'deactive',
    active: false,
  },
  {
    id: 4,
    name: 'Shape up 3',
    telegram: 'https://t.me/+Xnq_hivnP0BmNjY0',
    zoom: '',
    status: 'deactive',
    active: false,
  },
  {
    id: 5,
    name: 'Shape up pro 2',
    telegram: 'https://t.me/+DHG3M8CsQOFlMjg0',
    zoom: 'https://meet.google.com/azi-rmer-sis',
    status: 'deactive',
    active: false,
  },
  {
    id: 6,
    name: 'Shape up Academy',

    status: 'active',
    offline: true,
    active: true,
  },
  {
    id: 7,
    name: 'Shape up pro 3',
    telegram: 'https://t.me/+DHG3M8CsQOFlMjg0',
    zoom: 'https://meet.google.com/fgg-uzfs-dos',
    status: 'active',
    active: true,
  },
  {
    id: 8,
    // desc: 'شروع دوره از ۲۲ فروردین ۱۴۰۳ هر چهارشنبه ساعت ۸ شب بوقت تهران',
    name: 'Shape up 4',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    zoom: 'https://meet.google.com/ptn-knjz-ugz',
    status: 'deactive',
    active: false,
  },
  {
    id: 8,
    // desc: 'شروع دوره از ۲۲ فروردین ۱۴۰۳ هر چهارشنبه ساعت ۸ شب بوقت تهران',
    name: 'Shape up pro 4',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    zoom: 'https://meet.google.com/ptn-knjz-ugz',
    status: 'active',
    active: true,
  },
  {
    id: 8,
    // desc: ' شروع دوره  از ۲۶ خرداد  ۱۴۰۳  ساعت ۱۹ بوقت تهران ',
    name: 'Shape up 5',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    zoom: 'https://meet.google.com/frr-vegu-qpc',
    status: 'deactive',
    active: false,
  },
  {
    id: 8,
    desc: ' شروع دوره  از ۲ مهرماه  ۱۴۰۳  ساعت ۲۰ بوقت تهران ',
    name: 'Shape up 6',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    // zoom: 'https://meet.google.com/frr-vegu-qpc',
    status: 'active',
    active: true,
  },
  {
    id: 8,
    desc: ' شروع دوره  از ۲۷ شهریور  ۱۴۰۳  ساعت ۲۰ بوقت تهران ',
    name: 'Shape up pro 5',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    zoom: 'https://meet.google.com/frr-vegu-qpc',
    status: 'active',
    active: true,
  },
];

//check if userProduct match with sessions

const ProductData = (props) => {
  const { userProducts } = props;
  const matchedSessions = sessions.filter((session) =>
    userProducts.some((product) => product.name === session.name)
  );

  return (
    <>
      {userProducts ? (
        <div className="my-5 grid md:grid-cols-2 gap-4">
          {matchedSessions?.map((item) => (
            <div
              className="cursor-grab flex flex-col items-center justify-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
                ">
              <div className="card-body ">
                <h2 className="card-title"> {item.name}</h2>
                {item.status === 'notstarted' && (
                  <div className="badge badge-primary mx-auto">
                    دوره شما هنوز شروع نشده است
                  </div>
                )}
                {item.status === 'active' && (
                  <div className="badge badge-success mx-auto text-white">
                    این دوره برای شما فعال است{' '}
                  </div>
                )}
                {item.desc && (
                  <div className="badge badge-success mx-auto text-white ">
                    {item.desc}
                  </div>
                )}
                {item.status === 'deactive' && (
                  <div className="badge badge-ghost mx-auto">
                    این دوره به پایان رسیده است
                  </div>
                )}

                {/* <h5 className="text-xs tracking-tight text-main dark:text-white">
                    تاریخ انقضا:{' '}
                    {new Intl.DateTimeFormat('fa-IR', {
                      dateStyle: 'short',
                    }).format(new Date(item?.expirationDate))}
                  </h5> */}
                <div
                  className="card-actions justify-end

                ">
                  <div
                    className={`grid ${
                      item.offline ? 'md:grid-cols-1' : 'md:grid-cols-2'
                    } gap-3 items-center px-3 py-2 text-sm font-medium text-center text-main rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}>
                    {!item.offline && (
                      <>
                        {item.telegram && (
                          <Link
                            className={
                              item.status === 'notstarted'
                                ? 'bg-gray-500  hover:bg-gray-700 text-white font-bold py-2 px-4 border border-gray-700 rounded'
                                : 'bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
                            }
                            to={
                              item.status === 'active'
                                ? item.status === 'deactive'
                                  ? null
                                  : item?.telegram
                                : null
                            }>
                            دسترسی به گروه تلگرام
                          </Link>
                        )}
                        <Link
                          disabled={item.status}
                          className={
                            item.status === 'notstarted' ||
                            item.active === false
                              ? 'bg-gray-500 cursor-text text-white font-bold py-2 px-4 border border-gray-700 rounded'
                              : 'bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
                          }
                          to={item.status === 'active' ? item?.zoom : null}>
                          دسترسی به جلسات آنلاین
                        </Link>
                        <Link
                          disabled={item.status}
                          className={
                            item.status === 'notstarted' ||
                            item.active === false
                              ? 'bg-gray-500  cursor-text text-white font-bold py-2 px-4 border border-gray-700 rounded'
                              : 'bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
                          }
                          to={
                            item.status === 'active'
                              ? '/dashboard/report'
                              : null
                          }>
                          مشاهده و ارسال گزارش
                        </Link>
                      </>
                    )}
                    <Link
                      className={
                        item?.offline
                          ? 'bg-blue-500 w-full  hover:bg-gray-700 text-white font-bold py-2 px-4 border border-gray-700 rounded'
                          : 'bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
                      }
                      to={`/dashboard/workshop`}
                      state={{ data: item.name }}>
                      دسترسی به جلسات آفلاین
                    </Link>
                    {/* <Link
                      className="bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                      to="/dashboard/tools">
                      دانلود اپلیکیشنها
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>no data</>
      )}
    </>
  );
};

export default ProductData;
