import React from 'react';
import Azar from '../../../img/bg.webp';
import { Statistic } from './stats';
import Testimonial from './testimonial';
import TimeLine from './timeline';
import CtaSection from './cta';
import FirstSection from './fFirstSection';
import { About } from './About';
import { ForWho } from './ForWho';
import Register from './Register';
import TimeLinePng from '../../../img/timeline.png';
import callouts from '../../../data/services';
import RegButton from './button';
import RegCta from './regCTA';
import Prices from './prices';

const stickyButtonStyle = {
  position: 'sticky',
  bottom: '0px', // Adjust the value as per your requirement

  zIndex: 999, // Adjust the z-index value if needed to ensure it stays above other elements
  marginTop: '0px',
};

function Shapeup() {
  return (
    <div>
      <FirstSection />
      <div className="bg-white ">
        <Statistic />
      </div>
      <Testimonial />
      {/* <img src={TimeLinePng} alt="timeline" className="w-full" /> */}
      <TimeLine />
      <About />
      <CtaSection />
      <ForWho />
      <Register />
      <Prices />
      <div className="bg-gold" style={stickyButtonStyle}>
        {/* <RegButton data={callouts[0]} /> */}
        <RegCta data={callouts[0]} />
      </div>
    </div>
  );
}

export default Shapeup;
