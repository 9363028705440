import React, { useEffect, useState } from 'react';
import axios from 'axios';
import VModal from './Modal';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';
import api from '../api/api';

const Message = () => {
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    captcha: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      formData.name.length > 1 &&
      validateEmail(formData.email) &&
      formData.message.length > 1 &&
      validateCaptcha(formData.captcha, false) === true
    ) {
      try {
        const res = await api.post(`/contact`, { formData });
        console.log(res);
        setIsLoading(false);
        setShowModal(true);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setShowError(true);
      }
    } else {
      setIsLoading(false);
      alert('Invalid input or wrong captcha');
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);

  return (
    <div className="flex flex-col items-center">
      <form onSubmit={handleSubmit} className="flex flex-col text-sm mx-2">
        <input
          id="name"
          name="name"
          placeholder="Name"
          className="border border-solid divide-gray-300 w-80 h-10 pl-2 my-1"
          onChange={handleInputChange}
        />

        <input
          id="email"
          name="email"
          placeholder="Email"
          type="email"
          className="border border-solid divide-gray-300 w-80 h-10 pl-2 my-1"
          onChange={handleInputChange}
        />

        <textarea
          id="message"
          name="message"
          placeholder="Message"
          className="border border-solid divide-gray-300 w-80 h-36 pl-2 pt-2 my-1"
          onChange={handleInputChange}></textarea>

        <div className="flex justify-center items-center">
          <LoadCanvasTemplate />
          <input
            id="captcha"
            name="captcha"
            placeholder="Captcha"
            className="border border-solid divide-gray-300 w-32 h-10 pl-2 my-1"
            onChange={handleInputChange}
          />
          <button
            type="submit"
            disabled={isLoading}
            className="rounded-full text-main border border-solid border-persian-indigo-700 w-32 px-4 py-2 mt-8 active:scale-90 active:bg-persian-indigo-50 shadow hover:shadow-lg">
            {isLoading ? 'Loading...' : 'Send'}
          </button>
        </div>
      </form>
      {showModal ? <VModal /> : null}
      {/* {showError ? <Error /> : null} */}
    </div>
  );
};

export default Message;
