import React from 'react';
import Chart from 'react-google-charts';
import calculateMacronutrients from '../../api/calorieCalc';
import toPersianNumber from '../../api/persianNumber';

function MacrosChart({ calories }) {
  const { protein, carbs, fat } = calculateMacronutrients(
    calories,
    0.3,
    0.4,
    0.3
  );
  console.log(protein, carbs, fat);
  const data = [
    ['Macros', 'Percentage'],
    [`پروتئین ${toPersianNumber(protein)} گرم `, 30],
    [`چربی ${toPersianNumber(fat)} گرم  `, 30],
    [`کربوهیدرات ${toPersianNumber(carbs)}  گرم `, 40],
  ];
  return (
    <>
      <div>
        <div className="text-center text-md  text-fitlinez">
          ماکروها (macronutriants)
        </div>
      </div>
      <Chart
        chartType="PieChart"
        style={{
          width: '100%',
          height: '100%',
          margin: 'auto',
          padding: '0 0 0 0',
        }}
        data={data}
        options={{
          pieHole: 0.4,
          vAxis: { minValue: 0 },
          hAxis: { minValue: 0 },
          is3D: true,
          slices: {
            0: { color: '#ff0000' },
            1: { color: '#d1a3a4' },
            2: { color: '#0000ff' },
          },
          tooltip: {
            text: 'value',
          },
        }}
      />
    </>
  );
}

export default MacrosChart;
