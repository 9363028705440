import { useState } from 'react';
import Azar from '../../../img/academy.png';
import Modal from './modal';
import { Link } from 'react-router-dom';
import modalCertificate from './modalCertificate';
import CertModal from './modalCertificate';
import Cert from '../../../img/nutrition-coaching-certification.jpg';

export const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cert, setCert] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const openCert = () => {
    setCert(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeCert = () => {
    setCert(false);
  };

  const [showMoreCert, setShowMoreCert] = useState(false);

  const handleContinueReadingCert = () => {
    setShowMoreCert(!showMoreCert);
  };

  const [showMore, setShowMore] = useState(false);

  const handleContinueReading = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="px-4  mx-auto lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col">
          <div className="max-w-xl">
            <h2 className="max-w-lg  font-bold text-2xl font-bold tracking-tight text-gray-900 sm:text-lg md:text-lg lg:text-3xl sm:leading-none text-main">
              مدرس Shape Up: آذر شفیعی
              <br className="hidden md:block" />
              <span className="relative px-1">
                <div className="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400" />
                <span className="relative inline-block text-deep-purple-accent-400"></span>
              </span>
            </h2>
          </div>
          <div className="grid gap-5 row-gap-8 sm:grid-cols-1 text-main font-custom1">
            <div className="bg-white border-l-4 shadow-sm border-deep-purple-accent-400">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <ul
                  className="
                  max-w-md space-y-1 text-main list-inside dark:text-gray-400
                ">
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 mb-2 ml-2 text-green-500 dark:text-green-400 flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    <h6 className="mb-2 leading-6 font-custom1">
                      کوچ تغذیه با مدرک PN1 از موسسه ی precision nutrition
                      کانادا
                    </h6>
                  </li>

                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 text-green-500 mb-2 ml-2 dark:text-green-400 flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    <h6 className="mb-2 leading-5 font-custom1">
                      دانشجوی PN2 در precision nutrition کانادا
                    </h6>
                  </li>
                </ul>
                {showMoreCert ? (
                  <>
                    <img
                      src={Cert}
                      alt="nutrition-coaching-certification"
                      className="w-full h-fullfloat-left mr-4 mb-4"
                    />
                  </>
                ) : null}
                <span
                  className="text-sm text-main font-custom1 text-left cursor-pointer"
                  onClick={handleContinueReadingCert}>
                  {showMoreCert ? 'بستن ' : ' مشاهده مدرک'}
                </span>
              </div>
            </div>
            <div className="bg-white border-l-4 shadow-sm border-deep-purple-accent-400">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 leading-5 font-custom1">
                  {' '}
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 mb-2 ml-2 text-green-500 dark:text-green-400 flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>
                    اپلیکیشن ورزشی Fit Linez
                  </li>
                </h6>
                <p className="text-sm text-main font-custom1 text-left cursor-pointer">
                  <Link to="/application">لینک دانلود</Link>
                </p>
              </div>
            </div>
            <div className="bg-white border-l-4 shadow-sm border-deep-purple-accent-400">
              <div className="h-full p-5 border border-l-0 rounded-r">
                <h6 className="mb-2 leading-5 font-custom1">
                  <li className="flex items-center">
                    <svg
                      className="w-3.5 h-3.5 mb-2 ml-2 text-green-500 dark:text-green-400 flex-shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                    </svg>{' '}
                    مسیر آذر شفیعی
                  </li>
                </h6>
                <span className="text-sm text-main font-custom1 text-roght">
                  از نوجوانی با مسئله اضافه وزن درگیر بودم. سال ها با رژیم های
                  تهاجمی وزن کم می کردم ولی دوباره بعد از یک مدت برمی گشتم به
                  همان وزن قبلی
                  {showMore ? (
                    <>
                      <span>
                        {' '}
                        و سر خونه ی اول. اون روزها من هم مثل خیلی از افراد کفش
                        آهنی اراده می پوشیدم تا توی ماراتن رژیم گرفتم کم نیارم
                        ولی انگار داشتم روی یه تردمیل می دوییدم... به جز خستگی
                        خبری از رسیدن به مقصد نبود! دیگه حالم از کلمه رژیم بهم
                        می خورد، از طرفی انقدر چاق شده بودم که هیچ کدوم از لباس
                        هام اندازه ام نمی شد. کلافگی و افسردگیم به اونجایی رسید
                        که سال ۹۸ تصمیم گرفتم برم عمل جراحی کنم و از شر چربی ها
                        خلاص بشم. البته شانس آوردم که عمل کنسل شد،چرا؟ به خاطر
                        کرونا! از اون روز تصمیم گرفتم سالم خوری کنم تا وقتی
                        کرونا کم بشه و بتونم برم سمت اتاق عمل کنم. اما آذی اون
                        موقع از کجا می دونست سالم خوری چی هست؟! هیچ ایده ای
                        نداشتم که باید از کجا باید شروع کنم و چی کار کنم...
                        یکسال بعد با کوچینگ تغذیه آشنا شدم، هرچه قدر بیشتر
                        مطالعه کردم، بیشتر به این پی بردم که چقدر راه رو اشتباه
                        رفتم و چقدر به بدن و ذهنم آسیب وارد کردم. با گذشت زمان و
                        دیدن اینکه چطور اول بدنم و بعد تک تک ابعاد زندگیم دگرگون
                        شدن، تصمیم گرفتم بصورت علمی و آکادمیک وارد این حوزه بشم.
                        در دوره تخصصی PN1 موسسه precision nutrition شرکت کردم و
                        بعد از گذروندن امتحانات، سرتیفیکیت گرفتم. دیگه برای
                        اینکه بتونم این دریچه ی جدید رو به بقیه هم نشون بدم و
                        کمک کنم به آگاهی برسن احساس آمادگی می کردم. بعد از چند
                        ماه با دیدن تغییرات مثبت و نتیجه گرفتن کلاینت ها، یکی از
                        اساسی ترین تصمیم های زندگیم رو گرفتم. با کار و ۱۵ سال
                        سابقه مدیر بازرگانی در حوزه نفت و گاز خداحافظی کردم تا
                        به شکل فول تایم در حوزه ی کوچینگ تغذیه مشغول بشم. همیشه
                        در مسیر معتقد بودم و هستم که یک کوچ متخصص باید مداوم به
                        ارتقای خودش بپردازه و در حال آپدیت کردن مطالبی باشه که
                        به کلاینت ها ارائه میده باشه، به خاطر همین در دوره های
                        مربیگری، فیتنس و سمینارهای تغذیه ای و ورزشی زیادی شرکت
                        کردم و می کنم و در حال حاضر دانشجوی PN2 هستم.
                      </span>
                    </>
                  ) : null}
                  <span
                    className="text-sm text-main font-bold text-roght cursor-pointer"
                    onClick={handleContinueReading}>
                    {showMore ? 'بستن متن' : '  ادامه متن'}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <img
            className="object-fit
            rounded shadow-lg sm:h-3/4"
            src={Azar}
            alt="azar"
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
      <CertModal isOpen={cert} onClose={closeCert} />
    </div>
  );
};
