import React from 'react';
import { Header } from './header';
import Carosusel from './Carosusel';

function AppLanding() {
  return (
    <div dir="ltr">
      <Header />
      <Carosusel />
    </div>
  );
}

export default AppLanding;
