import React from 'react';

function Benefints() {
  return (
    <div className="items-start self-center flex w-full max-w-[1218px] flex-col mt-12 max-md:max-w-full">
      <div className="items-start self-center flex w-full justify-between gap-5 px-5 max-md:max-w-full max-md:flex-wrap">
        <div className="items-start self-stretch flex flex-col w-[232px]">
          <div className="text-black text-center text-xl  tracking-normal self-stretch">
            <span className="text-black"> Benefits of FitLinez</span>
          </div>{' '}
          <div className="self-stretch text-black text-2xl  tracking-normal w-full mt-5">
            <span className="text-orange-500">Exclusive Benefits</span>
            <span className="text-black">
              {' '}
              <br />
              of Fitlinez
            </span>
          </div>
        </div>{' '}
        <div className="text-black text-sm max-w-[376px] self-center items-center my-auto p-2.5 border-l-black border-l border-solid">
          Fitlinez is a fitness app that helps you to track your workout and
          fine-tuning the plan in real-time. It is a one-stop solution for all
          your fitness needs.
        </div>
      </div>
      {/* <div className="self-center w-full max-w-[1178px] mt-8 px-5 max-md:max-w-full">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-3/12 max-md:w-full">
            <div className="items-center self-stretch flex flex-col w-[193px] max-md:mt-12">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a743d20c-8af8-403c-866a-58c9b0ae57e5?apiKey=154f236011bc4824b8babf83bfdb3a28&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a743d20c-8af8-403c-866a-58c9b0ae57e5?apiKey=154f236011bc4824b8babf83bfdb3a28&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a743d20c-8af8-403c-866a-58c9b0ae57e5?apiKey=154f236011bc4824b8babf83bfdb3a28&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a743d20c-8af8-403c-866a-58c9b0ae57e5?apiKey=154f236011bc4824b8babf83bfdb3a28&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a743d20c-8af8-403c-866a-58c9b0ae57e5?apiKey=154f236011bc4824b8babf83bfdb3a28&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a743d20c-8af8-403c-866a-58c9b0ae57e5?apiKey=154f236011bc4824b8babf83bfdb3a28&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a743d20c-8af8-403c-866a-58c9b0ae57e5?apiKey=154f236011bc4824b8babf83bfdb3a28&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a743d20c-8af8-403c-866a-58c9b0ae57e5?apiKey=154f236011bc4824b8babf83bfdb3a28&"
                className="aspect-square object-cover object-center w-[60px] self-center shrink-0 rounded-none"
              />
              <div className="items-center self-stretch flex grow flex-col w-full mt-10">
                <div className="text-orange-500 text-xl italic self-center">
                  Time Efficiency
                </div>
                <div className="text-black text-sm self-stretch w-full mt-6">
                  <br />
                </div>
              </div>
            </div>
          </div>{' '}
          <div className="flex flex-col items-stretch w-[28%] ml-5 max-md:w-full">
            <div className="items-center self-stretch flex flex-col w-[205px] max-md:mt-12">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ec9e6f8a-81d6-4ce1-819c-167d6095df8b?apiKey=154f236011bc4824b8babf83bfdb3a28&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ec9e6f8a-81d6-4ce1-819c-167d6095df8b?apiKey=154f236011bc4824b8babf83bfdb3a28&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ec9e6f8a-81d6-4ce1-819c-167d6095df8b?apiKey=154f236011bc4824b8babf83bfdb3a28&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ec9e6f8a-81d6-4ce1-819c-167d6095df8b?apiKey=154f236011bc4824b8babf83bfdb3a28&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ec9e6f8a-81d6-4ce1-819c-167d6095df8b?apiKey=154f236011bc4824b8babf83bfdb3a28&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ec9e6f8a-81d6-4ce1-819c-167d6095df8b?apiKey=154f236011bc4824b8babf83bfdb3a28&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ec9e6f8a-81d6-4ce1-819c-167d6095df8b?apiKey=154f236011bc4824b8babf83bfdb3a28&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ec9e6f8a-81d6-4ce1-819c-167d6095df8b?apiKey=154f236011bc4824b8babf83bfdb3a28&"
                className="aspect-square object-cover object-center w-[60px] self-center shrink-0 rounded-none"
              />{' '}
              <div className="items-center self-stretch flex grow flex-col w-full mt-10">
                <div className="text-orange-500 text-xl italic self-stretch">
                  Personalized Workouts
                </div>{' '}
                <div className="text-black text-sm self-stretch w-full mt-6">
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full">
            <div className="items-center self-stretch flex grow flex-col w-44 max-md:mt-12">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e53ec779-fe38-4fd6-98bf-7a7448850ef0?apiKey=154f236011bc4824b8babf83bfdb3a28&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e53ec779-fe38-4fd6-98bf-7a7448850ef0?apiKey=154f236011bc4824b8babf83bfdb3a28&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e53ec779-fe38-4fd6-98bf-7a7448850ef0?apiKey=154f236011bc4824b8babf83bfdb3a28&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e53ec779-fe38-4fd6-98bf-7a7448850ef0?apiKey=154f236011bc4824b8babf83bfdb3a28&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e53ec779-fe38-4fd6-98bf-7a7448850ef0?apiKey=154f236011bc4824b8babf83bfdb3a28&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e53ec779-fe38-4fd6-98bf-7a7448850ef0?apiKey=154f236011bc4824b8babf83bfdb3a28&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e53ec779-fe38-4fd6-98bf-7a7448850ef0?apiKey=154f236011bc4824b8babf83bfdb3a28&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e53ec779-fe38-4fd6-98bf-7a7448850ef0?apiKey=154f236011bc4824b8babf83bfdb3a28&"
                className="aspect-square object-cover object-center w-[60px] self-center shrink-0 rounded-none"
              />
              <div className="items-center self-stretch flex grow flex-col w-full mt-10">
                <div className="text-orange-500 text-xl italic self-center">
                  Cost Savings
                </div>
                <div className="text-black text-sm self-stretch w-full mt-6">
                  <br />
                </div>
              </div>
            </div>
          </div>{' '}
          <div className="flex flex-col items-stretch w-[22%] ml-5 max-md:w-full">
            <div className="items-center self-stretch flex flex-col w-[193px] max-md:mt-12">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/67961af7-fd0d-4a0f-849c-e2802baa9f77?apiKey=154f236011bc4824b8babf83bfdb3a28&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/67961af7-fd0d-4a0f-849c-e2802baa9f77?apiKey=154f236011bc4824b8babf83bfdb3a28&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/67961af7-fd0d-4a0f-849c-e2802baa9f77?apiKey=154f236011bc4824b8babf83bfdb3a28&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/67961af7-fd0d-4a0f-849c-e2802baa9f77?apiKey=154f236011bc4824b8babf83bfdb3a28&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/67961af7-fd0d-4a0f-849c-e2802baa9f77?apiKey=154f236011bc4824b8babf83bfdb3a28&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/67961af7-fd0d-4a0f-849c-e2802baa9f77?apiKey=154f236011bc4824b8babf83bfdb3a28&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/67961af7-fd0d-4a0f-849c-e2802baa9f77?apiKey=154f236011bc4824b8babf83bfdb3a28&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/67961af7-fd0d-4a0f-849c-e2802baa9f77?apiKey=154f236011bc4824b8babf83bfdb3a28&"
                className="aspect-square object-cover object-center w-[60px] self-center shrink-0 rounded-none"
              />{' '}
              <div className="items-center self-stretch flex grow flex-col w-full mt-10">
                <div className="text-orange-500 text-xl italic self-center">
                  Fast And Accurate
                </div>{' '}
                <div className="text-black text-sm self-stretch w-full mt-6">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Benefints;
