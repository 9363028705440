const calculateMacronutrients = (
  calories,
  proteinPercent,
  carbsPercent,
  fatPercent
) => {
  console.log(calories, proteinPercent, carbsPercent, fatPercent);
  //calculate Macronutrients based on calories
  const protein = Math.round((calories * proteinPercent) / 4);
  const fat = Math.round((calories * fatPercent) / 9);
  const carbs = Math.round((calories * carbsPercent) / 4);
  console.log(protein, carbs, fat);
  return { protein, carbs, fat };
};

export default calculateMacronutrients;
