import { Label, TextInput } from 'flowbite-react';
import React from 'react';

function TextInputs(props) {
  const { label, id, type, placeholder, onChange, value } = props;
  return (
    <div className="w-full lg:w-1/3 mt-2">
      <div className="mb-2 block">
        <Label htmlFor="email1" value={label} />
      </div>
      <TextInput
        value={value}
        onChange={onChange}
        id={id}
        type={type}
        placeholder={placeholder}
        required
      />
    </div>
  );
}

export default TextInputs;
