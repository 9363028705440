import { CheckIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import callouts from '../../data/services';
import { useEffect, useState } from 'react';
import Modal from '../../component/Modal';

export default function Services() {
  const { id } = useParams();
  const userEmail = new URLSearchParams(window.location.search).get('email');

  const data = callouts.find((p) => p.id === parseInt(id));
  console.log(data);
  const [isEmail, setIsEmail] = useState(false);

  useEffect(() => {
    if (userEmail) {
      setIsEmail(true);
    }
  }, [userEmail]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>فیتلاینز </title>
        <link
          rel="canonical"
          href={`https://fitlinez.com/service/${data.id}`}
        />
      </Helmet>
      <div className="bg-white relative overflow-hidden py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-bold  leading-7 text-main font-custom1">
              {data.name}
            </h2>
            <p
              className={`my-10  drop-shadow text-3xl font-bold tracking-tight ${
                data.id === 5 ? 'text-silver' : 'text-gold'
              } sm:text-4xl font-custom1`}>
              {data.description}
            </p>
            <div className="relative overflow-hidden">
              <img
                className="space-y-1.5 h-96 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                src={data.imageSrc}
                alt={data.imageAlt}
              />
              {data.active !== true && (
                <div className="absolute right-0 top-0 h-16 w-16">
                  <div className="bg-nonary absolute transform rotate-45 bg-green-600 text-center text-gold font-semibold py-1 right-[-35px] top-[32px] w-[170px]">
                    ظرفیت تکمیل شد
                  </div>
                </div>
              )}
            </div>
            <p className="mx-6 text-lg leading-8 text-main">
              {data.id === 1 && (
                <>
                  <dd className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 sm:gap-y-16 lg:gap-x-8 text-main font-custom1 bg-primary rounded-2xl px-6 py-6">
                    هر چهارشنبه ساعت ۲۰ تا ۲۱ به وقت ایران در ZOOM
                    <br />
                    تمامی ویدیو های دوره بصورت نامحدود در پنل کاربری در دسترس
                    شما خواهد بود
                  </dd>
                </>
              )}
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl ">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16 bg-secondary rounded-xl px-6 py-6 text-right">
              {data.features && (
                <dt className="text-main font-bold font-custom1 text-xl underline underline-offset-8">
                  ویژگی ها
                </dt>
              )}
              {data.features?.map((feature) => (
                <div key={feature.id} className="pl-16">
                  <dt className="leading-7 text-main">
                    <div className="relative pl-9 flex flex-row">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 ml-2">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                        />
                      </svg>

                      <dd className="inline font-custom1">
                        {' '}
                        {feature.details}
                      </dd>
                    </div>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-main"></dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl ">
            {data.forWho ? (
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16 bg-secondary rounded-xl px-6 py-6 text-right">
                {data.forWho && (
                  <dt className="text-main font-bold font-custom1 text-xl underline underline-offset-8">
                    این دوره برای چه کسانی مناسب است؟
                  </dt>
                )}
                {data.forWho?.map((feature) => (
                  <div key={feature.id} className="pl-16">
                    <dt className="leading-7 text-main">
                      {' '}
                      <div className="relative pl-9 flex flex-row">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 ml-2">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                          />
                        </svg>

                        <dd className="inline font-custom1">
                          {' '}
                          {feature.title}
                        </dd>
                      </div>
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-main"></dd>
                  </div>
                ))}
              </dl>
            ) : null}
            {data.price && (
              <dd className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 sm:gap-y-16 lg:gap-x-8 text-main font-custom1 text-4xl	">
                {data.period} {data.price} یورو
              </dd>
            )}

            {data.priceRial && (
              <dd className="my-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 sm:gap-y-16 lg:gap-x-8 text-main font-custom1 text-2xl	">
                هزینه {data.period} برای کاربران ساکن ایران {''}:{' '}
                {data.priceRialSign} تومان
              </dd>
            )}
            {data.active ? (
              <dl className="mt-1 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 sm:gap-y-16 lg:gap-x-8">
                <Link to={`/signup`} state={{ data: data }}>
                  <button className="bg-gold hover:bg-silver text-main font-semibold hover:text-main py-2 px-4 border border-blue-500 hover:border-transparent rounded mb-5">
                    ثبت نام
                  </button>
                </Link>
              </dl>
            ) : (
              <div
                className="bg-orange-100 text-main border-l-4 border-orange-500 p-4"
                role="alert">
                <strong className="font-bold">ثبت نام غیر فعال است</strong>{' '}
                <span className="block sm:inline">ظرفیت تکمیل شد </span>{' '}
              </div>
            )}
          </div>
        </div>
      </div>
      {isEmail && (
        <Modal
          message={`لطفا برای خرید این محصول وارد حساب کاربری خود با ایمیل ${userEmail} شوید `}
          buttonTitle="تایید"
          onClick={() => {
            window.location.href = '/login';
          }}
        />
      )}
    </>
  );
}
