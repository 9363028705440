import React from 'react';

const FilePreview = ({ fileUrl, index }) => {
  const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    return extension;
  };

  const renderPreview = (url) => {
    const fileType = getFileType(url);

    // Handle image formats (png, jpg, gif, webp, etc.)
    const imageFormats = ['png', 'jpg', 'jpeg', 'gif', 'webp'];
    if (imageFormats.includes(fileType)) {
      return (
        <div className="w-32 h-32 mt-2 flex items-center justify-center">
          <img
            className="rounded-lg object-contain w-32 h-32"
            src={url}
            alt={`Image ${index + 1}`}
          />
        </div>
      );
    }

    // Handle PDF format
    if (fileType === 'pdf') {
      return (
        <div className="w-32 h-32 mt-2 flex items-center justify-center">
          <iframe
            className="rounded-lg w-32 h-32"
            src={url}
            title={`PDF Preview ${index + 1}`}
            frameBorder="0"></iframe>
        </div>
      );
    }

    // Handle other file formats (display a link)
    return (
      <div className="w-32 h-32 mt-2 flex items-center justify-center">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline">
          View File
        </a>
      </div>
    );
  };

  return (
    <div className="flex justify-center mb-3">
      {fileUrl && renderPreview(fileUrl)}
    </div>
  );
};

export default FilePreview;
