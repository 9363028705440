import { Label, Select } from 'flowbite-react';
import React from 'react';

function Selects(props) {
  const { label, id, options, onChange, value } = props;

  return (
    <div className="w-full lg:w-1/3 mt-2">
      <div className="mb-2 block">
        <Label htmlFor={id} value={label} />
      </div>
      <Select onChange={onChange} id={id} value={value || ''} required>
        {options?.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </Select>
    </div>
  );
}

export default Selects;
