import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fa: {
    translation: {
      brand: ' آذر شفیعی، کوچینگ تغذیه',
      home: 'صفحه اصلی',
      services: 'خدمات',
      application: 'اپلیکیشن',
      contact: 'تماس با ما',
      calorie: 'محاسبه کالری',
      login: 'ورود',
      logout: 'خروج',
      register: 'ثبت نام',
      dashboard: 'پنل کاربری',
      report: 'گزارش دهی',
      logout: 'خروج',
    },
    en: {
      translation: {
        brand: 'Azar Shafiei',
        home: 'Home Page',
        services: 'Services',
        application: 'Application',
        contact: 'Contact',
        calorie: 'Calorie Calculator',
        login: 'Login',
        logout: 'Logout',
        register: 'Register',
        dashboard: 'Dashboard',
        report: 'Report',
        logout: 'Logout',
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fa', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
