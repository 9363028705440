import { FileInput, Label } from 'flowbite-react';
import React from 'react';

function FileInputs(props) {
  const { onChange, id, value, helpText, multiple } = props;
  return (
    <div>
      <div>
        <Label htmlFor={id} value={value} />
      </div>
      <FileInput onChange={onChange} id={id} helperText={helpText} multiple />
    </div>
  );
}

export default FileInputs;
