import React, { useState } from 'react';
import Cert from '../../../img/nutrition-coaching-certification.jpg';

const CertModal = ({ isOpen, onClose }) => {
  const modalClasses = isOpen
    ? 'fixed top-0 left-0 w-full h-auto flex justify-center items-center bg-gray-800 bg-opacity-75 font-custom1 overflow-hidden'
    : 'hidden';

  return (
    <div className={modalClasses}>
      <div className="bg-white p-4 rounded-md max-w-lg">
        <div className="flex justify-between bg-main text-white rounded-full mb-4">
          <h3 className="text-2xl font-bold text-right mb-4 p-3"></h3>
          <button
            className="p-2 text-xs justify-left font-bold  "
            onClick={onClose}>
            بستن X
          </button>
        </div>

        <img
          src={Cert}
          alt="nutrition-coaching-certification"
          className="w-full h-fullfloat-left mr-4 mb-4"
        />
      </div>
    </div>
  );
};

export default CertModal;
