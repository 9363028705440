import { Helmet } from 'react-helmet';
import Hero from './hero';
import HomeHeader from './homeHeader';
import HomeStats from './homeStats';
import HomeOverView from './productOverView';
import Services from './Services';
import { useContext } from 'react';
import UserContext from '../../context/usercontext';
import { Header } from './header';
import SubHero from './SubHero';
import Benefints from './Benefints';
import AppDemo from './AppDemo';
import Features from './Features';
import Testimonials from './Testimonials';
import Download from './Download';
import FitlinezPromo from './appFeature';
import CookieConsent from 'react-cookie-consent';
import FeatureCompare from './featureCompare';

const Home = () => {
  return (
    <div dir="ltr">
      <Helmet>
        <meta charSet="utf-8" />
        <title>کوچینگ آنلاین تغذیه | آذر شفیعی</title>
        <link rel="canonical" href="https://fitlinez.com" />
      </Helmet>

      <div className="bg-transparent flex flex-col">
        <div className="self-stretch flex w-full flex-col mt-5 max-md:max-w-full">
          <div className="flex-col self-stretch relative flex  mt-5 pt-1.5 px-5 max-md:max-w-full">
            <Hero />
          </div>
          <Services />
          <FeatureCompare />
          {/* <FitlinezPromo />
          <AppDemo />

          <Testimonials />
          <Download /> */}
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}>
            This website uses cookies to enhance the user experience.{' '}
          </CookieConsent>
        </div>
      </div>
    </div>
  );
};

export default Home;
