import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  if (location.pathname === '/') {
    return null;
  }

  return (
    <div dir="ltr" className="mx-5 my-4 border-b-5 text-sm breadcrumbs">
      <Link to="/">Home</Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const decodedName = decodeURIComponent(name);

        const isLast = index === pathnames.length - 1;

        return (
          <span key={name}>
            <span> / </span>
            <Link to={routeTo}>
              {isLast ? <span className="font-bold">{decodedName}</span> : name}
            </Link>
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
