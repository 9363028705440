import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ImageUpload from '../../../api/uploadImages';
const Report = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState();
  const [userProducts, setUserProducts] = useState();
  const [name, setName] = useState('دوست');
  const [userPermission, setUserPermission] = useState(false);
  const [userValidDay, setUserValidDay] = useState('');

  const todayName = new Date().toLocaleDateString('fa-IR', {
    weekday: 'long',
  });
  const yesterday = new Date().toLocaleDateString('fa-IR', {
    weekday: 'long',
  });
  const tomorrow = new Date().toLocaleDateString('fa-IR', {
    weekday: 'long',
  });

  //console.log(todayName);

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      try {
        const parsedCredentials = JSON.parse(credentials);
        setIsLoggedIn(true);
        setUserId(parsedCredentials.id);
        setUserProducts(parsedCredentials?.userProduct);
        setName(parsedCredentials?.name);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoggedIn(false);
        setIsLoading(false);
      }
    } else {
      setIsLoggedIn(false);
      setIsLoading(false);
    }
  }, []);

  const reportType = [
    {
      name: 'ارسال گزارش  هفتگی',
      link: '/dashboard/report/weeklyreport',
      limited: false,
      reportLink: '/dashboard/weekly-history',
      ActiveReport: true,
      // historytitle: 'ارسال گزارش هفتگی فقط از طریق ربات تلگرام امکان پذیر است',
    },
    {
      name: ' ارسال تصاویر قبل/بعد',
      link: '/dashboard/report/beforeafter',
      limited: false,
      reportLink: '/dashboard/beforeafterhistory',
      ActiveReport: true,
      historytitle: 'مشاهده تصاویر قبل/بعد',
    },
    {
      name: ' ارسال گزارش سایز ',
      link: '/dashboard/report/size',
      limited: false,
      reportLink: '/dashboard/size-history',
      ActiveReport: true,
      historytitle: 'مشاهده سایزهای قبلی',
    },
  ];

  // const checkValidDay = (productName, targetDay) => {
  //   console.log('in checkValidDay func', productName, targetDay);

  //   const validDays = {
  //     'shape up 4': 'سه شنبه',
  //     'shape up 5': 'جمعه',
  //     'shape up pro 3': 'یکشنبه',
  //   };

  //   return validDays[productName.toLowerCase()] === targetDay.toLowerCase();
  // };

  // const checkUserProducts = () => {
  //   if (userProducts) {
  //     console.log(userProducts);
  //     userProducts.find((product) => {
  //       setUserValidDay(checkValidDay(product.name, todayName));
  //     });
  //   }
  // };

  // useEffect(() => {
  //   checkUserProducts();
  // }, [userProducts, todayName]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 font-custom1">
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className=" w-full bg-gray-100 font-custom1">
        {/* <ImageUpload /> */}

        <div className="flex  flex-col gap-3 items-center  p-6 ">
          {reportType.map((item) => (
            <div className=" md:w-1/3 w-full bg-base-100 mx-3 shadow-xl p-3 rounded rounded-lg">
              <div className="">
                <h2 className="card-title">
                  <Link
                    //to={item.link}
                    to={item.link}
                    state={{ userId: userId }}
                    className="bg-blue-700 text-white px-4 py-2 rounded-lg mt-4">
                    {!userPermission
                      ? ` ${item.name}`
                      : 'شما اجازه دسترسی به این صفحه را ندارید'}{' '}
                  </Link>
                </h2>
                <p className="text-center text-red-500">
                  {/* {!item.limited
                    ? ''
                    : checkValidDay(
                        userProducts?.[userProducts.length - 1]?.name,
                        todayName
                      )
                    ? ''
                    : 'مهلت ارسال گزارش به پایان رسیده است'}{' '} */}
                </p>

                <div className="card-actions justify-end text-xs">
                  <div className="">
                    <Link
                      to={item.ActiveReport ? item.reportLink : null}
                      state={{ userId: userId }}
                      // className="bg-blue-700 text-white px-4 py-2 rounded-lg mt-4"
                    >
                      {!userPermission
                        ? ` ${item.historytitle ? item.historytitle : ''}`
                        : 'شما اجازه دسترسی به این صفحه را ندارید'}{' '}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Report;
