import React, { useState } from 'react';
import Azi from '../../img/azar.jpg';

import Cert from '../../img/nutrition-coaching-certification.jpg';

function Team() {
  const [showMore, setShowMore] = useState(false);

  const handleContinueReading = () => {
    setShowMore(!showMore);
  };
  return (
    <div className="py-20 bg-gray-50 text-justify	">
      <div className="container mx-auto px-6 md:px-12 xl:px-32">
        <div className="mb-16 text-center">
          <h2 className="mb-4 text-center text-2xl text-gray-900 font-bold md:text-4xl"></h2>
          <p className="text-gray-600 lg:w-8/12 lg:mx-auto"></p>
        </div>
        <div className=" gap-12 items-center ">
          <div>
            <h4 className="text-3xl font-bold mb-4">آذر شفیعی</h4>
            <h6 dir="rtl" className="mb-4 leading-6 font-custom1">
              کوچ تغذیه با مدرک PN1 از موسسه ی precision nutrition کانادا
            </h6>
          </div>
          <div className="space-y-4 text-center grid md:grid-cols-2 mb-4">
            <img
              className="mx-auto rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80 object-cover"
              src={Azi}
              alt="Azar Shafiei"
              loading="lazy"
              width="1000"
              height="667"
            />
            <img
              className="mx-auto rounded-xl object-cover w-96"
              src={Cert}
              alt="Azar Shafiei"
              loading="lazy"
              width="1000"
              height="667"
            />
          </div>
          <span className="text-sm text-main font-custom1 text-justify	mt-5">
            از نوجوانی با مسئله اضافه وزن درگیر بودم. سال ها با رژیم های تهاجمی
            وزن کم می کردم ولی دوباره بعد از یک مدت برمی گشتم به همان وزن قبلی و
            سر خونه ی اول. اون روزها من هم مثل خیلی از افراد کفش آهنی اراده می
            پوشیدم تا توی ماراتن رژیم گرفتم کم نیارم ولی انگار داشتم روی یه
            تردمیل می دوییدم... به جز خستگی خبری از رسیدن به مقصد نبود! دیگه
            حالم از کلمه رژیم بهم می خورد، از طرفی انقدر چاق شده بودم که هیچ
            کدوم از لباس هام اندازه ام نمی شد. کلافگی و افسردگیم به اونجایی رسید
            که سال ۹۸ تصمیم گرفتم برم عمل جراحی کنم و از شر چربی ها خلاص بشم.
            البته شانس آوردم که عمل کنسل شد،چرا؟ به خاطر کرونا! از اون روز تصمیم
            گرفتم سالم خوری کنم تا وقتی کرونا کم بشه و بتونم برم سمت اتاق عمل
            کنم. اما آذی اون موقع از کجا می دونست سالم خوری چی هست؟! هیچ ایده ای
            نداشتم که باید از کجا باید شروع کنم و چی کار کنم... یکسال بعد با
            کوچینگ تغذیه آشنا شدم، هرچه قدر بیشتر مطالعه کردم، بیشتر به این پی
            بردم که چقدر راه رو اشتباه رفتم و چقدر به بدن و ذهنم آسیب وارد کردم.
            با گذشت زمان و دیدن اینکه چطور اول بدنم و بعد تک تک ابعاد زندگیم
            دگرگون شدن، تصمیم گرفتم بصورت علمی و آکادمیک وارد این حوزه بشم. در
            دوره تخصصی PN1 موسسه precision nutrition شرکت کردم و بعد از گذروندن
            امتحانات، سرتیفیکیت گرفتم. دیگه برای اینکه بتونم این دریچه ی جدید رو
            به بقیه هم نشون بدم و کمک کنم به آگاهی برسن احساس آمادگی می کردم.
            بعد از چند ماه با دیدن تغییرات مثبت و نتیجه گرفتن کلاینت ها، یکی از
            اساسی ترین تصمیم های زندگیم رو گرفتم. با کار و ۱۵ سال سابقه مدیر
            بازرگانی در حوزه نفت و گاز خداحافظی کردم تا به شکل فول تایم در حوزه
            ی کوچینگ تغذیه مشغول بشم. همیشه در مسیر معتقد بودم و هستم که یک کوچ
            متخصص باید مداوم به ارتقای خودش بپردازه و در حال آپدیت کردن مطالبی
            باشه که به کلاینت ها ارائه میده باشه، به خاطر همین در دوره های
            مربیگری، فیتنس و سمینارهای تغذیه ای و ورزشی زیادی شرکت کردم و می کنم
            و در حال حاضر دانشجوی PN2 هستم.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Team;
