import React, { useState } from 'react';
import './timeline.css';
import { random } from 'maath';
import TimeLLine from '../../../../src/img/timeline.png';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/react-flicking/dist/flicking-inline.css';
import 'react-vertical-timeline-component/style.min.css';
import close from '../../../../src/close.svg';

const randomColor = () => {
  const colors = [
    '#4A192C',
    '#287233',
    '#E55137',
    '#1F3438',
    '#FF7514',
    '#1E1E1E',
    '#9C9C9C',
  ];
  const random = Math.floor(Math.random() * colors.length);
  return colors[random];
};

const timeline = [
  {
    week: 'هفته اول',
    title: 'سفر به درون بدن',
    icon: '❤️',
    text: 'به درون بدنمون سفر کنیم. شروع سفر جذاب شناخت خودمون و بدنمون، تو این هفته ارزش مواد غذایی رو می شناسیم و در مورد آنچه می خوریم آگاه می شیم.',
  },
  {
    week: 'هفته دوم',
    title: 'جادویی به اسم پروتئین',
    icon: '🥩',
    text: 'جادویی به اسم پروتئین\nپروتئین رو می شناسیم و با هم در مورد نقشش در سلامتی و‌تناسب انداممون صحبت می کنیم و به این سوال جواب میدیم که چرا انقدر پروتئین مهمه؟!',
  },
  {
    week: 'هفته سوم',
    title: 'راز کربوهیدرات',
    icon: '🍞',
    text: 'راز کربوهیدرات!\nتو این هفته راز کربوهیدرات خوردن رو یاد می گیریم و در یک کلام هوشمندانه کربوهیدرات ها رو انتخاب می کنیم.',
  },
  {
    week: 'هفته چهارم',
    title: 'کاپ سلامتی و تناسب اندام',
    icon: '🍳',
    text: '«کاپ سلامتی و تناسب اندام»\nتو این هفته حضور«سلامتی» رو حس می کنیم و تغییرات و تناسب انداممونو به شیوه کاملا اصولی به چشم میبینیم.',
  },
  {
    week: 'هفته پنجم',
    title: 'روتینتو بساز!',
    icon: '🏋️‍♀️',
    text: 'روتینتو بساز!\nتو این هفته ایرادهای تغذیه ای مون رو بررسی و رفع می کنیم. در کنار هم تمرین می کنیم و ورزش قدرتی رو تبدیل به روتین زندگیمون می کنیم.',
  },
  {
    week: 'هفته ششم',
    title: 'بشقابتو بچین!',
    icon: '🍽',
    text: 'بشقابتو بچین!\nتو این هفته با هم بشقاب کامل و سالم غذایی می چینیم و برخلاف تصویر بی مزه و تکراری که از سالم خوری داریم،تجربه متفاوتی از «بشقاب کامل» به دست میاریم.',
  },
  {
    week: 'هفته هفتم',
    title: 'سبد خریدتو اصلاح کن',
    icon: '🛒',
    text: 'سبد خریدتو اصلاح کن.\nتو این هفته با هم میریم خرید و یه تجربه متفاوت از تغذیه بهتر در نتیجه خرید بهتر، به دست میاریم.',
  },
  {
    week: 'هفته هشتم',
    title: 'کاپ روتین سازی قدم روزانه',
    icon: '🏃‍♀️',
    text: '«کاپ روتین سازی قدم روزانه»\nتو این هفته «اکتیو شدن» واقعی رو تجربه می کنیم و بیشتر شاهد تغییرات فیزیکیمون هستیم( کاهش/ افزایش وزن و سایز ). خبر خوب اینکه گرفتن قدم های روزانمون روتین شده و باید جشن بگیریم.',
  },
  {
    week: 'هفته نهم',
    title: 'من صدای تو رو می شنوم!',
    icon: '👂',
    text: 'من صدای تو رو می شنوم!\nتو این هفته افزایش آگاهی نسبت به خودمون و بدنمون رو احساس و البته زندگی می کنیم.',
  },
  {
    week: 'هفته دهم',
    title: 'به مغزت غذا بده!',
    icon: '🧠',
    text: 'به مغزت غذا بده!\nتو این هفته تاثیر تغییر مایندستمون روی ظاهرمونو میبینیم و بیشتر به این مفهوم پی میبریم که؛ بدن ما با تغییر ذهنمون تغییر خواهد کرد و نیاز به گرسنگی کشیدن نیست.',
  },
  {
    week: 'هفته یازدهم',
    title: 'من عاشق بدنم هستم',
    icon: '🥰',
    text: 'من عاشق بدنم هستم.\nتو این هفته احساس علاقه زیادی به خودت داری و دیگه کامل یادگرفتی چی رو باید چقدر بخوری؟!',
  },
  {
    week: 'هفته دوازدهم',
    title: ' کاپ تغییر سبک زندگی ',
    icon: '🧘‍♀️',
    text: 'تو این هفته تمام یاد گرفته هاتو تمرین می کنی و سالم خوری و اکتیو بودن روتینت می شه.  تبریک می گم «سبک زندگی سالمت» رو شکل دادی!!  حالا باید بهش ادامه بدی.',
  },
];

function Timeline() {
  const [visible, setVisible] = useState(false);
  return (
    <section dir="rtl " className="font-bold">
      <div className="flex-1 bg-main shadow-xl mt-4 p-8">
        {visible ? (
          <div>
            <div className="flex justify-center ">
              <div className="flex justify-center rounded rounded-lg border border-1 ">
                <button
                  onClick={() => setVisible(!visible)}
                  className="bg-main text-white font-bold text-2xl py-2 px-4  justify-center hover:bg-white hover:text-main hover:border-main border-2 border-main">
                  بستن تایم لاین
                </button>
                <img
                  src={close}
                  alt="close"
                  onClick={() => setVisible(!visible)}
                  style={{
                    width: '30px',
                    height: '30px',
                    top: '0',
                    right: '0',
                    margin: '10px',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>

            <h4 className="text-xl text-white font-bold text-center mt-4">
              تایم لاین دوره
            </h4>

            {timeline.map((item) => (
              <VerticalTimeline
                layout="1-column"
                className="vertical-timeline-custom-line"
                animate={true}
                key={item.week}
                //add load more

                // layout="1-column"
              >
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: '#fff',
                    color: '#3f51b5',
                  }}
                  contentArrowStyle={{
                    borderRight: '#3E3B6C',
                  }}
                  iconStyle={{
                    // background: '#fff',
                    // color: '#3E3B6C',
                    fontSize: '2.5rem',
                  }}
                  icon={item.icon}>
                  <span
                    className="  
            text-center font-bold
              ">
                    {' '}
                    <h3 className="vertical-timeline-element-title">
                      {item.week} : {item.title}
                    </h3>
                  </span>

                  <p>{item.text}</p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            ))}
          </div>
        ) : (
          <div
            className="
          flex flex-col items-center
          ">
            <div className="flex justify-center rounded rounded-lg border border-1 ">
              <button
                onClick={() => setVisible(true)}
                className="bg-main text-white font-bold text-2xl py-2 px-4  justify-center hover:bg-white hover:text-main hover:border-main border-2 border-main animate-text text-gradient-to-r from-white via-gold to-white
              ">
                مشاهده تایم لاین
              </button>
            </div>
            {/* <img src={TimeLLine} alt="timeline" className="" /> */}
          </div>
        )}
      </div>
    </section>
  );
}

export default Timeline;
