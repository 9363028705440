import React, { useEffect, useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import callouts from '../../data/services';
import { Link, useLocation } from 'react-router-dom';
import countries from '../../data/countries';
import Modal from '../../component/Modal';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import api from '../../api/api';

const validationSchema = Yup.object().shape({
  password: Yup.string().required().label('Password'),
  name: Yup.string().required().label('Name'),
});

const SignUp = () => {
  const location = useLocation();
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  let queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const locations = queryParams.get('location');
  const amount = queryParams.get('amount');
  const amountRial = queryParams.get('amountRial');
  const product = queryParams.get('product');
  const discountCode = 'discount';

  console.log('urlParams', email, locations, amount, amountRial);

  const discountPercentage = 10; // Change this value to adjust the discount percentage
  const discountedPrice =
    product?.price - product?.price * (discountPercentage / 100);
  const discountedPriceRial =
    product?.priceRial - product?.priceRial * (discountPercentage / 100);

  const buttonStyle =
    'bg-main hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mb-10 ';
  const inputStyle =
    'input input-bordered w-full max-w-xs font-custom1 mb-5 mt-3';
  // 'bg-white w-2/3 hover:bg-blue-700 text-black font-bold py-2 px-4 border border-blue-700 rounded mb-10';

  const errorStyle = 'text-main text-lg italic bg-red-100 p-2 rounded-lg';
  return (
    <div>
      <div
        className="
    flex flex-col justify-center items-center
    ">
        <h1 className="text-center text-2xl mt-10 font-bold text-main mb-10 font-custom1">
          ثبت نام جلسات خصوصی و دوره shape up
        </h1>
        {product ? (
          <>
            <div>
              <div className="text-center  text-main mb-10 font-custom1">
                <p>
                  {' '}
                  پکیج انتخابی: <span className="font-bold">{product}</span>
                </p>
                <p>
                  قیمت برای کاربران خارج از کشور:
                  <span className="font-bold">{amount} €</span>
                </p>
                <p>
                  قیمت برای کاربران داخل کشور:
                  <span className="font-bold">{amountRial} تومان</span>
                </p>
              </div>
            </div>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                password: '',
                name: '',
                email: email,
                location: locations,
              }}
              onSubmit={(payload) => {
                console.log(payload);

                setIsLoading(true); // Set isLoading to true before making the API request
                api
                  .post(`/register`, payload)
                  .then(async (res) => {
                    if (res.data.status === 'ok') {
                      setIsLoading(false);
                      const queryParams = new URLSearchParams({
                        email: email,
                        name: payload.name,
                        location: locations,
                        amount: amount,

                        amountRial: amountRial,

                        product: product,
                      });
                      window.location.href = `/loadingpayment/?${queryParams.toString()}`;
                    } else {
                      console.log(res.data);
                    }
                  })
                  .catch((e) => {
                    setIsLoading(false);
                    alert(e);
                    BugsnagPluginReact.notify(e);
                  });
              }}>
              {({
                handleChange,
                handleSubmit,
                errors,
                values,
                handleBlur,
                touched,
              }) => (
                <>
                  {/* <div className="text-center font-bold text-main mb-5 font-custom1">
                    قبلا ثبت نام کرده اید؟
                  </div>
                  <button>
                    <Link
                      to="/login"
                      className="bg-main mt-4 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mb-5">
                      ورود
                    </Link>
                  </button>
                  <div className="text-center mt-5 font-bold text-main mb-5 font-custom1">
                    یا از طریق فرم زیر ثبت نام کنید
                  </div> */}
                  <Field
                    id="Name"
                    className={inputStyle}
                    name="name"
                    onChange={handleChange('name')}
                    placeholder="نام"
                  />
                  <ErrorMessage name="name" />
                  <Field
                    id="Instagram"
                    className={inputStyle}
                    name="instagram"
                    onChange={handleChange('instagram')}
                    placeholder="آیدی اینستاگرام"
                  />
                  <Field
                    id="password"
                    className={inputStyle}
                    name="password"
                    type="password"
                    placeholder="رمز عبور"
                    onChange={handleChange('password')}
                  />
                  <ErrorMessage name="password" />
                  <select
                    onKeyUp={handleChange}
                    className={inputStyle}
                    value={locations}
                    name="location"
                    onChange={handleChange('location')}>
                    <option className="align-right" disabled selected>
                      <span className="align-left"> کشور محل سکونت </span>
                    </option>
                    {countries.map((country, i) => (
                      <option
                        className="align-left"
                        key={i}
                        value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage name="location" />

                  <Field
                    className={inputStyle}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="ایمیل"
                    value={email}
                    onChange={handleChange('email')}
                  />
                  <ErrorMessage name="email" />
                  {/* <div className="form-control">
                    <label className="label cursor-pointer">
                      <span className="label-text">کد تخفیف دارید؟</span>
                      <input
                        onChange={() => setShowDiscount(!showDiscount)}
                        type="checkbox"
                        className="checkbox"
                      />
                    </label>
                  </div> */}
                  {showDiscount && (
                    <Field
                      className={inputStyle}
                      id="discount"
                      name="discount"
                      type="text"
                      placeholder="کد تخفیف"
                      onChange={handleChange('discount')}
                    />
                  )}
                  <ErrorMessage name="discount" />

                  <button
                    className={buttonStyle}
                    onClick={() => {
                      handleSubmit();
                    }}
                    type="submit">
                    ثبت نام و پرداخت
                  </button>

                  <div className="text-center  text-main mb-5 mt-5 font-custom1">
                    در صورتی که در مرحله ثبت نام به مشکلی برخوردید لطفا از طریق
                    لینک زیر با پشتیبان در تماس باشید{' '}
                  </div>
                  <button>
                    <Link
                      to="https://t.me/fitlinezsupport"
                      className="bg-main hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mb-10">
                      تماس با پشتیبان
                    </Link>
                  </button>
                  {isLoading && <div className={errorStyle}>Loading...</div>}
                </>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div
              className="dropdown

            ">
              <label
                tabIndex={0}
                className="btn m-1 font-custom1 text-center text-2xl font-bold text-main"
                onClick={() => setDropdownOpen(!dropdownOpen)}>
                انتخاب پکیج
              </label>
              {dropdownOpen && (
                <div
                  tabIndex={0}
                  className="dropdown-content font-custom1 z-[1] card card-compact w-64 p-2 shadow bg-primary text-main  border-e-8 ">
                  {callouts.map((callout, i) => (
                    <div
                      key={i}
                      className="card-body border-b-4"
                      onClick={() => {
                        console.log(callout.id);

                        setDropdownOpen(false);
                      }}>
                      <h4 className="font-bold text-center bg-gold p-4 rounded  cursor-pointer">
                        {callout.name}
                        <p dir="ltr" className="card-text ">
                          {callout.nameEn}
                        </p>
                      </h4>

                      <p>
                        <div>قیمت برای کاربران خارج از کشور</div>
                        <div>
                          {callout.price} {callout.currency}
                        </div>
                      </p>
                      <p>
                        قیمت برای کاربران داخل کشور {callout.priceRialSign}{' '}
                        تومان
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignUp;
