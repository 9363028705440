import React, { useState } from 'react';

const content = [
  'شرکت کنندگان هر هفته با مبحثی علمی و جدید در زمینه ی تغذیه آشنا می‌ شوند و در طول هفته با توجه به اهداف تعیین شده روی موضوع خاص تمرکز می کنند و خود را به چالش می کشند. آموزش در کنار تمرین مستمر به شرکت کننده ها کمک می کند که عادات جدید را جزئی از لایف استایل خود کنند.',
  ' پایه ای تغذیه بصورت علمی و با ارائه فکت ها آموزش داده می شوند، در نتیجه ذهن جستجوگر انسان راحت تر مطالب تئوری را می پذیرد و ذهنیت افراد در خصوص تغذیه تغییر خواهد کرد. ',
  'با گذر زمان، شرکت کنندگان با برداشتن قدم های کوچک و تمرین اجرای آموخته ها، بدون سختی و عذاب کشیدن در هر هفته پیشرفت قابل توجهی خواهند داشت و می توانند انتظار داشته باشند که تغییرات ظاهری هم برایشان اتفاق بیفتد.',

  'اهمیت موضوع ورزش و فعالیت، هر هفته  مورد بحث قرار میگیرد و شرکت کنندگان ملزم به رسیدن به اهداف در نظر گرفته شده توسط مربی هستند.',
];

function CtaSection() {
  const [visible, setVisible] = useState(false);
  return (
    <section>
      <div className="mx-auto max-w-screen-2xl px-2 sm:px-2  text-main bg-main">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
          <div className="bg-blue-600 p-2 pt-0">
            <div className="text-center">
              <div
                className="
          flex flex-col items-center
          ">
                <div
                  className="flex justify-center rounded rounded-lg border border-1 mt-2 mb-2 shadow-lg
               ">
                  <button
                    onClick={() => setVisible(!visible)}
                    className="bg-main text-white font-bold text-md py-2 px-4  justify-center hover:bg-white  hover:border-main border-2 border-main animate-bounce rounded-lg animate-text bg-gradient-to-r from-white via-white to-gold bg-clip-text text-transparent text-lg  font-black
              ">
                    در جلسات خصوصی کوچینگ تغذیه جه خواهد گذشت؟
                  </button>
                </div>
                {/* <img src={TimeLLine} alt="timeline" className="" /> */}
              </div>
              {visible && (
                <div className="p-4 bg-gray-100 font-custom1 text-xs leading-7 text-justify rounded-md">
                  {content.map((item, index) => (
                    <li
                      className="flex items-start

                  sm:list-disc sm:ml-5 sm:mr-5 sm:mt-2 sm:mb-2 sm:text-sm sm:leading-5 sm:text-gray-700 sm:dark:text-gray-300
                  ">
                      <svg
                        className="w-3.5 h-3.5 mb-2 ml-2 text-green-500 dark:text-green-400 flex-shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <p className="">{item}</p>
                    </li>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CtaSection;
