import { useEffect, useState } from 'react';
import SubmitButton from '../../../component/submitButton';
import TextInputs from '../../../component/FormInputs/TextInputs';
import Selects from '../../../component/FormInputs/Selects';
import { Textarea } from 'flowbite-react';
import api from '../../../api/api';

export default function BasicForm(props) {
  const [userId, setUserId] = useState('');
  const [status, setStatus] = useState('idle');
  const [name, setName] = useState('');
  const [userData, setUserData] = useState({
    userId: '',
    weight: '',
    height: '',
    age: '',
    gender: '',
    illness: '',
    medication: '',
    pain: '',
    reason: '',
    calorie: '',
    calories: '',
    macro: '',
    lastTimeDiet: '',
    currentDiet: '',
    exerciseType: '',
    placeOfExercise: '',
    comment: '',
  });
  console.log(userData);
  // On component mount, check if user data exists
  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);
      console.log('parsedCredentials', parsedCredentials);
      setUserId(parsedCredentials.userId);
      setUserData((prevData) => ({
        ...prevData,
        userId: parsedCredentials.userId,
      })); // Update userData with userId
      setName(parsedCredentials.name);
      console.log('parsedCredentials.id', parsedCredentials.userId);
      setStatus('loading');

      // Send a GET request to check if the user already has assessment data
      api
        .get(`/ShapeUpAssessment/${parsedCredentials.userId}`)
        .then((res) => {
          console.log('res', res);
          if (res.data && res.data.data.length > 0) {
            setUserData(res.data.data[0]); // Assuming res.data contains the user assessment data
          }
          setStatus('loaded');
        })
        .catch((error) => {
          console.log('No existing data found', error);
          setStatus('idle');
        });
    }
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setUserData({ ...userData, [id]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');

    try {
      const res = await api.post(`/ShapeUpAssessment`, userData);
      console.log(res);
      if (res) setStatus('success');
      alert('اطلاعات با موفقیت ثبت شد');
      // window.location.href = '/dashboard';
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  };

  return (
    <>
      <div className="font-custom1 flex flex-col mt-5 items-center mx-3">
        <TextInputs
          label="وزن فعلی"
          id="weight"
          type="number"
          onChange={handleChange}
          value={userData.weight || ''}
          placeholder="وزن فعلی خود را به کیلو گرم وارد کنید"
        />
        <TextInputs
          label="قد"
          id="height"
          type="number"
          onChange={handleChange}
          value={userData.height || ''}
          placeholder="قد خود را به سانتی متر وارد کنید"
        />
        <TextInputs
          label="سن"
          id="age"
          type="number"
          onChange={handleChange}
          value={userData.age || ''}
          placeholder="سن خود را وارد کنید"
        />
        <Selects
          label="جنسیت"
          id="gender"
          onChange={handleChange}
          value={userData.gender || ''}
          options={['انتخاب کنید', 'آقا', 'خانم']}
        />
        <TextInputs
          label="بیماری خاص"
          id="illness"
          type="text"
          onChange={handleChange}
          value={userData.illness || ''}
          placeholder="در صورتی که بیماری خاصی دارید اینجا بنویسید"
        />
        <TextInputs
          label="داروی خاص"
          id="medication"
          type="text"
          onChange={handleChange}
          value={userData.medication || ''}
          placeholder="در صورتی که داروی خاصی مصرف میکنید اینجا بنویسید"
        />
        <TextInputs
          label="احساس درد"
          id="pain"
          type="text"
          onChange={handleChange}
          value={userData.pain || ''}
          placeholder="در کدام قسمت از بدن خود درد احساس میکنید؟"
        />
        <Selects
          label="دلیل برای شرکت در دوره"
          id="reason"
          onChange={handleChange}
          value={userData.reason || ''}
          options={[
            'انتخاب کنید',
            'تغییر سبک زندگی و کاهش وزن',
            'تغییر سبک زندگی و افزایش وزن',
            'تغییر سبک زندگی و اصلاح تغذیه',
          ]}
        />
        <hr />
        <Selects
          label="میدونی کالری چیه؟"
          id="calorie"
          onChange={handleChange}
          value={userData.calorie || ''}
          options={['انتخاب کنید', 'نمیدونم', 'میدونم']}
        />
        <Selects
          label="میزان کالری مصرفی"
          id="calories"
          onChange={handleChange}
          value={userData.calories || ''}
          options={[
            'انتخاب کنید',
            'نمیدونم',
            'زیر ۱۰۰۰ کالری',
            'بین ۱۰۰۰ تا ۱۵۰۰ کالری',
            '۱۵۰۰ تا ۲۰۰۰ کالری در روز',
            'بیشتر از ۲۰۰۰ کالری در روز',
          ]}
        />
        <Selects
          label="میدونی ماکرو جیه؟"
          id="macro"
          onChange={handleChange}
          value={userData.macro || ''}
          options={['نمیدونم', 'میدونم']}
        />
        <Selects
          label="آخرین باری که رژیم گرفتی؟"
          id="lastTimeDiet"
          onChange={handleChange}
          value={userData.lastTimeDiet || ''}
          options={[
            'انتخاب کنید',
            '۱ ماه پیش',
            '۳ ماه پیش',
            '۶ ماه پیش',
            'همیشه در حال رژیم گرفتن هستم!',
          ]}
        />
        <TextInputs
          label="رژیم فعلی شما"
          id="currentDiet"
          type="text"
          onChange={handleChange}
          value={userData.currentDiet || ''}
          placeholder="کانادایی یا لوکرب"
        />
        <Selects
          label="چه ورزشی انجام میدی"
          id="exerciseType"
          onChange={handleChange}
          value={userData.exerciseType || ''}
          options={[
            'انتخاب کنید',
            'ورزش نمیکنم',
            'ورزش قدرتی',
            'شنا',
            'یوگا',
            'ورزش رزمی و یا بوکس',
            'کشتی',
            'دو و میدانی',
            'سایر ورزشها',
          ]}
        />
        <Selects
          label="معمولا کجا ورزش میکنی؟"
          id="placeOfExercise"
          onChange={handleChange}
          value={userData.placeOfExercise || ''}
          options={[
            'انتخاب کنید',
            'ورزش نمیکنم',
            'خانه',
            'باشگاه',
            'هم خانه و هم باشگاه',
          ]}
        />
        <Textarea
          className="w-full lg:w-1/3 mt-4"
          onChange={handleChange}
          id="comment"
          value={userData.comment || ''}
          placeholder="سابقه ورزش یا پیاده روی خود را اینجا بنویسید"
          required
          rows={4}
        />
        <SubmitButton handleSubmit={handleSubmit} status={status} />
      </div>
    </>
  );
}
