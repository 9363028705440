import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import api from '../../api/api';

const LoadingPayment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const locations = queryParams.get('location');
  const amount = queryParams.get('amount');
  const amountRial = queryParams.get('amountRial');
  const name = queryParams.get('name');
  const [paymentUrl, setPaymentUrl] = useState('');
  const product = queryParams.get('product');
  const [isLoading, setIsLoading] = useState(false); // Set isLoading to true before making the API request

  console.log(email, locations, amount, amountRial, product, name);

  // Flag variable to track if the request has already been sent
  let requestSent = false;

  const initiatePayment = async () => {
    // Check if the request has already been sent
    if (!requestSent) {
      setIsLoading(true);
      requestSent = true;

      try {
        if (locations === 'Iran') {
          const response = await api.post(`/zarinpal/rial`, {
            amount: amountRial,
            email: email,
            product: product,
            name: name,
          });

          window.location.href = response.data.url;
          setPaymentUrl(response.data.url);
        } else {
          const response = await api.post(`/stripeworkshop`, {
            amount: amount,
            email: email,
            product: product,
            name: name,
          });

          window.location.href = response.data.url;
          setPaymentUrl(response.data.url);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    initiatePayment();
  }, []);

  return (
    <div className="loading-payment flex justify-center items-center">
      <button
        className="btn btn-main mt-11 bg-main hover:bg-main-dark w-1/4 rounded rounded-xl h-20 font-bold btn-sx text-center text-white text-xl"
        onClick={initiatePayment}>
        انتقال به درگاه پرداخت
      </button>
    </div>
  );
};

export default LoadingPayment;
