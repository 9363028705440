import React from 'react';
import { useLocation } from 'react-router-dom';

const PaymentUnSuccess = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // Get the email and name parameters
  const email = params.get('email');

  return (
    <>
      {email ? (
        <div className="bg-main min-h-screen flex flex-col justify-center items-center">
          <h1 className="text-3xl font-bold mb-6 font-custom1">
            پرداخت شما با مشکل روبرو شد.
          </h1>
          <p className="text-lg text-gray-200 mb-12 font-custom1">
            لطغا مجددا تلاش کنید.
          </p>
          <a
            href="/"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            برگشت به صفحه اصلی
          </a>
          <a
            href="/login"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            ورود به حساب کاربری
          </a>
        </div>
      ) : // <Navigate to="/" />
      null}
    </>
  );
};

export default PaymentUnSuccess;
