import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../img/FITLINEZ.svg';
import { useTranslation } from 'react-i18next';
import Breadcrumb from './breadcrumb';
import { useAuth } from '../api/authContxt';
import Gravatar from 'react-gravatar';
import { useDispatch } from 'react-redux';
import { logout } from '../store/userSlice';

export default function Navbars() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const [userId, setUserId] = useState('');
  const [userProducts, setUserProducts] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);
      setUserDetails(parsedCredentials);
      setUserId(parsedCredentials.id);
      setUserProducts(parsedCredentials?.userProduct);
      setName(parsedCredentials?.name);
      setDataLoaded(true);
    }
  }, []);

  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const navigation = [
    { name: `${t('home')}`, href: '/', current: true, public: true },
    // {
    //   name: `${t('services')}`,
    //   href: '/services',
    //   current: false,
    //   public: true,
    // },
    // {
    //   name: `${t('application')}`,
    //   href: '/download',
    //   current: false,
    //   public: true,
    // },
    { name: `${t('contact')}`, href: '/contact', current: false, public: true },
    {
      name: `${t('calorie')}`,
      href: '/calculator',
      current: false,
      public: true,
    },

    // {
    //   name: `${t('report')}`,
    //   href: '/dashboard/report',
    //   current: true,
    //   public: false,
    // },
  ];

  const changeLanguage = (language) => {
    console.log(language);
    i18n.changeLanguage(language);
    setCurrentLanguage(language); // Update the current language
  };

  const [currentLanguage, setCurrentLanguage] = useState('en'); // Add this line

  return (
    <>
      <div className="navbar bg-base-100 sticky top-0 z-40">
        {/* add burger menu */}

        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" fill="none"></rect>
                <path
                  d="M4 6H20M4 12H20M4 18H11"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>
              </svg>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
              {navigation.map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.href}
                    activeClassName="active"
                    exact={item.exact}>
                    {item.name}
                  </NavLink>
                </li>
              ))}
              <li>
                {!isLoggedIn ? (
                  <a href="/login">{t('login')}</a>
                ) : (
                  <a href="/dashboard">{t('dashboard')}</a>
                )}
              </li>
              {isLoggedIn && (
                <>
                  {/* <li>
                    <a
                      onClick={() => {
                        localStorage.removeItem('credentials');
                        setIsLoggedIn(false);
                        setUserId(null);
                        dispatch(logout());
                      }}>
                      {t('logout')}
                    </a>
                  </li> */}
                  <li>
                    <NavLink to="dashboard/basicdata">پروفایل کاربری</NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
          <Link to="/">
            <span className="text-lg font-bold text-main">{t('brand')}</span>
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            {navigation.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  activeClassName="active"
                  exact={item.exact}>
                  {item.name}
                </NavLink>
              </li>
            ))}
            <li>
              {!isLoggedIn ? (
                <a href="/login">{t('login')}</a>
              ) : (
                <a href="/dashboard">{t('dashboard')}</a>
              )}
            </li>
            {isLoggedIn && (
              <>
                <li>
                  <NavLink to="dashboard/basicdata">پروفایل کاربری</NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="navbar-end">
          {isLoggedIn ? (
            <div className="avatar">
              {/* <div
                onClick={() => {
                  window.open('/dashboard/profile', '_self');
                }}
                className="w-18 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                {
                  <Gravatar
                    className="rounded-full"
                    size={40}
                    rating="pg"
                    default="monsterid"
                    email={email}
                  />
                }
              </div> */}
            </div>
          ) : null}
        </div>
      </div>
      <Breadcrumb />
    </>
  );
}
