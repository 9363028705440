import React, { useState } from 'react';
import logo from '../../../img/logo.png';
const apps = [
  {
    id: 1,
    name: 'myfitness',
    androidLink:
      'https://play.google.com/store/apps/details?id=com.myfitnesspal.android&hl=en_US&gl=US',
    iosLink: 'https://apps.apple.com/us/app/myfitnesspal/id341232718',
    bazzar: 'https://cafebazaar.ir/app/com.myfitnesspal.android/?l=fa',
    applogo:
      'https://png2.cleanpng.com/sh/934539e6d2e05cb24669f79040f14835/L0KzQYm3V8EzN6FAe5H0aYP2gLBuTf16bpp5htd8c4DkfH7rggRiNZN3fdNsaD3mf773lgRmel54fdVAcnn3iX78kBVzNZVmRd9ELXbshLBskCMueJJxRadrNEbnc7LogfE0a5U4Rqc5MUK4Qoe6UcU0OWQ8SKgBNka8R4S1kP5o/kisspng-myfitnesspal-data-breach-computer-security-user-da-my-fitness-pal-5b46dcaaaa3cd3.5012526315313706666973.png',
  },
  {
    id: 2,
    name: 'pacer',
    androidLink:
      'https://play.google.com/store/apps/details?id=cc.pacer.androidapp&hl=en_US&gl=US',
    iosLink:
      'https://apps.apple.com/us/app/pacer-pedometer-step-tracker/id600446812',
    bazzar: 'https://cafebazaar.ir/app/cc.pacer.androidapp/?l=fa',
    applogo:
      'https://s3-us-west-2.amazonaws.com/issuewireassets/primg/24633/thumb_pacer-pedometer-fitness-tracking-app-offers-opportunity-to-create-branded-virtual-event-packages28.png',
  },
  {
    id: 3,
    name: 'Fitlinez',
    androidLink:
      'https://play.google.com/store/apps/details?id=com.fitlinez.eu&hl=en&gl=US',
    iosLink: 'https://apps.apple.com/us/app/fitlinez-beta/id6443489365',
    applogo: logo,
  },
];

const Android = () => {
  return (
    <div className="flex justify-center	">
      <ul
        role="list"
        className="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
        {apps.map((app) => {
          return (
            <li
              key={app.id}
              className="flex justify-between gap-x-6 py-5 pb-3 sm:pb-4">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 
                    "
                    src={app.applogo}
                    alt="Neil image"
                  />
                </div>
                <div className="flex flex-col">
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white font-bold">
                    {app.name}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                    {app.description}
                  </p>
                </div>
                <div className="flex gap-x-6">
                  {app.androidLink && (
                    <a
                      href={app.androidLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm font-medium text-gray-900 dark:text-white">
                      دانلود از پلی استور
                    </a>
                  )}
                  {app.bazzar && (
                    <a
                      href={app.bazzar}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm font-medium text-gray-900 dark:text-white">
                      دانلود از بازار
                    </a>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Ios = () => {
  return (
    <div className="flex justify-center	">
      <ul
        role="list"
        className="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
        {apps.map((app) => {
          return (
            <li
              key={app.id}
              className="flex justify-between gap-x-6 py-5 pb-3 sm:pb-4">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 
                  "
                    src={app.applogo}
                    alt="Neil image"
                  />
                </div>
                <div className="flex flex-col">
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                    {app.name}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                    {app.description}
                  </p>
                </div>
                <div className="flex gap-x-6">
                  {app.iosLink && (
                    <a
                      href={app.iosLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm font-medium text-gray-900 dark:text-white">
                      دانلود از اپ استور
                    </a>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

function ToolsIndex() {
  const [phoneType, setPhoneType] = useState(null);

  return (
    <div dir="rtl">
      <div className="flex justify-center	">
        <div className="card w-96 bg-base-100 shadow-xl">
          <figure>
            <img
              src="https://buildfire.com/wp-content/uploads/2017/10/ios-vs-android-users.jpg"
              alt="Shoes"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">انتخاب نوع گوشی</h2>
            <p>
              برای مشاهده لینک دانلود اپهای مربوط به دوره، لطفا نوع گوشی خودتون
              رو انتخاب کنید
            </p>
            <div className="card-actions justify-end">
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">اندروید</span>
                  <input
                    onChange={() => setPhoneType('android')}
                    type="radio"
                    name="radio-10"
                    className="radio checked:bg-red-500"
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">آیفون </span>
                  <input
                    onChange={() => setPhoneType('ios')}
                    type="radio"
                    name="radio-10"
                    className="radio checked:bg-blue-500"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {phoneType === 'android' && <Android />}
      {phoneType === 'ios' && <Ios />}
    </div>
  );
}

export default ToolsIndex;
