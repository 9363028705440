import { useContext, useEffect, useState } from 'react';
import Front from '../../../img/front.png';
import Back from '../../../img/back.png';
import Side from '../../../img/side.png';
import axios from 'axios';
import Modal from '../../../component/Modal.js';
import { Navigate, useLocation } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import api from '../../../api/api.js';
import UserContext from '../../../context/usercontext.js';

const BeforAfter = () => {
  const location = useLocation();
  const [status, setStatus] = useState('idle'); // 'idle' | 'loading' | 'success' | 'error'
  const [userId, setUserId] = useState(null);
  const { userDetails } = useContext(UserContext);
  console.log(userDetails);
  // useEffect(() => {
  //   const { userId } = location.state || {};
  //   if (userId) setUserId(userId);
  // }, [location.state]);

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      try {
        const parsedCredentials = JSON.parse(credentials);
        console.log(parsedCredentials.userId);
        setUserId(parsedCredentials.userId);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const numberOfInputs = [
    {
      id: 1,
      name: 'عکس از روبرو',
      value: 'Front',
      img: Front,
    },
    {
      id: 2,
      name: 'عکس از پشت',
      value: 'Back',
      img: Back,
    },
    {
      id: 3,
      name: 'عکس از یک سمت',
      value: 'Side',
      img: Side,
    },
  ];

  const [imageUrls, setImageUrls] = useState([]);

  const [percent, setPercent] = useState(0);
  const [message, setMessage] = useState('');
  const [uploadQueue, setUploadQueue] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleUploadImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('image', file); // Change 'file' to 'image' to match the backend

    try {
      const response = await api.post(
        '/imageUploader/single', // Update to your Express server endpoint
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);

            if (percent < 100) {
              setPercent(percent);
            }
          },
        }
      );
      console.log(response.data);
      setImageUrls([...imageUrls, response.data.url]); // Update to handle single URL
      setPercent(100);
      setStatus('success');
      setUploadQueue((prev) => prev.slice(1)); // Remove the uploaded file from the queue
      // Navigate to the next page or perform other actions
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');

    try {
      // Send request data to backend endpoint
      const response = await api.post('/report/imagereport', {
        userId: userId,
        images: imageUrls,
      });
      setStatus('success');
      setMessage('تصاویر شما با موفقیت ثبت شدند');
      alert('تصاویر شما با موفقیت ثبت شدند');
      window.location.href = '/dashboard/beforeafterhistory';
    } catch (error) {
      setStatus('error');
      setMessage('Upload failed: ' + error.message);

      alert('error in uploading to database');
      Bugsnag.notify('error in uploading to database');
    } finally {
      setPercent(0);
      setStatus('idle');
    }
  };

  return (
    <>
      {userId !== null ? (
        <div className="w-full mt-10 my-5  rounded-lg">
          <form
            noValidate
            className="w-full items-center justify-center mt-2 rounded-lg"
            onSubmit={handleSubmit}>
            <div className="justify-between rounded-t-lg bg-blue-500 flex items-center">
              <label className="text-base text-right text-white mx-4 my-2">
                تصاویر قبل و بعد{' '}
                <progress
                  className="progress w-56"
                  value={percent}
                  max="100"></progress>
              </label>
            </div>

            {status === 'loading' && (
              <div
                role="status"
                className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                <svg
                  className="animate-spin -ml-1 mr-3 h-20 w-20 text-main"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24">
                  <circle
                    className="opacity-50"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span className="text-main text-lg font-bold bg-white px-4 py-2 rounded-lg">
                  لطفا تا بارگذاری عکس ها صبر کنید
                </span>
              </div>
            )}
            <div className="grid md:grid-cols-3">
              {numberOfInputs.map((item, index) => (
                <div key={item.id} className="w-4/5 m-auto md:w-2/3">
                  <div className="justify-between rounded-t-lg flex items-center">
                    <label className="text-base text-right text-main mx-4 my-2">
                      {item.name}
                    </label>
                  </div>

                  <div className="grid md:grid-cols-1 gap-4 rounded-none">
                    <div className="flex flex-col items-center justify-center">
                      <label className="flex flex-col rounded-lg p-10 group text-center">
                        <div className="text-center">
                          <div className="max-h-48 mt-10">
                            {imageUrls[index] ? (
                              <div className="w-48 h-48 mt-2">
                                <img
                                  className="rounded-lg object-fit w-60 h-60"
                                  src={imageUrls[index]}
                                  alt={`uploaded ${index}`}
                                />
                              </div>
                            ) : (
                              <div className="w-48 h-48 mt-2">
                                <img
                                  className="rounded-lg object-contain w-60 h-60"
                                  src={item.img}
                                  alt={`default ${index}`}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <input
                          type="file"
                          onChange={(e) => handleUploadImage(e.target.files[0])}
                          accept="image/*"
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <>
              {status === 'loading' && (
                <div className="weekly-report__progress">
                  <p className="text-main">در حال ذخیره...</p>
                  <progress
                    className="weekly-report__progress-bar"
                    value={percent}
                    max="100"
                  />
                </div>
              )}
              <div
                className="flex bg-blue-400 text-center text-white text-sm px-1 py-1"
                role="alert">
                <p>حداکثر حجم هر فایل ۵ مگابایت است</p>
              </div>
              <button
                type="submit"
                disabled={status === 'loading'}
                className={`w-full border-transparent rounded-b-lg py-4 text-sm font-custom1 text-white ${
                  setStatus === 'loading' ? 'bg-gray-500' : 'bg-main'
                } text-center`}>
                ارسال تصاویر
              </button>
            </>
          </form>

          {status === 'error' && (
            <div className="flex items-center justify-center">
              <span className="text-red-500">{message}</span>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <span>شما اجازه دسترسی به این صفحه را ندارید</span>
        </div>
      )}
    </>
  );
};

export default BeforAfter;
