import axios from 'axios';
import { useEffect, useState } from 'react';
import Modal from '../../../component/Modal';
import { useLocation } from 'react-router-dom';
import SubmitButton from '../../../component/submitButton';
import api from '../../../api/api';

export default function ExerciseForm(props) {
  const location = useLocation();
  const { userExerciseData } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [isWorkOut, setIsWorkOut] = useState(false);
  const [isStrengthTraining, setIsStrengthTraining] = useState(false);
  const [done, setDone] = useState(false);
  const [status, setStatus] = useState('idle');
  const [workoutPlace, setWorkoutPlace] = useState(
    userExerciseData?.workoutPlace
  );
  const [dayOfCardio, setDayOfCardio] = useState(userExerciseData?.dayOfCardio);
  const [dayOfStrengthTraining, setDayOfStrengthTraining] = useState(
    userExerciseData?.dayOfStrengthTraining
  );
  const [workOutExperience, setWorkOutExperience] = useState(
    userExerciseData?.workOutExperience
  );
  const [userWorkOutData, setUserWorkOutData] = useState({
    workoutPlace,
    dayOfCardio,
    dayOfStrengthTraining,
    workOutExperience,
  });

  const [userId, setUserId] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);

      setUserId(parsedCredentials.id);
    }
  }, []);
  const handlechange = (e) => {
    const { name, value } = e.target;
    setUserWorkOutData({ ...userWorkOutData, [name]: value });
  };

  const handleSubmit = async (e) => {
    setStatus('loading');
    e.preventDefault();
    setIsLoading(true);

    setTimeout(async () => {
      try {
        const res = await api.put(
          `/api/updateWorkOut/${userId}`,
          userWorkOutData
        );
        setStatus('success');
        setMessage(res.status);
        window.location.href = '/dashboard';
      } catch (error) {
        setStatus('error');
      }
    }, 2000); // 5 seconds delay
  };

  return (
    <>
      <div className="font-custom1 flex flex-row mt-20">
        <div className="basis-1/4"></div>
        <div className="basis-2/4">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-main">
                اطلاعات ورزشی
              </h2>
            </div>

            <div className="border-b border-gray-900/10 pb-12">
              <div className="sm:col-span-6">
                <div className="flex items-center gap-x-3">
                  <legend className="text-sm font-semibold leading-4 text-main text-right">
                    آیا ورزش میکنید؟
                  </legend>
                  <input
                    defaultChecked={isWorkOut ? true : false}
                    id="push-email"
                    name="workOut"
                    type="checkbox"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => setIsWorkOut(!isWorkOut)}
                  />
                </div>
              </div>
              {isWorkOut ? (
                <div className="mt-10 grid grid-cols-1 gap-x-12 gap-y-12 sm:grid-cols-1">
                  <fieldset>
                    <legend className="text-sm font-semibold leading-4 text-main text-right">
                      چه نوع ورزشی انجام میدهید؟
                    </legend>
                    <div className="mt-2">
                      <div className="flex items-center gap-x-6">
                        <input
                          id="push-everything"
                          name="excersiceType"
                          defaultValue={userExerciseData?.excersiceType}
                          placeholder=" مثلا پیاده روی یا ورزش قدرتی"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={handlechange}
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend className="text-sm font-semibold leading-4 text-main text-right">
                      آیا ورزش قدرتی انجام میدهید؟{' '}
                    </legend>

                    <div className="mt-6 space-y-6">
                      <div className="flex items-center gap-x-3">
                        <input
                          id="do-workout"
                          name="doyouworkout"
                          type="radio"
                          defaultChecked={isStrengthTraining ? true : false}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={() => {
                            setIsStrengthTraining(true);
                          }}
                        />
                        <label
                          htmlFor="push-everything"
                          className="block text-sm font-medium leading-6 text-main">
                          بله{' '}
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="dont-workout"
                          defaultChecked={isStrengthTraining ? false : true}
                          name="doyouworkout"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={() => {
                            setIsStrengthTraining(false);
                          }}
                        />
                        <label
                          htmlFor="push-email"
                          className="block text-sm font-medium leading-6 text-main">
                          خیر{' '}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  {isStrengthTraining && (
                    <fieldset>
                      <legend className="text-sm font-semibold leading-4 text-main text-right">
                        چند روز در هفته ورزش قدرتی انجام میدهید؟
                      </legend>
                      <div className="mt-2">
                        <div className="flex items-center gap-x-6">
                          <select
                            id="dayOfStrengthTraining"
                            defaultValue={
                              userExerciseData?.dayOfStrengthTraining
                            }
                            name="dayOfStrengthTraining"
                            onChange={handlechange}
                            className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option>انتخاب کنید</option>
                            <option value="1">۱ روز</option>
                            <option value="2">۲ روز</option>
                            <option value="3">۳ روز</option>
                            <option value="4">۴ روز</option>
                            <option value="5">۵ روز</option>
                            <option value="+5">بیشتر از ۵ روز </option>
                          </select>
                        </div>
                      </div>
                    </fieldset>
                  )}
                  <fieldset>
                    <legend className="text-sm font-semibold leading-4 text-main text-right">
                      در خانه ورزش میکنید یا در باشگاه؟{' '}
                    </legend>

                    <div className="flex items-center gap-x-3">
                      <select
                        id="workoutPlace"
                        name="workoutPlace"
                        defaultValue={userExerciseData?.workoutPlace}
                        onChange={handlechange}
                        className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option>انتخاب کنید</option>
                        <option value="home">خانه</option>
                        <option value="gym">باشگاه</option>
                      </select>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend className="text-sm font-semibold leading-4 text-main text-right">
                      چند روز در هفته کاردیو انجام میدهید؟
                    </legend>
                    <div className="mt-2">
                      <div className="flex items-center gap-x-6">
                        <select
                          id="dayOfCardio"
                          name="dayOfCardio"
                          defaultValue={userExerciseData?.dayOfCardio}
                          onChange={handlechange}
                          className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option>انتخاب کنید</option>
                          <option value="1">۱ روز</option>
                          <option value="2">۲ روز</option>
                          <option value="3">۳ روز</option>
                          <option value="4">۴ روز</option>
                          <option value="5">۵ روز</option>
                          <option value="+5">بیشتر از ۵ روز </option>
                        </select>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend className="text-sm font-semibold leading-4 text-main text-right">
                      سابقه تمرینی خود را انتخاب کنید{' '}
                    </legend>
                    <div className="mt-2">
                      <div className="flex items-center gap-x-6">
                        <select
                          id="workOutExperience"
                          name="workOutExperience"
                          defaultValue={userExerciseData?.workOutExperience}
                          onChange={handlechange}
                          className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option>انتخاب کنید</option>
                          <option value="one year">کمتر از یکسال</option>
                          <option value=" one to three">یکسال تا سه سال</option>
                          <option value="three to five">
                            سه سال تا پنج سال
                          </option>
                          <option value="more than five"> بیش از ۵ سال </option>
                        </select>
                      </div>
                    </div>
                  </fieldset>
                </div>
              ) : (
                <div className="mt-10 grid grid-cols-1 gap-x-12 gap-y-12 sm:grid-cols-1">
                  <fieldset>
                    <legend className="text-sm font-semibold leading-4 text-main">
                      دلیل ورزش نکردنت چیه؟
                    </legend>
                    <div className="mt-2">
                      <input
                        onChange={handlechange}
                        id="reasonToNotWorkout"
                        name="reasonToNotWorkout"
                        placeholder="مثلا وقت ندارم یا ورزش رو دوست ندارم"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-main text-center text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </fieldset>
                </div>
              )}
            </div>
          </div>
          <div className="basis-1/4"></div>

          <SubmitButton handleSubmit={handleSubmit} status={status} />
          {isSubmitted && (
            <Modal
              title="پیام"
              message={message === 200 ? 'اطلاعات با موفقیت ثبت شد' : 'خطا'}
              buttonTitle="باشه"
              onClick={() => {
                window.location.href = '/dashboard';
              }}
            />
          )}
        </div>
        <div className="flex flex-col "></div>
      </div>
    </>
  );
}
