import axios from 'axios';
import { useEffect, useState } from 'react';
import activities from '../../data/activities.js';
import Modal from '../../component/Modal.js';
import { Helmet } from 'react-helmet';
import Result from './result.js';
import Calories from './calories.js';

const Calculator = () => {
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState(null);
  const [weight, setWeight] = useState(null);
  const [height, setHeight] = useState(null);
  const [activityLevel, setActivityLevel] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [maintainWeightCalories, setMaintainWeightCalories] = useState(null);
  const [mildWeightLossCalories, setMildWeightLossCalories] = useState(null);
  const [bulkWeightCalories, setBulkWeightCalories] = useState(null);
  const [weightLossCalories, setWeightLossCalories] = useState(null);
  const [extremeWeightLossCalories, setExtremeWeightLossCalories] =
    useState(null);

  const calculateCalories = () => {
    let bmr;
    if (gender === 'male') {
      bmr = 10 * weight + 6.25 * height - 5 * age + 5;
    } else {
      bmr = 10 * weight + 6.25 * height - 5 * age - 161;
    }
    return bmr * activityLevel;
  };

  const handleInput = (event) => {
    switch (event.target.name) {
      case 'age':
        setAge(event.target.value);
        break;
      case 'weight':
        setWeight(event.target.value);
        break;
      case 'height':
        setHeight(event.target.value);
        break;
      case 'gender':
        setGender(event.target.value);
        break;
      case 'activity':
        setActivityLevel(event.target.value);
        break;
      default:
        break;
    }
  };

  const [macros, setMacros] = useState();

  const handleSubmitUserInfo = (event) => {
    //check if all fields are filled
    if (age && weight && height && gender && activityLevel) {
      setIsEnabled(true);
      event.preventDefault();
      const maintainWeightCalories = calculateCalories();
      setMaintainWeightCalories(maintainWeightCalories);
      setMildWeightLossCalories(maintainWeightCalories * 0.89); // 89% of maintenance
      setWeightLossCalories(maintainWeightCalories * 0.81); // 79% of maintenance
      setExtremeWeightLossCalories(maintainWeightCalories * 0.6); // 58% of maintenance
      setBulkWeightCalories(maintainWeightCalories * 1.1); // 110% of maintenance

      console.log(
        `Calculated calories to maintain weight: ${maintainWeightCalories}`
      );
      console.log(
        `Calculated calories for mild weight loss (0.25 kg/week): ${mildWeightLossCalories}`
      );
      console.log(
        `Calculated calories for weight loss (0.5 kg/week): ${weightLossCalories}`
      );
      console.log(
        `Calculated calories for extreme weight loss (1 kg/week): ${extremeWeightLossCalories}`
      );

      // Here you can handle the calculated calories as you need
    } else {
      alert('لطفا تمامی فیلدها را پر کنید');
    }
  };

  const inputStyle =
    'mt-2 block w-full rounded-md border-0 py-1.5 text-main shadow-sm ring-1 ring-inset ring-main placeholder:text-main focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
  const selectStyle =
    'mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-main shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
  const lableStyle = 'block text-sm font-medium leading-6 text-main';
  const divStyle = 'col-span-6 sm:col-span-3 lg:col-span-3';
  const buttonStyle =
    'bg-main hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mb-10';
  const buttonDisableStyle =
    'bg-gray-500  text-gray-300 py-2 px-4 border border-blue-700 rounded mb-10';
  return (
    <div className="mt-10 sm:mt-0">
      <Helmet
        title="محاسبه‌گر کالری مورد نیاز"
        meta={[
          {
            name: 'description',
            content: 'محاسبه‌گر کالری مورد نیاز',
          },
        ]}
      />

      {/* {showModal && (
        <Modal
          {...modalProps}
          onClose={() => setShowModal(false)}
          onClick={() => {
            window.location.href = '/';
          }}
        />
      )} */}
      <div className="md:grid md:grid-cols-2 md:gap-6 Custom-1">
        <div className="m-auto lg:w-1/2">
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-6 text-main mt-20">
              محاسبه‌گر کالری مورد نیاز
            </h3>
            <p className="mt-1 text-sm text-main">
              لطفا تمامی فیلدها را پر کنید
            </p>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form>
            <div className="overflow-hidden shadow sm:rounded-md lg:w-1/2 m-auto">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-2 gap-6">
                  <div className={divStyle}>
                    <label for="first-name" className={lableStyle}>
                      سن
                    </label>

                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="age"
                      id="first-name"
                      autocomplete="given-name"
                      className={inputStyle}
                    />
                  </div>
                  <div className={divStyle}>
                    <label className={lableStyle}>وزن</label>
                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="weight"
                      id="first-name"
                      autocomplete="given-name"
                      className={inputStyle}
                    />
                  </div>

                  <div className={divStyle}>
                    <label className={lableStyle}>قد</label>
                    <input
                      required
                      onChange={handleInput}
                      type="number"
                      name="height"
                      id="last-name"
                      autocomplete="family-name"
                      className={inputStyle}
                    />
                  </div>

                  <div className={divStyle}>
                    <label className={lableStyle}>جنسیت</label>
                    <select
                      required
                      onChange={handleInput}
                      id="gender"
                      name="gender"
                      autocomplete="country-name"
                      className={selectStyle}>
                      <option value="none">انتخاب کنید</option>
                      <option value="female">خانم</option>
                      <option value="male">آقا</option>
                    </select>
                  </div>
                  <div className={divStyle}>
                    <label className={lableStyle}>میزان فعالیت</label>
                    <select
                      required
                      onChange={handleInput}
                      id="activity"
                      name="activity"
                      autocomplete="country-name"
                      className={selectStyle}>
                      <option value="1">بدون فعالیت</option>
                      <option value="1.2">
                        کم تحرک: تحرک کم یا بدون تحرک{' '}
                      </option>
                      <option value="1.375">
                        سبک:ورزش سبک ۱-۳ بار در هفته
                      </option>
                      <option value="1.465">
                        متوسط: ورزش متوسط ۴-۵ بار در هفته
                      </option>
                      <option value="1.55">
                        فعال: ورزش با شدت بالا ۳-۴ بار در هفته
                      </option>
                      <option value="1.725">
                        بسیار فعال: ورزش با شدت بالا ۶-۷ بار در هفته
                      </option>
                      <option value="1.9">
                        فوق العاده فعال: ورزش با شدت بالا دو بار در روز
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                className="px-4 py-3  text-right sm:px-6 text-white 
              ">
                <button
                  className="btn bg-fitlinez text-white font-bold  px-4 border border-blue-700 rounded w-full"
                  //disabled={!isEnabled}
                  onClick={handleSubmitUserInfo}
                  type="submit">
                  محاسبه و دریافت نتیجه
                </button>
              </div>

              {isEnabled && (
                <div className="overflow-x-auto" dir="rtl">
                  <Calories
                    mildWeightLossCalories={mildWeightLossCalories}
                    weightLossCalories={weightLossCalories}
                    extremeWeightLossCalories={extremeWeightLossCalories}
                    maintainWeightCalories={maintainWeightCalories}
                    bulkWeightCalories={bulkWeightCalories}
                  />
                  {/* <Result
                    mildWeightLossCalories={mildWeightLossCalories}
                    weightLossCalories={weightLossCalories}
                    extremeWeightLossCalories={extremeWeightLossCalories}
                    maintainWeightCalories={maintainWeightCalories}
                  /> */}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
