import { Helmet } from 'react-helmet';

export default function NotFound() {
  return (
    <>
      {/*
          This example requires updating your template:

          ```
          <html className="h-full">
          <body className="h-full">
          ```
        */}
      <Helmet
        title="404 Not Found"
        meta={[
          {
            name: 'description',
            content: '404 page',
          },
        ]}
      />
      <main
        dir="ltr"
        className="grid min-h-full place-items-center bg-main py-24 px-6 sm:py-32 lg:px-8">
        <div className="grid grid-col items-center gap-3 justify-center md:flex-row md:justify-center">
          <a
            dir="rtl"
            className="mb-2 bg-lighter inline-block rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
            data-te-ripple-init
            data-te-ripple-color="light"
            href="/private"
            role="button">
            ثبت نام دوره خصوصی
          </a>
          <a
            dir="rtl"
            className="mb-2 inline-block bg-gold text-main rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal  transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
            data-te-ripple-init
            data-te-ripple-color="light"
            href="/shapeup"
            role="button">
            ثبت نام دوره نیمه خصوصی
          </a>
          <a
            dir="rtl"
            className="mb-2 bg-lighter inline-block rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
            data-te-ripple-init
            data-te-ripple-color="light"
            href="/academy"
            role="button">
            ثبت نام دوره آفلاین
          </a>
          <a
            className="mb-2 bg-lighter inline-block rounded-full border-2 border-neutral-50 px-[46px] pt-[14px] pb-[12px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 md:mr-2 md:mb-0"
            // className="inline-block rounded-full px-12 pt-4 pb-3.5 text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:bg-neutral-500 hover:bg-opacity-20 hover:text-neutral-200 focus:text-neutral-200 focus:outline-none focus:ring-0 active:text-neutral-300"
            data-te-ripple-init
            data-te-ripple-color="light"
            href="http://fitlinez.com"
            role="button">
            دانلود اپلیکیشن
          </a>
        </div>
      </main>
    </>
  );
}
