import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import api from '../../api/api';
import Verif from './verif';
import ResetPasswordForm from './resetPassword';

function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('initial');
  console.log('status in forgot pass', status);

  const [closeModal, setCloseModal] = useState(true);
  const baseUrl = 'https://azishafiei.com/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api
        .post(`/userauth/forgot-password`, {
          email,
          baseUrl,
        })
        .then((res) => {
          console.log(res.data);

          if (res.data.status === 'ok') {
            setStatus('success');
          } else {
            setStatus('error');
          }
        });
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  };

  return (
    <div
      className=" height-screen 
    ">
      <Helmet>
        <title>فراموشی رمز عبور</title>
      </Helmet>

      {status === 'initial' && (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-center items-center">
            <label
              className=" 
        text-main text-center text-xs w-1/3 mb-5  font-custom1
        "
              htmlFor="email">
              برای ریست کردن رمز عبور، ایمیل خود را وارد کنید در صورتی که ایمیل
              وارد شده در سیستم موجود نباشد، پیامی برای شما ارسال نخواهد شد
            </label>
            <input
              type="email"
              id="email"
              dir="ltr"
              className="text-main border-1 border-main rounded-md p-2 w-80 "
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              required
            />
            <button
              className="btn btn-primary  text-white bg-main my-10 w-30 py-4 px-3 border-2 border-main rounded-md"
              type="submit">
              درخواست تغییر رمز عبور
            </button>
          </div>
        </form>
      )}
      {status === 'error' && (
        <div className="alert alert-error  justify-center items-center">
          <div className="text-main font-bold">خطا در ارسال</div>
          <span className="text-white">
            ایمیل وارد شده در سیستم موجود نیست، لطفا ایمیل صحیح وارد کنید
          </span>
          <button
            onClick={() => setStatus('initial')}
            className="btn btn-primary  text-white bg-main my-10 w-30 py-4 px-3 border-2 border-main rounded-md"
            type="submit">
            تلاش مجدد
          </button>
        </div>
      )}
      {status === 'success' && (
        <div className="flex flex-col justify-center items-center">
          <label
            className="text-main text-center text-xs w-1/5  mb-5  font-custom1"
            htmlFor="email">
            کد ارسال شده به ایمیل خود را وارد کنید
          </label>
          <Verif email={email} />
        </div>
      )}
      {status === 'verfied' && <ResetPasswordForm />}
    </div>
  );
}

export default ForgotPasswordForm;
