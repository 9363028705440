import { Link, useLocation } from 'react-router-dom';
import Azar from '../../../../img/bg.webp';
import Session2 from '../../../../img/session2.jpeg';
import { useState } from 'react';

const ShapeUpTwo = () => {
  const location = useLocation();
  //console.log(location.state.data[0].name);
  const product = location?.state?.data;
  const [showModal, setShowModal] = useState(false);
  const [video, setVideo] = useState('');
  const [classes, setClasses] = useState(true);

  const products = [
    {
      id: 1,
      name: 'جلسه اول',
      active: false,
      imageSrc: Azar,
      date: '۱۴۰۲/۰۶/۱۳',
      duration: '۹۲ دقیقه',
      description: 'جلسه اول ',

      video:
        'https://backend.fitlinez.com/videos/copy_8DF837CB-0D05-4668-95E0-9A83E093178B.MOV',
    },
    {
      id: 2,
      name: 'جلسه دوم',
      active: false,
      imageSrc: Azar,
      date: '۱۴۰۲/۰۶/۲۰',
      duration: '۶۸ دقیقه',
      description: 'جلسه دوم - پروتئین و کالری',

      video: 'https://backend.fitlinez.com/videos/video1004768079.mp4',
    },
    {
      id: 3,
      name: 'جلسه سوم',
      active: false,
      imageSrc: Azar,
      date: '۱۴۰۲/۰۶/۲۷',
      duration: '۶۶ دقیقه',
      description: 'جلسه سوم -  فیبر',

      video: 'https://backend.fitlinez.com/videos/video1807886024.mp4',
    },
    {
      id: 4,
      name: 'جلسه چهارم',
      active: false,
      imageSrc: Azar,
      date: '۱۴۰۲/۰۷/۰۳',
      duration: '۶۵ دقیقه',
      description: 'جلسه چهارم -  کربوهیدرات و قند',

      video: 'https://backend.fitlinez.com/videos/video1065659882.mp4',
    },
    {
      id: 5,
      name: 'جلسه پنجم',
      active: false,
      imageSrc: Azar,
      date: '۱۴۰۲/۰۷/۱۰',
      duration: '۶۷ دقیقه',
      description: 'جلسه پنجم - چربی ',

      video: 'https://backend.fitlinez.com/videos/video1701990999.mp4',
    },
  ];

  return (
    <div className="bg-white">
      {product === 'Shape up 2' ? (
        <>
          <nav className="flex rtl-grid mt-5 ml-10" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <a
                  href="/dashboard"
                  className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  صفحه اصلی پنل
                </a>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"></path>
                  </svg>
                  <a
                    href="/dashboard"
                    className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                    Shape up
                  </a>
                </div>
              </li>
            </ol>
          </nav>

          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
            {classes ? (
              <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                {products.map((product) => (
                  <Link
                    to={`/dashboard/product/${product.id}`}
                    state={{
                      data: product,
                    }}
                    key={product.id}
                    className="group relative p-4 border border-gray-200 rounded-lg flex flex-col hover:bg-gray-50">
                    {' '}
                    <div className="relative aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg xl:aspect-h-8 xl:aspect-w-7">
                      {/* <img
                        src={product.imageSrc}
                        alt="Product"
                        className="object-cover w-full h-full"
                      /> */}
                      <div className="absolute inset-0 flex items-center bg-black bg-opacity-50 p-2">
                        <p className="text-black text-5xl font-custom1">
                          {product.name}
                        </p>
                      </div>
                      <p className="absolute bottom-0 right-2 text-white font-medium bg-opacity-50 p-2">
                        {product.duration}
                      </p>
                    </div>
                    <p className="text-black text-3xl font-custom1">
                      {product.name}
                    </p>
                    <br></br>
                    <p className="mt-1 text-lg font-medium text-main">
                      تاریخ :{product.date}
                    </p>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                <span className="text-center text-lg font-semibold leading-8 text-main">
                  {' '}
                  دوره هنوز شروع نشده است
                </span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-lg font-semibold leading-8 text-main">
            دسترسی شما به این صفحه محدود می باشد
          </h2>
        </div>
      )}
    </div>
  );
};

export default ShapeUpTwo;
